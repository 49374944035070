import React, { useState } from 'react';

interface CarouselProps {
    views: React.ReactNode[];
    setCurrent: (current: any) => void
    data: any
}

const Carousel: React.FC<CarouselProps> = ({ views, setCurrent, data }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        let newIndex
        setCurrentIndex((prevIndex) => {
            newIndex = prevIndex === 0 ? views.length - 1 : prevIndex - 1
            return newIndex;
        });
        setCurrent(data[newIndex ?? 0]);
    };

    const handleNext = () => {
        let newIndex
        setCurrentIndex((prevIndex) => {
            newIndex = prevIndex === views.length - 1 ? 0 : prevIndex + 1
            return newIndex;
        });
        setCurrent(data[newIndex ?? 0]);
    };

    return (
        <div className="bLoyal-carousel">
            <button onClick={handlePrev} className="bLoyal-carousel__button bLoyal-carousel__button--left">
                &lt;
            </button>
            <div className="bLoyal-carousel__view">
                {views[currentIndex]}
            </div>
            <button onClick={handleNext} className="bLoyal-carousel__button bLoyal-carousel__button--right">
                &gt;
            </button>
        </div>
    );
};

export default Carousel;
