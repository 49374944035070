import {queryCache, useMutation} from 'react-query';
import {client} from './ApiClient';

const joinClub = ({urls, session, domain, deviceCode, data, clubUid}: {urls: any; session: string; domain: string; deviceCode: string; data: any; clubUid: any;}) => {
	const body = {
		...data,
		GiftCustomer: data.GiftCustomer === null ? null : {
			...data.GiftCustomer,
			Address: {
				...data.GiftCustomer
			}
		},
		ClubUid: clubUid,
	}
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/clubmembership/commands/join`, {
		body: body
	});
}

export default function useJoinClub() {
	// @ts-ignore
	return useMutation(joinClub, {
		onSuccess: () => {
			queryCache.invalidateQueries('ClubMembership')
		},
	})
}