import React, {useMemo} from 'react';
import {ChoiceResponse, ClubSignupSnippetProfile} from "../Helpers/Types";
import RadioSelection from "../ClubPreference/Sections/RadioSelection";
import DateInput from "../Inputs/DateInput";
import TextInput from "../TextInput";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {languageCode} from "../Helpers/Functions";
import {LocalizationProvider} from "@mui/x-date-pickers";

type ClubMembershipTermProps = {
    handleChange: (value: any) => void;
    config: ClubSignupSnippetProfile
    state: any
}

export default function ClubMembershipTerm(props: ClubMembershipTermProps) {
    const radioOptions = useMemo(() => [{label: props.config.DefaultEndDateLabel ? props.config.DefaultEndDateLabel : 'Use Default Club Length', value: true}, {label: props.config.CustomEndDateLabel ? props.config.CustomEndDateLabel : 'Choose my own end date', value: false}], [])

    const handleChange = (field: string, value: any) => {
        let newValue = value;
        if (field === 'UseDefaultEndDate' && newValue === 'false')
            newValue = false;
        else if (field === 'UseDefaultEndDate' && newValue === 'true')
            newValue = true;
        props.handleChange({...props.state, [field]: newValue})
    }
    
    return (
        <div className={'bLoyal-center bLoyal-terms-container'}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={languageCode.toLowerCase()}>
                {props.config.AllowCustomStartDate === true &&
                    <>    
                        <DateInput id={'StartDate'} label={props.config.StartDateLabel ? props.config.StartDateLabel : 'Start Date'} value={props.state.StartDate} handleChange={handleChange} />
                        <br />
                    </>
                }
                {props.config.AllowCustomEndDate === true &&
                    <>
                        <RadioSelection  fieldName={'UseDefaultEndDate'} value={props.state.UseDefaultEndDate} handleChange={handleChange} helpText={''} label={props.config.EndDateLabel ? props.config.EndDateLabel : 'Membership End Date'} options={radioOptions}/>
                        {props.state.UseDefaultEndDate === false &&
                            <DateInput id={'ExpirationDate'} label={props.config.EndDateLabel} value={props.state.ExpirationDate ? props.state.ExpirationDate : 'End Date'} handleChange={handleChange} disablePast={true}/>
                        }
                    </>
                }
                <hr className='button-break' />
            </LocalizationProvider>
        </div>
    )
}