import {useQuery} from 'react-query';
import {client} from './ApiClient';
import {mapServiceUrls} from '../Helpers/Functions';
import {Settings} from "../Settings";

const getMyRewardsConfig = (key: any, domain: string) => {
	return client(`https://${Settings.ConfigStorageName}.blob.core.windows.net`, `config/${domain.toLowerCase()}/settings.json`);
}

export default function useMyRewardsConfig(domain: string) {
	const { status, data, error, isFetching } = useQuery(['MyRewardsConfig', domain], (key) => getMyRewardsConfig(key, domain), { enabled: !!domain, staleTime: 1000*60*5})
	const myRewardsConfig = data
	return {status, myRewardsConfig, error, isFetching}
}
