import React, {useEffect, useMemo, useState} from 'react';
import {BillingAddress, IPaymentType, ISyntheticEvent, PaymentType, WebSnippetProfile} from "../Helpers/Types";
import {useAuth} from "../Utils/UseAuth";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import useSavePaymentMethod from "../Utils/UseSavePaymentMethod";
import useDeletePaymentMethod from "../Utils/UseDeletePaymentMethod";
import useServiceUrls from "../Utils/UseServiceUrls";
import usePaymentMethods from "../Utils/UsePaymentMethods";
import {v4 as uuid} from "uuid";
import {addPayment, hasPaymentToken, mapServiceUrls, savedPayments} from "../Helpers/Functions";
import LoadingSpinner from "../LoadingSpinner";
import {MDBCol, MDBRow} from "mdbreact";
import SelectInput from "../SelectInput";
import PaymentMethodPreview from "../PaymentMethodPreview";
import PaymentModal from "../PaymentModal";
import SnippetHeader from "../Helpers/SnippetHeader";
import SnippetFooter from "../Helpers/SnippetFooter";
import {useCartContext} from "../Utils/UseCartContext";

type PaymentMethodSnippetProps = {
    snippetProfile: WebSnippetProfile
    onSelect?: (field: string, value: string) => void
    initialValue?: string
}

export default function PaymentMethodSnippet(props: PaymentMethodSnippetProps) {
    const config = props.snippetProfile.PaymentMethods
    
    const dumbEnums: Array<IPaymentType> = useMemo(() => config?.PaymentTypes.map((pt) => {
        return {
            Enumkey: pt.toString(),
            EnumValue: pt.toString(),
            IsDisplay: true,
        }
    }) ?? [], [config])

    const [bLoyalCartUid, setBLoyalCartUid] = useState('')
    const [bLoyalCartExternalId, setBLoyalCartExternalId] = useState('')
    const [bLoyalCartSourceExternalId, setBLoyalCartSourceExternalId] = useState('')
    // @ts-ignore
    const { session } = useAuth();
    // @ts-ignore
    const { domain, deviceCode, onCompleteFunction, cartUid, cartExternalId, cartSourceExternalId } = useSnippetContext();
    const {contextCartUid, contextCartExternalId, contextCartSourceExternalId} = useCartContext();
    const [savePaymentMethod, {status: saveStatus, error: saveError, data}] = useSavePaymentMethod();
    const [deletePaymentMethod, { status, error}] = useDeletePaymentMethod();
    const { serviceUrls } = useServiceUrls(domain);
    const { paymentMethodsStatus, paymentMethods, paymentMethodsError, paymentMethodsFetching } = usePaymentMethods(serviceUrls, session, domain, deviceCode);
    const [paymentOptions, setPaymentOptions] = useState<Array<any>>([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(props.initialValue ?? '');
    const [showingAdd, setShowingAdd] = useState(false);
    const [onCompleteExists, setOnCompleteExists] = useState(false)
    const [allowCartPayments, setAllowCartPayments] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)

    useEffect(() => {
        if (!bLoyalCartUid) {
            if (contextCartUid)
                setBLoyalCartUid(contextCartUid);
            else if (cartUid)
                setBLoyalCartUid(cartUid);
        }
        
        if (!bLoyalCartExternalId) {
            if (contextCartExternalId)
                setBLoyalCartExternalId(contextCartExternalId);
            else if (cartExternalId)
                setBLoyalCartExternalId(cartExternalId);
        }
        
        if (!bLoyalCartSourceExternalId) {
            if (contextCartSourceExternalId)
                setBLoyalCartSourceExternalId(contextCartSourceExternalId)
            else if (cartSourceExternalId)
                setBLoyalCartSourceExternalId(cartSourceExternalId);
        }
    }, [contextCartUid, contextCartExternalId, contextCartSourceExternalId, cartUid, cartExternalId, cartSourceExternalId]);

    useEffect(() => {
        if (paymentMethods && paymentMethods.length > 0) {
            setPaymentOptions(mapPaymentsToOptions(paymentMethods));
            setSelectedPaymentMethod(paymentMethods.find((a: any) => a.Uid === selectedPaymentMethod)?.Uid ?? paymentMethods[0]?.Uid ?? '')
            props.onSelect?.('paymentMethod', paymentMethods.find((a: any) => a.Uid === selectedPaymentMethod) ?? paymentMethods[0])
        }
    }, [paymentMethods]);
    
    useEffect(() => {
        if (!config?.AllowCartPayments)
            return
        
        if ((bLoyalCartUid || bLoyalCartExternalId || bLoyalCartSourceExternalId) && config?.AllowCartPayments)
            setAllowCartPayments(true)
    }, [config, bLoyalCartUid, bLoyalCartExternalId, bLoyalCartSourceExternalId])
    
    useEffect(() => {
        // @ts-ignore
        if (typeof window[onCompleteFunction] === "function") {
            setOnCompleteExists(true)
            return;
        }
        
        console.log(`OnCompleteFunction ${onCompleteFunction} does not exist`)
    }, [])
    
    useEffect(() => {
        if (saveStatus === 'success') {
            const uid = data.data.PaymentMethod.Uid
            setSelectedPaymentMethod(uid);
            const newMethod = data.data.PaymentMethod
            props.onSelect?.('paymentMethod', newMethod)
            setShowSuccess(true)
            window.setTimeout(function () {
                setShowSuccess(false)
            }, (1500))
        }
    }, [saveStatus])

    const mapPaymentsToOptions = (payments: Array<any>) => {
        if (!payments)
            return [];
        if (payments.length === 0)
            return [];
        
        let options: Array<any> = [];
        payments.forEach((payment: any) => {
            const text = payment.CreditCard?.CardMask ? ` - ${payment.CreditCard.CardMask?.slice(-4)}` : ''
            const clubText = payment.IsClubPayment ? ' - Payment is used on a club' : ''
            let option = {
                value: payment.Uid,
                text: payment.Title + text + clubText,
            };
            if (selectedPaymentMethod === '' && payment.IsPrimary)
                setSelectedPaymentMethod(payment.Uid);
            options.push(option);
        });
        return options;
    };

    const showAddPayment = (e: ISyntheticEvent) => {
        e.preventDefault();
        setSelectedPaymentMethod('')
        setShowingAdd(true);
    };

    const saveCreditPayment = (token: string, cardNumber: string, cardType: string, isPrimary: boolean, title: string, billingAddress: BillingAddress) => {
        if (!hasPaymentToken(token)) {
            addPayment(token)
            const uid = selectedPaymentMethod === '' ? null : selectedPaymentMethod;
            savePaymentMethod({
                creditCard: {
                    CardType: cardType,
                    CardMask: cardNumber,
                    CardToken: token,
                    ...billingAddress
                },
                paymentMethodUid: uid,
                title: title,
                giftCard: undefined,
                isPrimary: isPrimary,
                deviceCode: deviceCode,
                domain: domain,
                paymentType: PaymentType.CreditCard,
                session: session,
                urls: mapServiceUrls(serviceUrls)
            });
            setShowingAdd(false);
        }
    }
    
    const savePayment = (title: string) => {
        setSelectedPaymentMethod(title);
        setShowingAdd(false)
    };

    const handleSelectChange = (field: string, value: string) => {
        const newMethod = paymentMethods.find((curr: any) => curr.Uid === value);
        props.onSelect?.('paymentMethod', newMethod)
        setSelectedPaymentMethod(value);
    };
    
    const getSelectedPaymentMethod = () => {
        return paymentMethods.find((curr: any) => curr.Uid === selectedPaymentMethod);
    };

    const editPayment = (e:ISyntheticEvent) => {
        e.preventDefault();
        setShowingAdd(true);
    }

    const deletePayment = (e: ISyntheticEvent) => {
        e.preventDefault();
        deletePaymentMethod({urls: mapServiceUrls(serviceUrls), paymentUid: selectedPaymentMethod, deviceCode, domain, session});
        setSelectedPaymentMethod('')
    }
    
    const addCartPayment = (e: ISyntheticEvent) => {
        e.preventDefault()
        const method = getSelectedPaymentMethod();
        if (domain.toLowerCase() === 'client' || domain.toLowerCase() === 'chetudev') {
            // @ts-ignore
            window[onCompleteFunction]({CartUid: null, PaymentMethod: { Title: method.Title, Uid: method.Uid, Mask: method.CreditCard?.CardMask ?? null}});
        }
        else {
            // @ts-ignore
            window[onCompleteFunction](method);
        }
    }
    
    return (
        <>
            <SnippetHeader logoUrl={props.snippetProfile.LogoUrl} title={props.snippetProfile.Title} message={props.snippetProfile.Message} snippetCode={props.snippetProfile.Code}/>
            <div className='bLoyal-center form bl-narrow'>
                {paymentMethodsStatus === 'loading' &&
                    <LoadingSpinner/>
                }
                {paymentMethodsStatus === 'success' &&
                    <>
                        <MDBRow>
                            <MDBCol md='12'>
                                <SelectInput label='Payment Method' options={paymentOptions} current={selectedPaymentMethod} disabled={false}
                                             fieldName='PaymentMethod' handleChange={handleSelectChange}/>
                            </MDBCol>
                            <div className='bLoyal-center'>
                                <button className='bl-snippet-button' onClick={showAddPayment}>New</button>
                                <button className='bl-snippet-button' disabled={selectedPaymentMethod === ''} onClick={editPayment}>Edit</button>
                                <button className='bl-snippet-button' disabled={selectedPaymentMethod === '' || (paymentMethods?.find((a: any) => a.Uid === selectedPaymentMethod)?.IsClubPayment ?? false)} onClick={deletePayment} >Delete</button>
                            </div>
                        </MDBRow>
    
                        {allowCartPayments && selectedPaymentMethod !== '' && onCompleteExists &&
                            <button className='bl-snippet-button' onClick={addCartPayment}>{config?.AddPaymentLabel}</button>
                        }
                        {selectedPaymentMethod !== '' && getSelectedPaymentMethod() &&
                            <>
                                <PaymentMethodPreview paymentMethod={getSelectedPaymentMethod()}/>
                            </>
                        }
                        {showingAdd &&
                            <PaymentModal payment={selectedPaymentMethod === '' ? undefined : getSelectedPaymentMethod()} savePayment={savePayment} saveCreditPayment={saveCreditPayment} close={() => setShowingAdd(false)} paymentTypeList={dumbEnums} includeBillingAddress={config?.IncludeBillingAddress ?? false}/>
                        }
                        <br/>
                    </>
                }
                {saveStatus === 'error' && <p className='bLoyal-error-text'>Unable to save payment method.</p>}
                {(status === 'loading' || paymentMethodsStatus === 'loading') &&
                    <LoadingSpinner />
                }

                {showSuccess &&
                    <p className='message'>{props.snippetProfile.OnSuccessMessage ?? 'Success'}</p>
                }
    
                {(paymentMethodsError || error) &&
                    <p className='bLoyal-error-text'>{paymentMethodsError ? paymentMethodsError : error}</p>
                }
                <SnippetFooter footer={props.snippetProfile.Footer} snippetCode={props.snippetProfile.Code}/>
            </div>
        </>
    )
}