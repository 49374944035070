import React, {Fragment, useEffect, useMemo, useReducer, useState} from 'react';
import useSnippetConfig from './Utils/UseSnippetConfig';
import useServiceUrls from './Utils/UseServiceUrls';
import LoadingSpinner from './LoadingSpinner';
import {
	CharacterTypeOptions,
	ClubMembershipCustomFieldConfig,
	CustomFieldDisplayType,
	ICountry,
	ICustomFieldType,
	IInputConfig,
	ISelectOption,
	ISignupState,
	IState,
	ISyntheticEvent,
	PasswordState,
	SubscriberGroupConfig
} from './Helpers/Types';
import TextInput from './TextInput';
import {
	SignupAction,
	SignupActionTypes,
	updateCustomSignupFieldAction,
	updateSignupFieldAction,
	updateSubscriberGroupsFieldAction
} from './SignupActions';
import UseSignupCustomer from './Utils/UseSignupCustomer';
import {
	doesCountryHaveStates,
	formatDate,
	getButtonText,
	getDisplayValue,
	mapServiceUrls,
	updateLanguageCode
} from './Helpers/Functions';
import PasswordField from './PasswordField';
import EmailInput from './EmailInput';
import {useSnippetContext} from './Utils/UseSnippetContext';
import {useAuth} from './Utils/UseAuth';
import useDeviceSessionProfile from './Utils/UseDeviceSessionProfile';
import NumberInput from './NumberInput';
import {useDeviceSessionContext} from './Utils/UseDeviceSessionProfileContext';
import SelectInput from './SelectInput';
import useCountries from './Utils/UseCountries';
import useStates from './Utils/UseStates';
import {useStyleContext} from "./Utils/UseStyleContext";
import CheckboxInput from "./CheckboxInput";
import useSetCustomerToCart from "./Utils/UseSetCustomerToCart";
import SnippetHeader from "./Helpers/SnippetHeader";
import SnippetFooter from "./Helpers/SnippetFooter";
import RadioSelection from "./ClubPreference/Sections/RadioSelection";
import {useCartContext} from "./Utils/UseCartContext";

export const signupState: ISignupState = {
	SubscriberGroups: [],
	BirthdayMonth1: '',
	BirthdayMonth2: '',
	FirstName2: '',
	LastName2: '',
	LoyaltyCardNumber: '',
	Password: '',
	VerifyPassword: '',
	FirstName: '',
	LastName: '',
	CompanyName: '',
	Address1: '',
	Address2: '',
	City: '',
	State: '',
	PostalCode: '',
	Country: '',
	EmailAddress: '',
	Phone1: '',
	MobilePhone: '',
	BirthDate: '',
	CustomFields: [],
	RegisterCard: '',
};

export const buildSignupState = (customer: any, defaultCountry: string) => {
	if (customer === undefined)
		return signupState
	
	return {
		SubscriberGroups: [],
		BirthdayMonth1: customer.BirthdayMonth1 ?? '',
		BirthdayMonth2: customer.BirthdayMonth2 ?? '',
		FirstName2: customer.FirstName2 ?? '',
		LastName2: customer.LastName2 ?? '',
		LoyaltyCardNumber: customer.LoyaltyCardNumber ?? '',
		Password: customer.Password ?? '',
		VerifyPassword: customer.Password ?? '',
		FirstName: customer.FirstName ?? '',
		LastName: customer.LastName ?? '',
		CompanyName: customer.CompanyName ?? '',
		Address1: customer.Address?.Address1 ?? customer.Address1 ?? '',
		Address2: customer.Address?.Address2 ?? customer.Address2 ?? '',
		City: customer.Address?.City ?? customer.City ?? '',
		State: customer.Address?.State ?? customer.State ?? '',
		PostalCode: customer.Address?.PostalCode ?? customer.PostalCode ?? '',
		Country: customer.Address?.Country ? customer.Address?.Country : customer.Country ?? defaultCountry,
		EmailAddress: customer.EmailAddress ?? '',
		Phone1: customer.Phone1 ?? '',
		MobilePhone: customer.MobilePhone ?? '',
		BirthDate: formatDate(customer.BirthDate) ?? '',
		CustomFields: [],
		RegisterCard: ''
	}
}

export const signupReducer = (state = signupState, action: SignupAction): ISignupState => {
	switch (action.type) {
		case SignupActionTypes.UpdateCustomField:
			return {...state, CustomFields: action.value}
		case SignupActionTypes.UpdateField:
			return {
				...state,
				[action.fieldName]: action.value
			};
		case SignupActionTypes.UpdateSubscriberGroupsField:
			return {
				...state,
				SubscriberGroups: action.value
			}
		default:
			return state;
	}
};

type SignupSnippetProps = {
	snippetCode: string
	isStandalone: boolean
	setShowSignup?: Function;
	requiresLogin?: boolean
	saveLabel?: string,
	backLabel?: string,
	onBack?: Function
	onComplete?: Function
	isGift?: boolean
	setGiftRecipient?: (recipient: ISignupState) => void
	giftCustomer?: ISignupState
	error?: any
}

export default function SignupSnippet(props: SignupSnippetProps) {
	// @ts-ignore
	const {setSession} = useAuth();
	// @ts-ignore
	const {profile} = useDeviceSessionContext();
	// @ts-ignore
	const { customer, session } = useAuth()
		
	const [state, dispatch] = useReducer(signupReducer, buildSignupState(props.isGift ? props.giftCustomer : customer, profile.DefaultCountry));
	const [bLoyalCartUid, setBLoyalCartUid] = useState('')
	const [bLoyalCartExternalId, setBLoyalCartExternalId] = useState('')
	const [bLoyalCartSourceExternalId, setBLoyalCartSourceExternalId] = useState('')

	const {contextCartUid, contextCartExternalId, contextCartSourceExternalId} = useCartContext();
	// @ts-ignore
	const { domain, deviceCode, cartUid, cartExternalId, cartSourceExternalId } = useSnippetContext();
	// @ts-ignore
	const {theme, setTheme} = useStyleContext();
	const { serviceUrls } = useServiceUrls(domain);
	const { deviceSessionProfile } = useDeviceSessionProfile(serviceUrls, domain, deviceCode)
	const { SnippetConfig: config } = useSnippetConfig(serviceUrls, domain, deviceCode, props.snippetCode);
	const [signupCustomer, { data, isSuccess, isLoading, isError, error, reset }] = UseSignupCustomer();
	const [signupFields, setSignupFields] = useState<Array<IInputConfig>>([]);
	const [subscriberGroupFields, setSubscriberGroupFields] = useState<Array<SubscriberGroupConfig>>([])
	const [passwordState, setPasswordState] = useState(PasswordState.Unsubmitted);
	const [passwordDisplayed, setPasswordDisplayed] = useState(false);
	const [validLoyaltyCard, setValidLoyaltyCard] = useState(false)
	const [checkLoyaltyCardNumber, setCheckLoyaltyCardNumber] = useState(false)
	const [loyaltyNumberChanged, setLoyaltyNumberChanged] = useState(false)
	const [cardNumber, setCardNumber] = useState('')
	const [validRegisteredCard, setValidRegisteredCard] = useState(false)
	const [checkRegisteredCardNumber, setCheckRegisteredCardNumber] = useState(false)
	const [registeredCardChanged, setRegisteredCardChanged] = useState(false)
	const [setCustomer, {status: setCustomerStatus, error: setCustomerError}] = useSetCustomerToCart();

	const { countries } = useCountries(serviceUrls, domain, deviceCode);
	const { states } = useStates(serviceUrls, domain, deviceCode, state.Country, countries && doesCountryHaveStates(countries, state.Country));
	const customerCustomFields = useMemo(() => profile.CustomerCustomFields, [profile])
	

	const [countryOptions, setCountryOptions] = useState<Array<ISelectOption>>([]);
	const [stateOptions, setStateOptions] = useState<Array<ISelectOption>>([]);
	
	const SnippetConfig = useMemo(() => config?.CustomerSignup, [config])

	useEffect(() => {
		if (!bLoyalCartUid) {
			if (contextCartUid)
				setBLoyalCartUid(contextCartUid);
			else if (cartUid)
				setBLoyalCartUid(cartUid);
		}

		if (!bLoyalCartExternalId) {
			if (contextCartExternalId)
				setBLoyalCartExternalId(contextCartExternalId);
			else if (cartExternalId)
				setBLoyalCartExternalId(cartExternalId);
		}

		if (!bLoyalCartSourceExternalId) {
			if (contextCartSourceExternalId)
				setBLoyalCartSourceExternalId(contextCartSourceExternalId)
			else if (cartSourceExternalId)
				setBLoyalCartSourceExternalId(cartSourceExternalId);
		}
	}, [contextCartUid, contextCartExternalId, contextCartSourceExternalId, cartUid, cartExternalId, cartSourceExternalId]);
	
	useEffect(() => {
		if (config)
			setTheme(config.Theme);
	}, [config])
	
	useEffect(() => {
		if (profile && state.Country === '') {
			// @ts-ignore
			dispatch(updateSignupFieldAction('Country', profile.DefaultCountry))
		}
		updateLanguageCode(profile)
	}, [profile])
	
	useEffect(() => {
		if (props.isGift)
			props.setGiftRecipient?.(state)
	}, [state])

	useEffect(() => {
		if (!countries)
			return;

		const countryList = countries.map((country: ICountry) => {
			return {
				value: country.Code,
				text: country.Name
			};
		});

		setCountryOptions(countryList);
	}, [countries]);

	useEffect(() => {
		if (!states)
			return;

		const stateList = states.map((state: IState) => {
			return {
				value: state.Code,
				text: state.Name
			};
		});

		setStateOptions(stateList);
	}, [states]);
	
	// useEffect(() => {
	// 	if (isSuccess && !isRegisteringCard() || isRegisteringCard() && saveCardStatus === 'success') {
	// 		// props.
	// 	}
	// }, [])

	useEffect(() => {
		if (SnippetConfig) {
			setSignupFields(SnippetConfig.SignupFields);
			setSubscriberGroupFields(SnippetConfig.SubscriberGroups)
			let groups: Array<string> = [];
			SnippetConfig.SubscriberGroups.forEach((group: any) => {
				if (group.Subscribe)
					groups.push(group.Uid);
			})
			// @ts-ignore
			dispatch(updateSubscriberGroupsFieldAction(groups))
		}
	}, [SnippetConfig]);

	useEffect(() => {
		if (isSuccess) {
			handleSuccessfulSignup(data)
		}
	}, [isSuccess, data]);
	
	useEffect(() => {
		if (isError)
			console.log(error)
	}, [isError])
	
	const handleSuccessfulSignup = (data: any) => {
		if ((bLoyalCartUid || bLoyalCartExternalId || bLoyalCartSourceExternalId) && !props.isGift)
			setCustomer({urls: mapServiceUrls(serviceUrls), domain, deviceCode, cartUid: bLoyalCartUid, cartExternalId: bLoyalCartExternalId, cartSourceExternalId: bLoyalCartSourceExternalId, customerUid: data.data.Customer.Uid})
		
		// if (!signupFields.some((f) => f.FieldName === 'RegisterCard')) {
			if (props.onComplete) {
				props.onComplete(data.data)
				return
			}
			if (!props.isStandalone) {
				if (data.data.SessionKey && domain.toLowerCase() !== 'landrys') {
					if (data.data.SessionKey !== null)
						setSession(data.data.SessionKey, data.data.Customer);
				}
				if (props.setShowSignup)
					props.setShowSignup(false);
			} else {
				if (config.OnSuccessRedirectUrl !== null)
					window.setTimeout(function () {
						let url = config.OnSuccessRedirectUrl ?? window.location.host;
						url = url.includes("http") ? url : `https://${url}`
						window.location.assign(url)
					}, (config.OnSuccessRedirectDelay ?? 0) * 1000)
			}
	}
	
	const handleChange = (fieldName: string, value: string) => {
		if (isError)
			reset()
		
		// @ts-ignore
		dispatch(updateSignupFieldAction(fieldName, value));
		if (fieldName === 'LoyaltyCardNumber') {
			validateLoyaltyCardNumber(value)
			setLoyaltyNumberChanged(true)
		}
	};
	
	const validateLoyaltyCardNumber = (number: string) => {
		if (number === '') {
			setValidLoyaltyCard(true)
			return true
		}
		
		let value = false
		if (number.length < SnippetConfig?.LoyaltyCardMinLength && !SnippetConfig?.ZeroFillLoyaltyCard) {
			setValidLoyaltyCard(value)
			return value
		}
		if (SnippetConfig?.LoyaltyCardMaxLength !== null && number.length > SnippetConfig?.LoyaltyCardMaxLength) {
			setValidLoyaltyCard(value)
			return value
		}
			
		if (SnippetConfig.LoyaltyCardCharacterType === CharacterTypeOptions.AlphaNumeric)
			value = /^[a-z0-9]+$/i.test(number)
		else if (SnippetConfig.LoyaltyCardCharacterType === CharacterTypeOptions.Alpha)
			value =/^[a-zA-Z]+$/.test(number)
		else if (SnippetConfig.LoyaltyCardCharacterType === CharacterTypeOptions.Numeric)
			value = /^\d+$/.test(number)
		
		setValidLoyaltyCard(value)
		return value
	}

	const validateRegisteredCardNumber = (number: string) => {
		if (number === '') {
			setValidRegisteredCard(true)
			return true
		}
		
		let value = false
		if (number.length < SnippetConfig?.RegisteredCardMinLength) {
			setValidRegisteredCard(value)
			return value
		}
		if (SnippetConfig?.RegisteredCardMaxLength !== null && number.length > SnippetConfig?.RegisteredCardMaxLength) {
			setValidRegisteredCard(value)
			return value
		}

		if (SnippetConfig.RegisteredCardCharacterType === CharacterTypeOptions.AlphaNumeric)
			value = /^[a-z0-9]+$/i.test(number)
		else if (SnippetConfig.RegisteredCardCharacterType === CharacterTypeOptions.Alpha)
			value =/^[a-zA-Z]+$/.test(number)
		else if (SnippetConfig.RegisteredCardCharacterType === CharacterTypeOptions.Numeric)
			value = /^\d+$/.test(number)

		setValidRegisteredCard(value)
		return value
	}

	const submit = (event: ISyntheticEvent) => {
		event.preventDefault();
		if (state.Password && state.Password !== state.VerifyPassword) {
			setPasswordState(PasswordState.Unmatching);
			return;	
		}
		setPasswordState(PasswordState.Matching)
		
		if (checkLoyaltyCardNumber && !validateLoyaltyCardNumber(state.LoyaltyCardNumber))
			return
		
		if (checkRegisteredCardNumber && !validateRegisteredCardNumber(cardNumber))
			return
		
		let loyaltyCard = state.LoyaltyCardNumber
		if (loyaltyCard.length < SnippetConfig?.LoyaltyCardMinLength) {
			const diff = SnippetConfig?.LoyaltyCardMinLength - loyaltyCard.length
			loyaltyCard = "0".repeat(diff) + loyaltyCard
		}
		
		if (props.isGift)
			props.onComplete?.()
		else 
			signupCustomer({
				urls: mapServiceUrls(serviceUrls), deviceCode, signupState: {...state, LoyaltyCardNumber: loyaltyCard}, domain, session: props.isGift ? '' : session ?? '', registeredCardNumber: cardNumber
			});
	};

	const handleCustomFieldChange = (field: string, value: any) => {
		if (isError)
			reset()
		
		let fields = state.CustomFields.slice()
		let found = false
		fields = fields.map((f: any) => {
			if (f.code === field) {
				found = true
				return {
					code: field,
					value: value,
				}
			}

			return f
		})

		if (!found)
			fields.push({code: field, value: value})

		// @ts-ignore
		dispatch(updateCustomSignupFieldAction('CustomFields', fields))
	}
	
	const handleSubscriberGroupChange = (value: boolean, group: string) => {
		if (isError)
			reset()
		
		let currentGroups = state.SubscriberGroups;
		if (value)
			currentGroups.push(group);
		else
			currentGroups = currentGroups.filter((curr) => curr !== group)
		
		// @ts-ignore
		dispatch(updateSubscriberGroupsFieldAction(currentGroups))
	}

	const back = (e: ISyntheticEvent) => {
		e.preventDefault();
		props.setShowSignup?.(false);
	}
	
	const isRegisteringCard = () => {
		return signupFields.some((f) => f.FieldName === 'RegisterCard') && cardNumber !== ''
	}
	
	const mapSignupField = (field: IInputConfig, index: number) => {
		const customField = customerCustomFields.find((d: any) => d.Code === field.FieldName)
		
		if (customField !== undefined) {
			return mapCustomField({Code: field.FieldName, Name: field.Label, Required: field.IsRequired}, index);
		}
		
		if (!field.IsIncluded && !field.IsRequired)
			return;
		
		if (field.FieldName === 'LoyaltyCardNumber' && !checkLoyaltyCardNumber)
			setCheckLoyaltyCardNumber(true)
		
		if (field.FieldName === 'RegisterCard' && !checkRegisteredCardNumber) {
			setCheckRegisteredCardNumber(true)
		}
		
		if (field.FieldName === 'RegisterCard')
			return <Fragment key={index} >
				<TextInput id={'register-card'} value={cardNumber} label={field.Label ? field.Label : 'Registered Card Number'}
					   handleChange={handleRegisterCardChange} required={field.IsRequired}/>
			</Fragment>

		if (field.FieldName === 'Zip')
			field.FieldName = 'PostalCode'
		// @ts-ignore
		let value = state[field.FieldName];
		let inputField: any;
		const label = field.Label ? field.Label : getDisplayValue(field.FieldName);
		switch (field.FieldName) {
			case 'Password':
				if (customer)
					return <Fragment key={index}></Fragment>
				
				if (!passwordDisplayed)
					setPasswordDisplayed(true);

				inputField = <>
					<PasswordField required={field.IsRequired} id={field.FieldName} value={''} label={label}
								   handleChange={handleChange}/>
					<br/>
					<PasswordField id={'VerifyPassword'} value={''} label={`Verify Password`}
								   required={field.IsRequired} handleChange={handleChange}/>
				</>;
				break;
			case 'EmailAddress':
				inputField = <EmailInput required={field.IsRequired} id={field.FieldName} value={value} label={label} handleChange={handleChange}/>;
				break;
			case 'Country':
					return (
						<Fragment key={index}>
							<SelectInput current={value} disabled={false} fieldName={field.FieldName} handleChange={handleChange} options={countryOptions}
							             label={label} required={field.IsRequired}/>
						</Fragment>
					);
			case 'State':
					if (state.Country === '') {
						return;
					}
					const selected: ICountry = countries?.find((curr: ICountry) => curr.Code === state.Country);
					if (selected?.HasStates)
						return (
							<Fragment key={index}>
								<SelectInput current={value} disabled={false} fieldName={field.FieldName} handleChange={handleChange}
								             options={stateOptions} label={label} required={field.IsRequired}/>
							</Fragment>
						);
					else
						inputField = <TextInput required={field.IsRequired} id={field.FieldName} value={value} label={label} handleChange={handleChange}/>;
				
					break;
			default:
				inputField = <TextInput required={field.IsRequired} id={field.FieldName} value={value} label={label} handleChange={handleChange}/>;
		}
		return (
			<Fragment key={index}>
				{inputField}
				<br/>
			</Fragment>
		);
	};

	const mapCustomField = (fieldConfig: ClubMembershipCustomFieldConfig, index: number) => {
		const field = customerCustomFields.find((d: any) => d.Code === fieldConfig.Code)
		
		if (!field)
			return null;

		const mapListOptions = (options: Array<any>) => {
			return options.map((option: any) => {
				return {
					text: option.Name,
					value: option.Value,
				}
			})
		}

		const mapRadioOptions = (options: Array<any>) => {
			return options.map((option: any) => {
				return {
					label: option.Name,
					value: option.Value,
				}
			})
		}

		let value = state.CustomFields?.find((curr: any) => curr.code === fieldConfig.Code)?.value
		switch (field.FieldType) {
			case ICustomFieldType.Int:
			case ICustomFieldType.Decimal:
				return (
					<Fragment key={field.Code}>
						{/*@ts-ignore*/}
						<NumberInput id={field.Code} value={value ?? ''} label={field.Name}
									 handleChange={handleCustomFieldChange}/>
						<br/>
					</Fragment>
				)
			case ICustomFieldType.ValidatedList:
				if (config.CustomFieldsDisplayType === CustomFieldDisplayType.Radio)
					return (
						<Fragment key={`${field.Code}-${index}`}>
							<RadioSelection fieldName={field.Code} value={value} handleChange={handleCustomFieldChange}
											helpText={field.HelpText} label={field.Name}
											options={mapRadioOptions(field.ValidValues)}/>
						</Fragment>
					)

				return (
					<Fragment key={`${field.Code}-${index}`}>
						<SelectInput options={mapListOptions(field.ValidValues)} label={field.Name}
									 current={value ?? ""} fieldName={field.Code} handleChange={handleCustomFieldChange}
									 disabled={false} required={fieldConfig.Required}/>
					</Fragment>
				)
			case ICustomFieldType.Money:
			case ICustomFieldType.Date:
			case ICustomFieldType.Unknown:
			case ICustomFieldType.Time:
			case ICustomFieldType.DateTime:
			case ICustomFieldType.Bool:
			case ICustomFieldType.Byte:
			case ICustomFieldType.Blob:
			case ICustomFieldType.List:
			case ICustomFieldType.MultiSelectValidatedList:
			case ICustomFieldType.String:
			default:
				return (
					<Fragment key={`${field.Code}-${index}`}>

						<TextInput id={field.Code} value={value ?? ''} label={field.Name}
								   handleChange={handleCustomFieldChange} required={fieldConfig.Required}/>
						<br/>
					</Fragment>
				)
		}
	}
	
	const mapSubscriberGroupBoxes = (group: SubscriberGroupConfig, index: number) => {
		if (!group.Display)
			return;
		
		const value = state.SubscriberGroups.includes(group.Uid);
		return (
			<div key={index} style={{textAlign: 'left'}}>
				<CheckboxInput key={group.Uid} id={group.Uid} value={value} label={group.Name} handleChange={handleSubscriberGroupChange} />
			</div>
		)
	}
	
	const handleRegisterCardChange = (field: string, value: string) => {
		validateRegisteredCardNumber(value)
		setRegisteredCardChanged(true)
		setCardNumber(value);
	}

	// @ts-ignore
	return (
		<div className='bLoyal-center bLoyal-form bLoyal-signup-page'>
			{!SnippetConfig &&
            <LoadingSpinner/>
			}
			{(isSuccess && !config.OnSuccessMessage) &&
            <h2>Signup Complete</h2>
			}
			{(isSuccess && config.OnSuccessMessage) &&
			<p>{config.OnSuccessMessage}</p>
			}
			{SnippetConfig && (!isSuccess || (isSuccess && isRegisteringCard())) &&
            <>
				<SnippetHeader logoUrl={config.LogoUrl} title={config.Title} message={config.Message} snippetCode={config.Code}/>
                <form onSubmit={submit}>
					<div className='bLoyal-center bLoyal-input-holder'>
						{signupFields.map(mapSignupField)}
						{SnippetConfig.CustomerCustomFields.map(mapCustomField)}
						{subscriberGroupFields.some((group) => group.Display) &&
						<h4>Subscriber Groups</h4>
						}
						{subscriberGroupFields.map(mapSubscriberGroupBoxes)}
					</div>
	                {props.setShowSignup &&
                        <button className='bl-snippet-button' onClick={back}>Back</button>
	                }
					{props.error && <p className='bLoyal-center bLoyal-error-text'>{props.error}</p>}
					{/*@ts-ignore*/}
					{props.backLabel !== undefined && <button className='bl-snippet-button' onClick={props.onBack !== undefined ? props.onBack : () => {}}>{props.backLabel}</button>}
					<button className='bl-snippet-button' type='submit' disabled={isLoading || setCustomerStatus === 'loading'}>{props.isGift ? props.saveLabel ?? 'Next' : getButtonText(config.Buttons, 'Signup')}</button>
                </form>
	            {passwordState === PasswordState.Unmatching &&
				<p className='bLoyal-error-text'>Passwords must match.</p>
	            }
				{!validLoyaltyCard && checkLoyaltyCardNumber && loyaltyNumberChanged && <p className='bLoyal-error-text'>{`Loyalty Card Number must have ${SnippetConfig.LoyaltyCardMaxLength === null ? `at least ${SnippetConfig.LoyaltyCardMinLength} characters` : `between ${SnippetConfig.LoyaltyCardMinLength} and ${SnippetConfig.LoyaltyCardMaxLength} characters`} and include only ${SnippetConfig.LoyaltyCardCharacterType === CharacterTypeOptions.Numeric ? 'numbers' : SnippetConfig.LoyaltyCardCharacterType === CharacterTypeOptions.Alpha ? 'letters' : 'letters and numbers'} `}</p>}
				{!validRegisteredCard && checkRegisteredCardNumber && registeredCardChanged && <p className='bLoyal-error-text'>{`Registered Card Number must have ${SnippetConfig.RegisteredCardMaxLength === null ? `at least ${SnippetConfig.RegisteredCardMinLength} characters` : `between ${SnippetConfig.RegisteredCardMinLength} and ${SnippetConfig.RegisteredCardMaxLength} characters`} and include only ${SnippetConfig.RegisteredCardCharacterType === CharacterTypeOptions.Numeric ? 'numbers' : SnippetConfig.RegisteredCardCharacterType === CharacterTypeOptions.Alpha ? 'letters' : 'letters and numbers'} `}</p>}
				{isLoading &&
                <LoadingSpinner/>
				}
				{isError &&
				<p className='bLoyal-error-text'>{`${config.OnErrorMessage ? config.OnErrorMessage : 'There was an error completing the request.'}: ${error}`}</p>
				}
				{setCustomerStatus === 'error' &&
					<p className='bLoyal-error-text'>{`${config.OnErrorMessage ? config.OnErrorMessage : 'There was an error completing the request.'}: ${setCustomerError}`}</p>
				}
				<SnippetFooter footer={config.Footer} snippetCode={config.Code}/>
            </>
			}
		</div>
	);
}