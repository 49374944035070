import React, {ChangeEvent, useEffect, useMemo, useState} from 'react';
import {AccountManagementSnippetProfile, ChoiceResponse, MembershipType, WebSnippetProfile} from "../Helpers/Types";
import {Box, Grid, Tab, Tabs} from "@material-ui/core";
import Dashboard from "./Dashboard";
import PaymentMethodSnippet from "../PaymentMethod/PaymentMethodSnippet";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import useServiceUrls from "../Utils/UseServiceUrls";
import useGetCart from "../Utils/UseGetCart";
import useGetClubs from "../Utils/UseGetClubs";
import useDeviceSessionProfile from "../Utils/UseDeviceSessionProfile";
import useSnippetConfig from "../Utils/UseSnippetConfig";
import LoadingSpinner from "../LoadingSpinner";
import ShippingAddressSnippet from "../ShippingAddress/ShippingAddressSnippet";
import queryString from "query-string";
import CustomerDetailsSnippet from "../CustomerDetails/CustomerDetailsSnippet";
import {DeviceSessionProvider} from "../Utils/UseDeviceSessionProfileContext";
import {getParameterCaseInsensitive} from "../Helpers/Functions";
import SnippetHeader from "../Helpers/SnippetHeader";
import SnippetFooter from "../Helpers/SnippetFooter";
import SubscriberGroupsSnippet from "../SubscriberGroups/SubscriberGroupsSnippet";
import RegisterCardSnippet from "../RegisterCard/RegisterCardSnippet";
import {logout} from "../Utils/ApiClient";

function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
}

type AccountManagementSnippetProps = {
    snippetProfile: WebSnippetProfile
}

export default function AccountManagementSnippet(props: AccountManagementSnippetProps) {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    
    const snippetProfile = props.snippetProfile.AccountManagement
    const steps = useMemo(() => {
        let workingSteps = []
        if (snippetProfile?.ShowDashboard)
            workingSteps.push(
                {
                    TabText: snippetProfile?.DashboardTabText ? snippetProfile.DashboardTabText : 'Dashboard',
                    PageName: 'Dashboard'
                }
            )

        if (snippetProfile?.ShowCustomerDetails)
            workingSteps.push(
                {
                    TabText: snippetProfile?.CustomerDetailsTabText ? snippetProfile.CustomerDetailsTabText : 'Customer Details',
                    PageName: 'CustomerDetails'
                }
            )

        if (snippetProfile?.ShowShippingAddresses)
            workingSteps.push(
                {
                    TabText: snippetProfile?.ShippingAddressesTabText ? snippetProfile.ShippingAddressesTabText : 'Shipping Addresses',
                    PageName: 'ShippingAddresses'
                }
            )
        
        if (snippetProfile?.ShowPaymentMethods)
            workingSteps.push(
                {
                    TabText: snippetProfile?.PaymentMethodsTabText ? snippetProfile.PaymentMethodsTabText : 'Payment Methods',
                    PageName: 'PaymentMethods'
                }
            )
        
        if (snippetProfile?.ShowSubscriberGroups)
            workingSteps.push(
                {
                    TabText: snippetProfile?.SubscriberGroupsTabText ? snippetProfile.SubscriberGroupsTabText : 'Subscriber Groups',
                    PageName: 'SubscriberGroups'
                }
            )
        
        if (snippetProfile?.ShowRegisterCard)
            workingSteps.push(
                {
                    TabText: snippetProfile?.RegisterCardTabText ? snippetProfile.RegisterCardTabText : 'Registered Cards',
                    PageName: 'RegisterCard'
                }
            )

        return workingSteps
    }, [snippetProfile])

    // @ts-ignore
    const { domain, deviceCode } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const { deviceSessionProfileStatus, deviceSessionProfile } = useDeviceSessionProfile(serviceUrls, domain, deviceCode);
    const { SnippetConfigStatus: dashboardStatus, SnippetConfig: dashboardConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, snippetProfile?.DashboardSnippetCode ?? '');
    const { SnippetConfigStatus: shippingStatus, SnippetConfig: shippingConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, snippetProfile?.ShippingAddressesSnippetCode ?? '');
    const { SnippetConfigStatus: customerStatus, SnippetConfig: customerConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, snippetProfile?.CustomerDetailsSnippetCode ?? '');
    const { SnippetConfigStatus: paymentStatus, SnippetConfig: paymentConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, snippetProfile?.PaymentMethodSnippetCode ?? '');
    const { SnippetConfigStatus: groupsStatus, SnippetConfig: groupsConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, snippetProfile?.SubscriberGroupsSnippetCode ?? '');
    const { SnippetConfigStatus: registerCardStatus, SnippetConfig: registerCardConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, snippetProfile?.RegisterCardSnippetCode ?? '');

    const getStepOfPage = (page: string) => {
        const index = steps.findIndex((step: any) => step.PageName.toLowerCase() === page.toLowerCase())
        if (index === -1)
            return 0;
        
        return index
    }
    
    const [value, setValue] = useState(sessionStorage.getItem('blAccountPage') !== undefined ? getStepOfPage(sessionStorage.getItem('blAccountPage') ?? '') : 0)
    
    useEffect(() => {
        if (sessionStorage.getItem('blAccountPage') === undefined || sessionStorage.getItem('blAccountPage') === null) {
            const queryString = require('query-string')
            const parsed = queryString.parse(window.location.search)
            let initialPage = getParameterCaseInsensitive(parsed, 'bl_section')
            const index = getStepOfPage(initialPage ?? '')
            if (index >= 0)
                setValue(index)
        }
        return () => {
            sessionStorage.removeItem('blAccountPage')
        }
    }, [])
    
    useEffect(() => {
        if (steps.length > 0)
            sessionStorage.setItem('blAccountPage', steps[value]?.PageName)
    }, [value])
    
    const mapTabStep = (tab: any, index: number) => {
        return <Tab className={'bLoyal-account_tab'} key={`${tab.TabText}-${index}`} label={tab.TabText} value={index}/>
    }

    const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
        // @ts-ignore
        if (value === 'Logout')
            logout()
        setValue(newValue);
    };
    
    return (
        <div className='bLoyal-center bLoyal-account-management-page'>
            {deviceSessionProfileStatus === 'loading' && <LoadingSpinner />}
            {deviceSessionProfile &&
                <DeviceSessionProvider profile={deviceSessionProfile}>
                    {!snippetProfile && <p className={'bLoyal-error-text'}>No Snippet found</p>}
                    {snippetProfile &&
                        <>
                            <SnippetHeader logoUrl={props.snippetProfile.LogoUrl} title={props.snippetProfile.Title} message={props.snippetProfile.Message} snippetCode={props.snippetProfile.Code}/>
                            {/*@ts-ignore*/}
                            <Grid container>
                                {snippetProfile.ShowTabs &&
                                    <Grid item sm={windowSize.innerWidth <= 600 ? 12 : 3}
                                          className={windowSize.innerWidth <= 600 ? 'bLoyal-center-grid' : ''}>
                                        {/*@ts-ignore*/}
                                        <Box sx={{
                                            borderBottom: 1,
                                            borderColor: 'divider',
                                            maxWidth: {sm: 525, xs: 400}
                                        }}>
                                            {/*@ts-ignore*/}
                                            <Tabs orientation={windowSize.innerWidth <= 600 ? 'horizontal' : 'vertical'}
                                                  variant={windowSize.innerWidth <= 600 ? 'scrollable' : 'standard'}
                                                  scrollButtons={"on"} value={value} onChange={handleChange}
                                                  aria-label=""
                                                  indicatorColor='secondary'>
                                                {steps.map(mapTabStep)}
                                                return <Tab className={'bLoyal-account_tab'} key={`Logout`} label={'Logout'} value={'Logout'}/>
                                            </Tabs>
                                        </Box>
                                    </Grid>
                                }
                                <Grid item sm={snippetProfile.ShowTabs ? (windowSize.innerWidth <= 600 ? 12 : 9) : 12} className={snippetProfile.ShowTabs ? (windowSize.innerWidth <= 600 ? 'bLoyal-center-grid' : '') : 'bLoyal-center-grid'}>
                                    {steps[value]?.PageName === 'Dashboard' && dashboardConfig &&
                                        <Dashboard snippetConfig={dashboardConfig}/>
                                    }
                                    {steps[value]?.PageName === 'Dashboard' && dashboardStatus === 'loading' && <LoadingSpinner/>}
                                    {steps[value]?.PageName === 'Dashboard' && dashboardStatus === 'error' &&
                                        <p className='bLoyal-error-text'>{`Unable to load dashboard snippet ${snippetProfile.DashboardSnippetCode}`}</p>}
                                            
                                    {steps[value]?.PageName === 'CustomerDetails' && customerConfig &&
                                        <CustomerDetailsSnippet snippetProfile={customerConfig}/>
                                    }
                                    {steps[value]?.PageName === 'CustomerDetails' && customerStatus === 'loading' && <LoadingSpinner />}
                                    {steps[value]?.PageName === 'CustomerDetails' && customerStatus === 'error' &&
                                        <p className='bLoyal-error-text'>{`Unable to load customer details snippet ${snippetProfile.CustomerDetailsSnippetCode}`}</p>}
                                            
                                    {steps[value]?.PageName === 'ShippingAddresses' && shippingConfig &&
                                        <ShippingAddressSnippet giftCustomer={undefined} canDelete={true} snippetProfile={shippingConfig} allowShipping={true} showAll={true}/>
                                    }
                                    {steps[value]?.PageName === 'ShippingAddresses' && shippingStatus === 'loading' && <LoadingSpinner />}
                                    {steps[value]?.PageName === 'ShippingAddresses' && shippingStatus === 'error' &&
                                        <p className='bLoyal-error-text'>{`Unable to load shipping address snippet ${snippetProfile.ShippingAddressesSnippetCode}`}</p>}
                                            
                                    {steps[value]?.PageName === 'PaymentMethods' && paymentConfig &&
                                        <PaymentMethodSnippet snippetProfile={paymentConfig}/>
                                    }
                                    {steps[value]?.PageName === 'PaymentMethods' && paymentStatus === 'loading' && <LoadingSpinner/>}
                                    {steps[value]?.PageName === 'PaymentMethods' && paymentStatus === 'error' &&
                                        <p className='bLoyal-error-text'>{`Unable to load payment method snippet ${snippetProfile.PaymentMethodSnippetCode}`}</p>
                                    }

                                    {steps[value]?.PageName === 'SubscriberGroups' && groupsConfig &&
                                        <SubscriberGroupsSnippet snippetProfile={groupsConfig}/>
                                    }
                                    {steps[value]?.PageName === 'SubscriberGroups' && groupsStatus === 'loading' && <LoadingSpinner/>}
                                    {steps[value]?.PageName === 'SubscriberGroups' && groupsStatus === 'error' &&
                                        <p className='bLoyal-error-text'>{`Unable to load subscriber groups snippet ${snippetProfile.SubscriberGroupsSnippetCode}`}</p>
                                    }

                                    {steps[value]?.PageName === 'RegisterCard' && registerCardConfig &&
                                        <RegisterCardSnippet snippetProfile={registerCardConfig}/>
                                    }
                                    {steps[value]?.PageName === 'RegisterCard' && registerCardStatus === 'loading' && <LoadingSpinner/>}
                                    {steps[value]?.PageName === 'RegisterCard' && registerCardStatus === 'error' &&
                                        <p className='bLoyal-error-text'>{`Unable to load register card snippet ${snippetProfile.RegisterCardSnippetCode}`}</p>
                                    }
                                </Grid>
                                <SnippetFooter footer={props.snippetProfile.Footer} snippetCode={props.snippetProfile.Code}/>
                            </Grid>
                        </>
                    }
                </DeviceSessionProvider>
            }
        </div>
    )
}