

import { useMutation} from 'react-query';
import {client} from './ApiClient';
import {EGiftRecipient} from "../EGiftCard/EGiftCardSnippet";
import {EGiftCard} from "../Helpers/Types";

const mapCardAmounts = (cards: Array<EGiftCard>): Array<EGiftCard> => {
    return cards.map(c => {return {...c, Amount: c.Amount === 'Custom' ? c.CustomAmount?.toString() ?? '0' : c.Amount}})
}

const setEGiftCards = ({urls, domain, deviceCode, cartUid, cartExternalId, cartSourceExternalId, lineUid, productCode, cards }: {urls: any; domain: string; deviceCode: string; cartUid: string; cartExternalId: string; cartSourceExternalId: string; lineUid: string; productCode: string; cards: Array<EGiftCard>}) => {
    const body = {
        CartUid: cartUid,
        CartExternalId: cartExternalId ? cartExternalId : null,
        CartSourceExternalId: cartSourceExternalId ? cartSourceExternalId : null,
        LineUid: lineUid,
        ProductCode: productCode,
        Details: {
            GiftCards: mapCardAmounts(cards)
        }
    }
    return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/carts/commands/setegiftcards`, {
        body: body
    });
}

export default function useSetEGiftCards() {
    // @ts-ignore
    return useMutation(setEGiftCards)
}