import React, {useEffect, useState} from 'react';
import TextInput from "../TextInput";
import DateInput from "../Inputs/DateInput";
import dayjs, {Dayjs} from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {EGiftCard, EGiftCardSnippetProfile} from "../Helpers/Types";
import EmailInput from "../EmailInput";
import {getInputField, languageCode} from "../Helpers/Functions";

type SenderDetailsProps = {
    handleChange: (field: string, value: any) => void,
    state: EGiftCard,
    invalidInputs: Array<any>
    config?: EGiftCardSnippetProfile,
}

export default function SenderDetails(props: SenderDetailsProps) {
    const [name, setName] = useState(props.state.SenderName);
    const [email, setEmail] = useState(props.state.SenderEmail);
    const [date, setDate] = useState<Dayjs | null>(props.state.SendDate);

    useEffect(() => {
        setName(props.state.SenderName)
        setEmail(props.state.SenderEmail)
        setDate(props.state.SendDate)
    }, [props.state.SenderName, props.state.SenderEmail, props.state.SendDate]);

    const handleChange = (field: string, value: any) => {
        props.handleChange(field, value)
        switch (field) {
            case 'SenderName':
                setName(value);
                break;
            case 'SenderEmail':
                setEmail(value);
                break;
            case 'Date':
                setDate(value)
        }
    }
    
    if (languageCode.toLowerCase() !== 'en-us')
        require(`dayjs/locale/en-au`)

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={languageCode.toLowerCase()}>
            <h3>{props.config?.SenderSectionLabel ?? 'From'}</h3>
            <div>
                <TextInput id='SenderName' value={name} handleChange={handleChange}
                           label={getInputField(props.config?.EGiftFields ?? [], 'SenderName')?.Label ?? 'Sender Name'}
                           required={true} invalid={props.invalidInputs.includes('SenderName')} maxLength={50}/>
            </div>
            <div>
                <EmailInput id='SenderEmail' value={email} handleChange={handleChange}
                            label={getInputField(props.config?.EGiftFields ?? [], 'SenderEmail')?.Label ?? 'Sender Email'}
                            required={true} invalid={props.invalidInputs.includes('SenderEmail')} maxLength={100}/>
            </div>
            <div style={{paddingTop: '12px'}}>
                <DateInput id={'Date'} value={date} handleChange={handleChange}
                           label={getInputField(props.config?.EGiftFields ?? [], 'SendDate')?.Label ?? 'Date to be sent'}
                           disablePast={true} required={true}/>
            </div>
        </LocalizationProvider>
    );
}