import React, {SyntheticEvent, useEffect, useState} from 'react';
import {SubscriberGroupConfig, WebSnippetProfile} from "../Helpers/Types";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import {useAuth} from "../Utils/UseAuth";
import useServiceUrls from "../Utils/UseServiceUrls";
import CheckboxInput from "../CheckboxInput";
import {updateSubscriberGroupsFieldAction} from "../SignupActions";
import useGetSubscriberGroups from "../Utils/UseGetSubscriberGroups";
import useSaveMember from "../Utils/UseSaveMember";
import useUpdateCustomerGroups from "../Utils/UseUpdateCustomerGroups";
import {mapServiceUrls} from "../Helpers/Functions";
import LoadingSpinner from "../LoadingSpinner";
import SnippetHeader from "../Helpers/SnippetHeader";
import SnippetFooter from "../Helpers/SnippetFooter";

type SubscriberGroupsSnippetProps = {
    snippetProfile: WebSnippetProfile
}

const getGroupsFromCustomer = (customer: any) => {
    if (!customer)
        return []
    
    return customer.SubscriberGroupMemberships.map((m: any) => m.Group.Uid)
}

export default function SubscriberGroupsSnippet(props: SubscriberGroupsSnippetProps) {
    const config = props.snippetProfile.SubscriberGroups;
    const { domain, deviceCode } = useSnippetContext();
    const { session, customer } = useAuth();
    const { serviceUrls } = useServiceUrls(domain);

    const [groups, setGroups] = useState(getGroupsFromCustomer(customer))
    
    const [updateGroups, {status, data, reset, error}] = useUpdateCustomerGroups();
    
    useEffect(() => {
        getGroupsFromCustomer(customer)
    }, [customer])

    useEffect(() => {
        if (error)
            console.log(error)
    }, [error]);

    const handleSubscriberGroupChange = (value: boolean, group: string) => {
        
        let currentGroups = groups.slice();
        if (value)
            currentGroups.push(group);
        else
            currentGroups = currentGroups.filter((curr: any) => curr !== group)

        setGroups(currentGroups)
    }
    
    const update = (e: SyntheticEvent) => {
        e.preventDefault()
        updateGroups({ urls: mapServiceUrls(serviceUrls), deviceCode, domain, session, groups })
    }

    const mapSubscriberGroupBoxes = (group: SubscriberGroupConfig, index: number) => {
        if (!group.Display)
            return;
        
        const value = groups.some((g: any) => g === group.Uid);
        return (
            <div key={index} style={{textAlign: 'left'}}>
                <CheckboxInput key={group.Uid} id={group.Uid} value={value} label={group.Name} handleChange={handleSubscriberGroupChange} />
            </div>
        )
    }
    
    return (
        <div className="bLoyal-center" >
            <SnippetHeader logoUrl={props.snippetProfile?.LogoUrl ?? ''} title={props.snippetProfile?.Title ?? ''} message={props.snippetProfile?.Message ?? ''} snippetCode={props.snippetProfile?.Code ?? ''}/>
            <div className={'bLoyal-center bLoyal-subscriber-groups-holder'}>
                {config?.SubscriberGroups?.map(mapSubscriberGroupBoxes)}
                <button onClick={update} >{props.snippetProfile.Buttons[0].Text ?? 'Save'}</button>
            </div>
            {status === 'loading' && <LoadingSpinner />}
            {error && <p className={'bLoyal-error-text'}>Unable to update groups.</p>}
            <SnippetFooter footer={props.snippetProfile?.Footer ?? ''} snippetCode={props.snippetProfile?.Code ?? ''}/>
        </div>
    )
}