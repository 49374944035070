import React, {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import {DeliveryType, IAddressState, IShippingAddress, WebSnippetProfile} from "../Helpers/Types";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import useServiceUrls from "../Utils/UseServiceUrls";
import useShippingAddress from "../Utils/UseShippingAddress";
import {useAuth} from "../Utils/UseAuth";
import SelectInput from "../SelectInput";
import {FormControlLabel, Grid, Radio, RadioGroup} from "@material-ui/core";
import LoadingSpinner from "../LoadingSpinner";
import Address from "../Address";
import usePickupLocations from "../Utils/UsePickupLocations";
import SnippetHeader from "../Helpers/SnippetHeader";
import SnippetFooter from "../Helpers/SnippetFooter";
import List from "../Helpers/List";
import useDeviceSessionProfile from "../Utils/UseDeviceSessionProfile";
import {mapServiceUrls, updateLanguageCode} from "../Helpers/Functions";
import useDeletePaymentMethod from "../Utils/UseDeletePaymentMethod";
import useDeleteShippingAddress from "../Utils/UseDeleteShippingAddress";

type ShippingAddressSnippetProps = {
    snippetProfile: WebSnippetProfile
    onSelect?: (field: string, value: any) => void
    giftCustomer: any
    allowShipping: boolean
    canDelete?: boolean
    allowPickup?: boolean
    initialValue?: string
    initialType?: DeliveryType,
    showAll?: boolean
    inventoryLocations?: Array<string>
    setGiftAddress?: (address: IAddressState) => void
    giftAddress?: IAddressState
}

export default function ShippingAddressSnippet(props: ShippingAddressSnippetProps) {
    const snippetProfile = props.snippetProfile.ShippingAddresses
    const canDelete = useMemo(() => props.canDelete ?? false, [props.canDelete])
    const isGift = useMemo(() => !!props.giftCustomer, [props.giftCustomer])
    const [add, setAdd] = useState(isGift)
    const [edit, setEdit] = useState(false)
    const[selectedAddress, setSelectedAddress] = useState(props.initialValue ?? '')
    const [tempTitle, setTempTitle] = useState('')

    // @ts-ignore
    const { session, customer } = useAuth();
    // @ts-ignore
    const { domain, deviceCode } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    // @ts-ignore
    const {profile } = useDeviceSessionProfile(serviceUrls, domain, deviceCode)
    const { shippingAddressesStatus, shippingAddresses, shippingAddressesError, shippingAddressesFetching } = useShippingAddress(serviceUrls, isGift ? '' : session, domain, deviceCode, isGift);
    const { pickupLocationStatus, pickupLocations, pickupLocationError } = usePickupLocations(serviceUrls, session, domain, deviceCode);
    const [deliveryType, setDeliveryType] = useState(props.initialType ? props.initialType : !props.allowShipping ? DeliveryType.Pickup : DeliveryType.Ship)

    const [deleteShippingAddress, { status, error}] = useDeleteShippingAddress();

    const addressOptions = useMemo(() => {
        if (shippingAddresses) {
            return shippingAddresses?.data.map((address: any) => {
                return {
                    value: address.Uid.toString(),
                    text: `${address.Title.toString()} - ${address.Address.Address1.toString()}`,
                    checked: selectedAddress === address.Uid
                };
            })
        }
        return []
    }, [shippingAddresses])
    
    const pickupOptions = useMemo(() => {
        if (pickupLocations) {
            return pickupLocations.map((location: any) => {
                return {
                    value: location.Uid,
                    text: `${location.Name} - ${location.Code}`,
                    checked: selectedAddress === location.Uid
                }
            }).filter((location: any) => {
                if (props.inventoryLocations === undefined)
                    return true
                
                return props.inventoryLocations.some((il) => location.value === il)
            })
        }
        return []
    }, [pickupLocations])
    
    useEffect(() => {
        if (shippingAddresses && pickupLocations) {
            if (deliveryType === DeliveryType.Ship) {
                setSelectedAddress(shippingAddresses.data.find((a: any) => a.Uid === selectedAddress)?.Uid ?? shippingAddresses.data[0]?.Uid ?? '')
                props.onSelect?.('shippingAddress', shippingAddresses.data.find((a: any) => a.Uid === selectedAddress) ?? shippingAddresses.data[0])
            } else if (deliveryType === DeliveryType.Pickup) {
                setSelectedAddress(pickupLocations.find((l: any) => l.Uid === selectedAddress)?.Uid ?? pickupLocations[0]?.Uid ?? '')
                props.onSelect?.('PickupLocation', pickupLocations.find((l: any) => l.Uid === selectedAddress) ?? pickupLocations[0])
            }
        }
    }, [deliveryType, shippingAddresses, pickupLocations])
    
    useEffect(() => {
        if (tempTitle) {
            const newAddress = shippingAddresses?.data.find((a: any) => a.Uid === tempTitle)
            if (newAddress) {
                setSelectedAddress(newAddress?.Uid)
                setTempTitle('')
                props.onSelect?.('shippingAddress', newAddress)
            }
        }
    }, [tempTitle, shippingAddresses])
    
    useEffect(() => {
        updateLanguageCode(profile)
    }, [profile])

    const handleChange = (field: string, value: string) => {
        props.onSelect?.('shippingAddress', shippingAddresses?.data.find((a: any) => a.Uid === value))
        setSelectedAddress(value)
    }
    
    const handlePickupChange = (field: string, value: string) => {
        props.onSelect?.('PickupLocation', pickupLocations?.find((p: any) => p.Uid === value))
        setSelectedAddress(value)
    }
    
    const handleRadioChange = (e: SyntheticEvent) => {
        // @ts-ignore
        const value = e.target.value;
        setDeliveryType(value);
    }
    
    const addAddress = (e: SyntheticEvent) => {
        e.preventDefault()
        setAdd(true)
    }
    
    const setNew = (address: any) => {
        setTempTitle(address.Uid)
        setAdd(false)
        props.onSelect?.('shippingAddress', address)
    }

    const editAddress = (e: SyntheticEvent) => {
        e.preventDefault()
        setEdit(true)
    }
    
    const finishEdit = (title: string) => {
        setEdit(false)
    }

    const deleteAddress = (e: SyntheticEvent) => {
        e.preventDefault()
        deleteShippingAddress({urls: mapServiceUrls(serviceUrls), addressUid: selectedAddress, deviceCode, domain, session})
    }
    
    const getSelectDisplay = () => {
        return (
            <>
                <Grid item sm={isGift ? 10 : 8} xs={12} className='bLoyal-center'>
                    <SelectInput label='Choose Shipping Address' options={addressOptions}
                                 current={selectedAddress}
                                 disabled={isGift}
                                 fieldName='ShippingAddress' handleChange={handleChange}/>
                </Grid>
                <Grid item sm={isGift ? 2 : 4} xs={12} className='bLoyal-center grid-button'>
                    <button className='bl-snippet-button' onClick={addAddress}>{snippetProfile?.AddButtonText}</button>
                    {canDelete &&
                        <button className='bl-snippet-button' onClick={deleteAddress} disabled={selectedAddress === '' || (shippingAddresses?.data?.find((a: any) => a.Uid === selectedAddress)?.IsClubAddress ?? false)}>{snippetProfile?.DeleteButtonText}</button>}
                    {!isGift &&
                        <button className='bl-snippet-button' onClick={editAddress} disabled={selectedAddress === ''}>{snippetProfile?.EditButtonText}</button>}
                </Grid>
                <br/>
                <br/>
            </>
        )
    }
    
    const mapAddress = (address: IShippingAddress, index: number) => {
        return (
            <div key={index} className={`bLoyal-list-item ${selectedAddress === address.Uid ? 'selected' : ''}`} onClick={() => setSelectedAddress(address.Uid ?? '')}>
                <div>{`${address.Title}${address.IsClubAddress ? ' - Address is used on a club' : ''}`}</div>
                <div>{`${address.Address.Address1} ${address.Address.City}`}</div>
            </div>
        )
    }
    
    const getListDisplay = () => {
        return (
            <div className={'bLoyal-center'} style={{width: '35%', minWidth: '400px'}}>
                <List data={shippingAddresses.data} displayFunction={mapAddress} />
                <button className='bl-snippet-button' onClick={addAddress}>{snippetProfile?.AddButtonText}</button>
                {canDelete &&
                    <button className='bl-snippet-button' onClick={deleteAddress} disabled={selectedAddress === '' || (shippingAddresses?.data?.find((a: any) => a.Uid === selectedAddress)?.IsClubAddress ?? false)}>{snippetProfile?.DeleteButtonText}</button>}
                {!isGift &&
                    <button className='bl-snippet-button' onClick={editAddress} disabled={selectedAddress === ''}>{snippetProfile?.EditButtonText}</button>}
            </div>
        )
    }
    
    if (props.allowPickup && props.allowShipping)
        return (
            <div className='bLoyal-center bLoyal-shipping-addresses-page'>
                {!snippetProfile && <p className={'bLoyal-error-text'}>No Snippet found</p>}
                {snippetProfile &&
                    <>
                        <SnippetHeader logoUrl={props.snippetProfile.LogoUrl} title={props.snippetProfile.Title} message={props.snippetProfile.Message} snippetCode={props.snippetProfile.Code}/>
                            {shippingAddressesStatus === 'loading' && <LoadingSpinner/>}
                        {!isGift &&
                            <>
                            {add && <Address fields={snippetProfile.AddressFields} customer={props.giftCustomer ?? customer}
                                             sessionKey={session}
                                             address={null}
                                             toggleEdit={setNew} onSuccessMessage={props.snippetProfile.OnSuccessMessage} setGiftAddress={props.setGiftAddress} isGift={isGift}/>}
                            {edit && <Address fields={snippetProfile.AddressFields} customer={props.giftCustomer ?? customer}
                                              sessionKey={session}
                                              address={shippingAddresses.data.find((curr: IShippingAddress) => curr.Uid === selectedAddress)}
                                              toggleEdit={finishEdit} onSuccessMessage={props.snippetProfile.OnSuccessMessage} setGiftAddress={props.setGiftAddress} isGift={isGift}/>}
                            </>
                        }
                        <RadioGroup aria-label={''} name="pickupOrShip" value={deliveryType} onChange={handleRadioChange}>
                        {((shippingAddresses && !add && !edit) || isGift) &&
                                <>
                                        <Grid container>
                                            <Grid item sm={4} >
                                                <FormControlLabel key={'Ship'} value={DeliveryType.Ship} control={<Radio color='default'/>} label={snippetProfile.ShipText}  />
                                            </Grid>
                                            {!isGift && 
                                                <>
                                                    <Grid item sm={8} xs={12} className='bLoyal-center'>
                                                        <SelectInput label='Choose Shipping Address' options={addressOptions}
                                                                 current={deliveryType === DeliveryType.Ship ? selectedAddress : ''}
                                                                 disabled={isGift || deliveryType === DeliveryType.Pickup}
                                                                 fieldName='ShippingAddress' handleChange={handleChange}/>
                                                    </Grid>
                                                    <Grid item xs={12} className='bLoyal-center grid-button'>
                                                        <button className='bl-snippet-button' onClick={addAddress}>{snippetProfile.AddButtonText}</button>
                                                        {canDelete &&
                                                            <button className='bl-snippet-button' onClick={deleteAddress} disabled={selectedAddress === '' || deliveryType === DeliveryType.Pickup || (shippingAddresses?.data?.find((a: any) => a.Uid === selectedAddress)?.IsClubAddress ?? false)}>{snippetProfile.DeleteButtonText}</button>}
                                                        {!isGift &&
                                                            <button className='bl-snippet-button' onClick={editAddress} disabled={selectedAddress === '' || deliveryType === DeliveryType.Pickup}>{snippetProfile.EditButtonText}</button>}
                                                    </Grid>
                                                </>
                                            }
                                            {isGift && deliveryType === DeliveryType.Ship &&
                                                <Grid item sm={12} className='bLoyal-center'>
                                                    <Address fields={snippetProfile.AddressFields} customer={props.giftCustomer ?? customer}
                                                             sessionKey={session}
                                                             address={props.giftAddress?.ShippingAddress ?? null}
                                                             toggleEdit={setNew} onSuccessMessage={props.snippetProfile.OnSuccessMessage} setGiftAddress={props.setGiftAddress} isGift={isGift}/>
                                                </Grid>
                                            }
                                        </Grid>
                                </>
                            }
                        <br />
                        <br />
                        {pickupLocations &&
                                <Grid container>
                                    <Grid item sm={4}>
                                        <FormControlLabel key={'Pickup'} value={DeliveryType.Pickup} control={<Radio color='default'/>} label={snippetProfile.PickupText}  />
                                    </Grid>
                                    <Grid item sm={8} xs={12} className='bLoyal-center'>
                                        <SelectInput label='Choose Pickup Location' options={pickupOptions}
                                                     current={deliveryType === DeliveryType.Pickup ? selectedAddress : ''}
                                                     disabled={deliveryType === DeliveryType.Ship}
                                                     fieldName='PickupLocation' handleChange={handlePickupChange}/>
                                    </Grid>
                                </Grid>
                        }
                        </RadioGroup>
                        <SnippetFooter footer={props.snippetProfile.Footer} snippetCode={props.snippetProfile.Code}/>
                    </>
                }
            </div>
        )
    else if (props.allowShipping && !props.allowPickup)
        return (
            <div className='bLoyal-center bLoyal-shipping-addresses-page'>
                {!snippetProfile && <p className={'bLoyal-error-text'}>No Snippet found</p>}
                {snippetProfile &&
                    <>
                        <SnippetHeader logoUrl={props.snippetProfile.LogoUrl} title={props.snippetProfile.Title} message={props.snippetProfile.Message} snippetCode={props.snippetProfile.Code}/>
                        <Grid container>
                            
                            {shippingAddressesStatus === 'loading' && <LoadingSpinner/>}
                            {add && <Address fields={snippetProfile.AddressFields} customer={props.giftCustomer ?? customer}
                                             sessionKey={session}
                                             address={null}
                                             toggleEdit={setNew} onSuccessMessage={props.snippetProfile.OnSuccessMessage} setGiftAddress={props.setGiftAddress} isGift={isGift}/>}
                            {edit && <Address fields={snippetProfile.AddressFields} customer={props.giftCustomer ?? customer}
                                              sessionKey={session}
                                              address={shippingAddresses.data.find((curr: IShippingAddress) => curr.Uid === selectedAddress)}
                                              toggleEdit={finishEdit} onSuccessMessage={props.snippetProfile.OnSuccessMessage} setGiftAddress={props.setGiftAddress} isGift={isGift}/>}
                            {shippingAddresses && !add && !edit &&
                                <>
                                {props.showAll && getListDisplay()}
                                {!props.showAll && getSelectDisplay()}
                                </>
                            }
                        </Grid>
                        <SnippetFooter footer={props.snippetProfile.Footer} snippetCode={props.snippetProfile.Code}/>
                    </>
                }
            </div>
        )
    else if (props.allowPickup && !props.allowShipping)
        return (
            <div className='bLoyal-center bLoyal-shipping-addresses-page'>
                {!snippetProfile && <p className={'bLoyal-error-text'}>No Snippet found</p>}
                {snippetProfile &&
                    <>
                        <SnippetHeader logoUrl={props.snippetProfile.LogoUrl} title={props.snippetProfile.Title} message={props.snippetProfile.Message} snippetCode={props.snippetProfile.Code}/>
                        {pickupLocationStatus === 'loading' && <LoadingSpinner/>}
                        {pickupLocations &&
                            <Grid container>
                                <Grid item sm={12} className='bLoyal-center'>
                                    <SelectInput label='Choose Pickup Location' options={pickupOptions}
                                                 current={selectedAddress}
                                                 fieldName='PickupLocation' handleChange={handlePickupChange} disabled={false}/>
                                </Grid>
                            </Grid>
                        }
                        <SnippetFooter footer={props.snippetProfile.Footer} snippetCode={props.snippetProfile.Code}/>
                    </>
                }
            </div>
        )
    else
        return (<></>)
}