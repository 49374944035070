import React, {useEffect} from 'react';
import {AvailableCoupon, WebSnippetProfile} from "../Helpers/Types";
import {Grid} from "@mui/material";
import DashboardCouponDisplay from "./DashboardCouponDisplay";

type CouponSectionProps = {
    snippetConfig: WebSnippetProfile
    customerSession: any
    applyCoupon: (code: string) => void
    onCompleteExists: boolean
}

export default function CouponSection({applyCoupon, snippetConfig, customerSession, onCompleteExists}: CouponSectionProps) {
    const mapCoupon = (coupon: AvailableCoupon, index: number) => {
        return <Grid key={index} item className='dashboard-coupon-col'>
            <DashboardCouponDisplay coupon={coupon} key={index} apply={applyCoupon} config={snippetConfig.CustomerDashboard} onCompleteExists={onCompleteExists ? onCompleteExists : false} />
        </Grid>
    }
    
    return (
        <>
            <h2>{snippetConfig.CustomerDashboard?.CouponHeaderLabel ?? 'Available Coupons'}</h2>
            <br />
            <Grid container justifyContent={'space-around'}>
                {customerSession?.StandardCoupons?.map(mapCoupon)}
                {customerSession?.AwardedCoupons?.map(mapCoupon)}
            </Grid>
        </>
    )
}