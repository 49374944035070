import React, {SyntheticEvent, useEffect, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextInput from "../TextInput";
import {validateRegisteredCardNumber} from "../Helpers/Functions";
import {CharacterTypeOptions, RegisterCardSnippetProfile, TrackedCard} from "../Helpers/Types";
import LoadingSpinner from "../LoadingSpinner";

type ManageCardDialogProps = {
    cancel: () => void;
    title: string;
    makePrimary: () => void
    snippetProfile: RegisterCardSnippetProfile;
    delete: string
    loading: boolean
    primary: string
    error?: any
    card?: TrackedCard;
}

export default function ManageCardDialog(props: ManageCardDialogProps) {
    const [cardNumber, setCardNumber] = useState('')
    const [validRegisteredCard, setValidRegisteredCard] = useState(false)
    const [submitted, setSubmitted] = useState<boolean>(false);

    useEffect(() => {
        console.log(props.error)
    }, [props.error]);
    
    const close = (e: SyntheticEvent) => {
        e.preventDefault();
        props.cancel();
    }

    const confirm = (e: SyntheticEvent) => {
        e.preventDefault()
        props.makePrimary();
    }
    
    const deleteCard = (e: SyntheticEvent) => {
        e.preventDefault()
    }

    return (
        <Dialog className={'bLoyal-dialog'} maxWidth='sm' fullWidth={true} open={true}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent className='dialog-body bLoyal-center'>
                <div className='bLoyal-center bLoyal-dialog-body'>
                    <div className="bLoyal-gift-card bLoyal-center">
                        <div className="bLoyal-stripe"></div>
                        <div className="bLoyal-gift-card-body"></div>
                    </div>
                    <div>{`${props.card?.Code} - $${props.card?.Balance}`}</div>
                    <button onClick={confirm}>{props.primary}</button>
                    <br/>
                    <button onClick={deleteCard}>{props.delete}</button>
                    {props.loading && <LoadingSpinner />}
                    {props.error && <p className={'bLoyal-error-text'}>{props.error.toString()}</p>}
                </div>
            </DialogContent>
            <DialogActions>
                <button style={{float: 'left'}} onClick={close}>
                    Close
                </button>
            </DialogActions>
        </Dialog>
    )
}