import React, {useEffect, useState} from 'react';
import {CartProvider} from "../Utils/UseCartContext";
import PreProcessWrapper from "../PreProcessWrapper";
import {ReactQueryDevtools} from "react-query-devtools";
import {SnippetProvider} from "../Utils/UseSnippetContext";
import useServiceUrls from "../Utils/UseServiceUrls";
import {getParameterCaseInsensitive} from "../Helpers/Functions";
import useMyRewardsConfig from "../Utils/UseMyRewardsConfig";
import LoadingSpinner from "../LoadingSpinner";

type HostedSnippetProps = {

}

export default function HostedSnippet(props: HostedSnippetProps) {
    const [domain, setDomain] = useState("")
    const [code, setCode] = useState("")
    const [deviceCode, setDeviceCode] = useState("");
    const [snippetArgs, setSnippetArgs] = useState<any>({})
    const {status, myRewardsConfig, error} = useMyRewardsConfig(domain);

    useEffect(() => {
        const queryString = require('query-string');
        const parsed = queryString.parse(window.location.search);
        const args = getParameterCaseInsensitive(parsed, 'bl_snippetargs')
        const jsonArgs = JSON.parse(args ?? '{}')
        setCode(getParameterCaseInsensitive(parsed, 'bl_snippetcode'))
        setDomain(getParameterCaseInsensitive(parsed, 'bl_logindomain'))
        setSnippetArgs({...snippetArgs, ...jsonArgs, DeviceCode: jsonArgs.DeviceCode ?? ""})
    }, [])

    useEffect(() => {
        if (myRewardsConfig && deviceCode !== myRewardsConfig.General?.DeviceCode)
            setDeviceCode(myRewardsConfig.General?.DeviceCode);
    }, [myRewardsConfig?.General?.DeviceCode, deviceCode]);

    if (code === "" || code === undefined || domain === "" || domain === undefined || deviceCode === "" || deviceCode === undefined)
        return <></>
    else if (status === 'loading')
        return <LoadingSpinner />
    else if (status === 'error')
        return <p className={'bLoyal-error-text'}>{error}</p>
    else
        return (
            <SnippetProvider domain={domain} deviceCode={deviceCode ?? ''}
                             clubCode={snippetArgs.ClubCode ?? ''} useLogout={snippetArgs.UseLogout}
                             returnUrlParam={snippetArgs.ReturnUrlParameter ?? 'ReturnUrl'} loginUrl={snippetArgs.LoginUrl ?? ''}
                             cartUid={snippetArgs.CartUid} cartExternalId={snippetArgs.CartExternalId}
                             cartSourceExternalId={snippetArgs.CartSourceExternalId} cartAmount={snippetArgs.CartAmount}
                             onCompleteFunction={snippetArgs.OnSnippetComplete ?? 'blOnSnippetComplete'}
                             lineUid={snippetArgs.LineUid} alertUid={snippetArgs.AlertUid} redirectToHome={snippetArgs.PaymentRedirectToHome}
                             productCode={snippetArgs.ProductCode}>
                <CartProvider >
                    <PreProcessWrapper snippetCode={code}/>
                    <ReactQueryDevtools initialIsOpen={false}/>
                </CartProvider>
            </SnippetProvider>
        )
}