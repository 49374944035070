import {useMutation} from 'react-query';
import {client} from './ApiClient';
import { v4 as uuid } from 'uuid';
import {mapSignupStateToLESignupCommand} from "../Helpers/Maps";
import {ISignupState} from "../Helpers/Types";

const signupCustomer = ({urls, domain, deviceCode, signupState, session, registeredCardNumber}: {urls: any; domain: string; deviceCode: string; signupState: ISignupState; session: string; registeredCardNumber?: string}) => {
	const body = mapSignupStateToLESignupCommand(signupState)
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/snippets/customer${session ? `/${session}` : ''}/commands/signups`, {
		body: {
			...body,
			TrackingKey: registeredCardNumber
		}
	});
}

export default function useSignupCustomer() {
	// @ts-ignore
	return useMutation(signupCustomer)
}