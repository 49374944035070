import React, {useEffect, useState} from 'react';
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import TableInput from './TableInput';
import {ClubSignupProductConfiguration, ProductPreference, QuantityType} from './Helpers/Types';
import {getPercentOffPrice, getUnitPriceDisplay, isMobile, safeConvertStringToNumber} from "./Helpers/Functions";
import ProductAmountSelection from "./ClubSignup/ProductAmountSelection";
import useServiceUrls from "./Utils/UseServiceUrls";
import {useSnippetContext} from "./Utils/UseSnippetContext";
import useDeviceSessionProfile from "./Utils/UseDeviceSessionProfile";
import {Paper} from "@mui/material";

type ProductSelectionProps = {
	products: Array<any>
	setQuantity: (value: number) => void
	setPrice: (value: number) => void
	getValue: Function
	valuesUpdated: () => void
	productConfigs?: Array<ClubSignupProductConfiguration>
}

const useStyles = makeStyles({
	table: {
		minWidth: 400
	},
});

interface Row {
	Title: string,
	Quantity: number,
	Price: number,
	Type: string,
}

export default function ProductSelection(props: ProductSelectionProps) {
	const { domain, deviceCode } = useSnippetContext();
	const { serviceUrls } = useServiceUrls(domain);
	const { deviceSessionProfile: profile } = useDeviceSessionProfile(serviceUrls, domain, deviceCode)
	const classes = useStyles();
	let totalQuantity: number = 0;
	let totalPrice: number = 0.00;
	const [products, setProducts] = useState(props.products.map((product: any) => {
		return { ...product, Quantity: product.SelectedQuantity ?? 0 }
	}));

	useEffect(() => {
		let newProds: Array<Row> = [...products]

		let selectedProducts: Array<ProductPreference> = []
		newProds.forEach((prod: any) => {
			if (prod.Quantity === 0)
				return;
			const config = props.productConfigs?.find(pc => pc.Code === prod.Code)
			const temp = {
				ProductUid: prod.Uid,
				Quantity: prod.Quantity,
				Price: config?.PromptForPrice ? prod.BasePrice : undefined,
			}
			selectedProducts.push(temp)
		})
		
		props.getValue(selectedProducts)
	}, [])

	function updatePriceAndQuantity(newProds: Array<Row>) {
		let quantity = 0;
		let price = 0.00;
		newProds.forEach((product: any) => {
			quantity += product.Quantity;
			price += getPercentOffPrice(product);
		})
		props.setQuantity(quantity);
		price = parseFloat(price.toFixed(2))
		props.setPrice(price);
		setProducts(newProds);
	}

	function updateSelectedProducts(newProds: Array<Row>) {
		let selectedProducts: Array<ProductPreference> = []
		newProds.forEach((prod: any) => {
			if (prod.Quantity === 0)
				return;
			const config = props.productConfigs?.find(pc => pc.Code === prod.Code)
			const temp = {
				ProductUid: prod.Uid,
				Quantity: prod.Quantity,
				Price: config?.PromptForPrice ? prod.BasePrice : undefined,
			}
			selectedProducts.push(temp)
		})
		props.valuesUpdated()
		props.getValue(selectedProducts);
	}

	const updateQuantity = (num: number, rowNum: number) => {
		let value: number = num
		if (isNaN(value))
			value = 0
		
		let updatedProduct = {
			...products[rowNum],
			Quantity: value
		}
		
		let newProds: Array<Row> = [...products]
		newProds[rowNum] = updatedProduct

		updatePriceAndQuantity(newProds);
		updateSelectedProducts(newProds);
	}
	
	const updatePrice = (product: any, price: number) => {
		let newProds: Array<Row> = products.slice()
		newProds = newProds.map(p => {
			// @ts-ignore
			if (p.Name === product.Name)
				return {
					...p,
					BasePrice: price,
					Price: price
				}
			
			return p;
		})
		
		updatePriceAndQuantity(newProds);
		updateSelectedProducts(newProds)
	}
	
	if (products.length > 0) {
		products.forEach((product: any) => {
			totalQuantity = totalQuantity + product.Quantity;
			totalPrice += getPercentOffPrice(product);
			totalPrice = parseFloat(totalPrice.toFixed(2));
		})
	}
	

	// @ts-ignore
	return (
		// <div className='bLoyal-center' style={isMobile() ? {width: '100%'} : { width: '100%' }}>
            <TableContainer component={Paper} style={{width: '98%'}} className={'bLoyal-center'}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><strong>Item</strong></TableCell>
							<TableCell align='right'><strong>Unit Price</strong></TableCell>
                            <TableCell align="right"><strong>Quantity</strong></TableCell>
	                        <TableCell align='right'><strong>Price</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
						{products.map((product: any, index: number) => {
							const config = props.productConfigs?.find(pc => pc.Code === product.Code)
							return (
							<TableRow key={product.Name}>
								<TableCell component='th' scope='row'>
									{config?.ProductUrl ? <a href={config.ProductUrl} target='_blank'
															 rel={'noopener noreferrer'}>{product.Name}</a> : <>{product.Name}</>}
								</TableCell>
								{/*@ts-ignore*/}
								{!(config?.PromptForPrice ?? false) &&
									<TableCell align='right'
										   dangerouslySetInnerHTML={{__html: getUnitPriceDisplay(product)}}></TableCell>
								}
								{config?.PromptForPrice &&
									<TableCell align='right'><ProductAmountSelection product={product} config={config} currencySymbol={profile?.Globalization?.CurrencySymbol ?? ''} updatePrice={updatePrice}/></TableCell>
								}
								<TableCell align='right'>
									{product.QuantityType === QuantityType.Options &&
										<TableInput options={product.QuantityValue}
													id={product.Name + '-quantity'} value={product.Quantity} label=''
													getValue={updateQuantity} rowNum={index} type='options'/>
									}
									{product.QuantityType !== QuantityType.Options &&
										<TableInput id={product.Name + '-quantity'}
													value={isNaN(product.Quantity) ? 0 : product.Quantity} label=''
													getValue={updateQuantity} rowNum={index} type='number'/>
									}
								</TableCell>
								<TableCell align='right'>{`$${getPercentOffPrice(product).toFixed(2)}`}</TableCell>
								
							</TableRow>
							)
						})}
						<TableRow>
							<TableCell><strong>Totals:</strong></TableCell>
							<TableCell></TableCell>
							<TableCell align='right'><strong>{totalQuantity > 0 ? totalQuantity : 0}</strong></TableCell>
							<TableCell align='right'><strong>{`$${(totalPrice > 0 ? totalPrice : 0).toFixed(2)}`}</strong></TableCell>
						</TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
		// </div>
	);
}