import {Accordion, AccordionDetails, AccordionSummary, Grid, Typography} from '@mui/material';
import React, {useEffect, useMemo, useState} from 'react';
import useWindowSize from "../Hooks/UseWindowSize";
import {EGiftCard, EGiftCardSnippetProfile, ImageOption} from "../Helpers/Types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type SelectDesignProps = {
    handleChange: (field: string, value: any) => void
    state: EGiftCard
    config?: EGiftCardSnippetProfile
}

const style = {
    // width: '100px',  // Set the width of the thumbnail
    // height: '100px', // Set the height of the thumbnail
    borderRadius: '8px', // Slightly round the corners
    height: '75px',
    cursor: 'pointer',
};

const previewStyle = {
    width: '80%',  // Set the width of the thumbnail
    maxWidth: '500px',
    borderRadius: '8px' // Slightly round the corners
};

export default function DesignSelection(props: SelectDesignProps) {
    const {width} = useWindowSize();
    const [showMore, setShowMore] = useState(false);
    const [showAll, setShowAll] = useState(false);
    const [selected, setSelected] = useState(props.state.ImageUrl);
    const [expanded, setExpanded] = React.useState<string | false>(false);
    
    const images = useMemo(() => {
        const temp = props.config?.ImageOptions?.map(o => {
            if (typeof o === 'string') {
                return {
                    Url: o,
                    Category: ''
                }
            }
            return o
        })
        return temp
    }, [props.config])
    const categories = useMemo(() => {
        let temp = images?.map(o => o.Category) ?? []
        temp = Array.from(new Set(temp))
        return temp
    }, [images])

    useEffect(() => {
        setExpanded(categories[0] ? categories[0] : false)
    }, []);

    useEffect(() => {
        setSelected(props.state.ImageUrl)
    }, [props.state.ImageUrl]);

    useEffect(() => {
        if (!width)
            return;
        
        if (width < 450 && (props.config?.ImageOptions.length ?? 0) > 12) 
            setShowMore(true)
        else if (width < 375 && (props.config?.ImageOptions.length ?? 0) > 9)
            setShowMore(true)
        else if (showMore)
            setShowMore(false)
    }, [showMore, width]);
    
    const setSelectedImage = (url: string) => {
        setSelected(url)
        props.handleChange('ImageUrl', url)
    }
    
    const mapImageOption = (image: ImageOption, index: number) => {
        if (!showAll && index > 11 && showMore)
            return <></>
        
        return (
            <Grid item xs={6} sm={4} lg={3} key={index}>
                <img src={image.Url} alt={`EGift Option ${index}`} className={'bLoyal-image-thumbnail'} onClick={() => setSelectedImage(image.Url)} />
            </Grid>
        )
    }

    const handleAccordionChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const mapAccordionImageOption = (image: ImageOption, index: number) => {
        return (
            <Grid item xs={4} sm={3} lg={2} key={index}>
                <div className="image-container">
                    <img src={image.Url} alt={`EGift Option ${index}`} style={style} className={'bLoyal-image-thumbnail'} onClick={() => setSelectedImage(image.Url)}/>
                </div>
            </Grid>
        )
    }

    const mapCategory = (name: string) => {
        const categoryImages = images?.filter(i => i.Category === name) ?? []
        return (
            <div className={'accordion-container'} key={name}>
                <Accordion expanded={expanded === name} onChange={handleAccordionChange(name)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        style={{width: '100%'}}
                    >
                        <Typography style={{marginLeft: '10px', textAlign: 'start'}} sx={{ width: '33%', flexShrink: 0 }}>
                            {name ? name : 'Generic'}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Grid container spacing={2}>
                            {categoryImages.map(mapAccordionImageOption)}
                        </Grid>
                    </AccordionDetails>
                </Accordion>
            </div>
        )
    }
    
    return (
        <>
            <h3>{props.config?.DesignSectionLabel}</h3>
            
                {categories.length < 2 &&
                    <Grid container columnSpacing={2} rowSpacing={1}>
                        {images?.map(mapImageOption)}
                    </Grid>
                }
                {categories.length >=2 && categories.map(mapCategory)}
            
            {showMore && <button className='bl-snippet-button' onClick={() => setShowAll(!showAll)} >{showAll ? 'Show less' : 'Show more'}</button>}
            <br />
            {selected && <div className={'bLoyal-center'}><img src={selected} alt={'Selected gift image'} style={previewStyle}/></div>}
        </>
    )
}