import React, {useEffect, useMemo, useState} from 'react';
import {useSnippetContext} from "./Utils/UseSnippetContext";
import {LoginType} from "./Helpers/Types";
import useServiceUrls from "./Utils/UseServiceUrls";
import useSnippetConfig from "./Utils/UseSnippetConfig";
import LoadingSpinner from "./LoadingSpinner";
import LegacyLogin from "./LegacyLogin";
import Login from "./Login";
import {parse} from "query-string";
import {useAuth} from './Utils/UseAuth';

type LoginHandlerProps = {
    onSuccess: (config: any) => string | null
    onError: (config: any) => string | null
    snippetCode: string
    setSignupSnippet: Function
    loginType: LoginType
    remoteLoginUrl?: string
}

export default function LoginHandler(props: LoginHandlerProps) {
    //@ts-ignore
    const { setSession, session } = useAuth();
    //@ts-ignore
    const { domain, deviceCode, returnUrlParam, loginUrl } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const { SnippetConfig, SnippetConfigStatus } = useSnippetConfig(serviceUrls, domain, deviceCode, props.snippetCode);
    const [loginQueryUrl, setLoginQueryUrl] = useState('')
    const [validLogin, setValidLogin] = useState(false)
    const [loginError, setLoginError] = useState('')
    const remoteLogin = useMemo(() => props.loginType === LoginType.ConfiguredUrl || props.loginType === LoginType.ExternalQueryUrl || props.loginType === LoginType.ExternalElementUrl, [props.loginType])

    useEffect(() => {
        const params = parse(window.location.search)
        if (params.bl_lurl) {
            // @ts-ignore
            setLoginQueryUrl(params.bl_lurl)
        }
    }, [])

    useEffect(() => {
        if (!loginQueryUrl  && !loginUrl && !props.remoteLoginUrl && remoteLogin) {
            setValidLogin(false)
            setLoginError('No Login url provided with login type set to external url. Please provide a login url as a query param, on the snippet element or in the configuration')
        }
        setValidLogin(true)
    }, [loginQueryUrl, loginUrl, props.remoteLoginUrl, remoteLogin])

    useEffect(() => {
        if (props.loginType === LoginType.ConfiguredUrl && !session && validLogin) {
            let url = props.remoteLoginUrl ? props.remoteLoginUrl : loginUrl ? loginUrl : loginQueryUrl
            const isValid = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi.test(url)
            if (!isValid) {
                if (url.includes('localhost'))
                    window.location.assign(`${url}?${returnUrlParam}=${window.location.protocol + '//' + window.location.host + window.location.pathname}`);
                else {
                    console.log("Should not display")
                    setValidLogin(false)
                    setLoginError('The url provided is not a valid url')
                }
            } else {
                url = url.includes('http') ? url : `https://${url}`
                window.location.assign(`${url}?${returnUrlParam}=${window.location.protocol + '//' + window.location.host + window.location.pathname}`);
            }
        }
    }, [validLogin]);

    if (!validLogin || loginError)
        return (
            <p className='bLoyal-error-text'>
                {`Configuration Error: ${loginError}`}
            </p>
        )
    
    return (
        <>
            {SnippetConfigStatus === 'loading' && <LoadingSpinner />}
            {SnippetConfig && SnippetConfig.LegacyLogin !== null && <LegacyLogin setSignupSnippet={props.setSignupSnippet} onSuccess={props.onSuccess}
                                                                                 snippetCode={SnippetConfig.Code} onError={props.onError}
                                                                                 remoteLoginUrl={props.remoteLoginUrl}
                                                                                 loginType={props.loginType}/>}
            {SnippetConfig && SnippetConfig.Login !== null && <Login setSignupSnippet={props.setSignupSnippet} onSuccess={props.onSuccess}
                                                                     snippetCode={SnippetConfig.Code} onError={props.onError}
                                                                     remoteLoginUrl={props.remoteLoginUrl}
                                                                     loginType={props.loginType}/>}
            {SnippetConfig && SnippetConfig.Login === null && SnippetConfig.LegacyLogin === null && <p className={'error-text'}>This is not a login snippet.</p>}
        </>
    )
}