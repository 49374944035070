import React, {useEffect, useState} from 'react';
import TextInput from "../TextInput";
import MultilineTextInput from "../MultilineTextInput";
import {EGiftCard, EGiftCardSnippetProfile} from "../Helpers/Types";
import EmailInput from "../EmailInput";
import {getInputField} from "../Helpers/Functions";

type RecipientDetailsProps = {
    handleChange: (field: string, value: any) => void,
    state: EGiftCard,
    invalidInputs: Array<any>
    config?: EGiftCardSnippetProfile,
}

export default function RecipientDetails(props: RecipientDetailsProps) {
    const [name, setName] = useState(props.state.RecipientName);
    const [email, setEmail] = useState(props.state.RecipientEmail);
    const [message, setMessage] = useState(props.state.Message);

    useEffect(() => {
        setName(props.state.RecipientName)
        setEmail(props.state.RecipientEmail)
        setMessage(props.state.Message)
    }, [props.state.RecipientName, props.state.RecipientEmail, props.state.Message]);

    const handleChange = (field: string, value: string) => {
        switch (field) {
            case 'RecipientName':
                setName(value);
                break;
            case 'RecipientEmail':
                setEmail(value);
                break;
            case 'Message':
                setMessage(value);
                break;
        }
        props.handleChange(field, value)
    }

    return (
        <>
            <h3>{props.config?.RecipientSectionLabel ?? 'Who are you sending to?'}</h3>
            <div>
                <TextInput id={'RecipientName'} value={name} handleChange={handleChange}
                           label={getInputField(props.config?.EGiftFields ?? [], 'RecipientName')?.Label ?? 'Recipient Name'}
                           required={true} invalid={props.invalidInputs.includes('RecipientName')} maxLength={50}/>
            </div>
            <div>
                <EmailInput id={'RecipientEmail'} value={email} handleChange={handleChange}
                            label={getInputField(props.config?.EGiftFields ?? [], 'RecipientEmail')?.Label ?? 'Recipient Email'}
                            required={true} invalid={props.invalidInputs.includes('RecipientEmail')} maxLength={100}/>
            </div>
            <MultilineTextInput id={'Message'} value={message} handleChange={handleChange}
                                label={getInputField(props.config?.EGiftFields ?? [], 'Message')?.Label ?? 'Message'}/>
        </>
    )
}