import React, {createContext} from 'react';

// @ts-ignore
const SnippetContext = createContext();
SnippetContext.displayName = 'SnippetContext';

type SnippetProviderProps = {
	domain: string,
	deviceCode: string,
	clubCode: string,
	useLogout: boolean,
	children: any
	returnUrlParam: string
	loginUrl: string
	cartUid?: string
	cartExternalId?: string
	cartSourceExternalId?: string
	cartAmount?: number
	alertUid?: string
	lineUid?: string
	onCompleteFunction?: string
	redirectToHome?: boolean
	productCode?: string
}

function SnippetProvider(props: SnippetProviderProps) {

	const domain = props.domain;
	const deviceCode = props.deviceCode;
	const clubCode = props.clubCode;
	const useLogout = props.useLogout;
	const returnUrlParam = props.returnUrlParam
	const loginUrl = props.loginUrl
	const cartUid = props.cartUid
	const cartExternalId = props.cartExternalId
	const cartSourceExternalId = props.cartSourceExternalId
	const cartAmount = props.cartAmount
	const alertUid = props.alertUid
	const lineUid = props.lineUid
	const onCompleteFunction = props.onCompleteFunction ?? 'blOnSnippetComplete'
	const redirectToHome = props.redirectToHome ?? false
	const productCode = props.productCode ?? ''

	const value = React.useMemo(() => ({ domain, deviceCode, clubCode, useLogout, returnUrlParam, loginUrl, cartUid, cartExternalId, cartSourceExternalId, cartAmount, alertUid, lineUid, onCompleteFunction, redirectToHome, productCode }), [
		props.domain,
		props.deviceCode,
		props.clubCode,
		props.useLogout,
		props.returnUrlParam,
		props.loginUrl,
		props.cartUid,
		props.cartExternalId,
		props.cartSourceExternalId,
		props.cartAmount,
		props.alertUid,
		props.lineUid,
		props.onCompleteFunction,
		props.redirectToHome,
		props.productCode
	]);

	return <SnippetContext.Provider value={value} {...props} />;
}

function useSnippetContext(): {domain: string, deviceCode: string, clubCode: string, useLogout: boolean, returnUrlParam: string, loginUrl: string, cartUid: string, cartExternalId: string, cartAmount: number, alertUid: string, onCompleteFunction: string, redirectToHome: string, productCode: string} {
	const context = React.useContext(SnippetContext);
	if (context === undefined) {
		throw new Error(`useSnippetContext must be used within a SnippetProvider`);
	}
	// @ts-ignore
	return context;
}

export {SnippetProvider, useSnippetContext};
