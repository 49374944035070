import {queryCache, useMutation} from 'react-query';
import {client} from './ApiClient';
import {mapServiceUrls} from "../Helpers/Functions";

const validate = ({urls, session, domain, deviceCode, data, clubUid}: {urls: any; session: string; domain: string; deviceCode: string; data: any; clubUid: any;}) => {
	const body = {
		GiftCustomer: data === null ? null : {
			...data,
			Address: {
				...data
			}
		},
		ClubUid: clubUid,
	}
	return client(mapServiceUrls(urls).LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/snippets/club/${session}/commands/validateGiftRecipient`, {
		body: body
	});
}

export default function useValidateGiftRecipient() {
	// @ts-ignore
	return useMutation(validate, {
		onSuccess: () => {
			queryCache.invalidateQueries('ClubMembership')
		},
	})
}