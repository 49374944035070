import {ISyntheticEvent, SnippetTheme} from './Helpers/Types';
import {TextField} from '@material-ui/core';
import React, {useEffect, useState} from 'react';
import {useStyleContext} from "./Utils/UseStyleContext";

type EmailInputProps = {
    id: string,
    value: string,
    label: string,
    getValue?: (value: string) => void,
    handleChange?: (fieldName: string, value: string) => void,
    required?: boolean,
    disabled?: boolean,
    invalid?: boolean,
    maxLength?: number
}

export default function EmailInput(props: EmailInputProps) {
    const [value, setValue] = useState(props.value);
    // @ts-ignore
    const {theme} = useStyleContext();

    useEffect(() => {
        setValue(props.value);
    }, [props.value])

    const handleChange = (event: ISyntheticEvent) => {
        event.preventDefault();
        let value = event.target.value;
        setValue(value);
        props.getValue?.(value);
        props.handleChange?.(props.id, value);
    };

    switch (theme) {
        case SnippetTheme.MaterialUI:
            return (
                <TextField
                    type='email'
                    id={props.id}
                    label={props.label}
                    required={props.required ? props.required : false}
                    value={value}
                    onChange={handleChange}
                    disabled={props.disabled === true}
                    className={props.invalid === true ? 'bLoyal-invalid-input' : ''}
                    inputProps={{maxLength: props.maxLength}}
                />
            );
        default:
            return (
                <label>{props.label + (props.required ? "* " : " ")}
                    <input style={{width: '100%'}} type="email" id={props.id} onChange={handleChange}
                           required={props.required} disabled={props.disabled === true} value={value}
                           className={props.invalid === true ? 'bLoyal-invalid-input' : ''} maxLength={props.maxLength}/>
                </label>
            );
    }

}