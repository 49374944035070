import React, {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import {
    ChoiceResponse,
    ClubSignupSnippetProfile,
    ClubsScope,
    DeliveryType,
    IAddressState,
    ISignupState,
    PaymentSource,
    ProductPreference,
    ProductPreferenceType,
    WebSnippetProfile
} from "../Helpers/Types";
import {useAuth} from "../Utils/UseAuth";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import useServiceUrls from "../Utils/UseServiceUrls";
import useGetClubs from "../Utils/UseGetClubs";
import useDeviceSessionProfile from "../Utils/UseDeviceSessionProfile";
import useSnippetConfig from "../Utils/UseSnippetConfig";
import useClubMembership from "../Utils/UseClubMembership";
import LoadingSpinner from "../LoadingSpinner";
import {DeviceSessionProvider} from "../Utils/UseDeviceSessionProfileContext";
import SnippetHeader from "../Helpers/SnippetHeader";
import {Box, Step, StepLabel, Stepper} from "@material-ui/core";
import ShippingAddressSnippet from "../ShippingAddress/ShippingAddressSnippet";
import PaymentMethodSnippet from "../PaymentMethod/PaymentMethodSnippet";
import SnippetFooter from "../Helpers/SnippetFooter";
import SignupSnippet from "../SignupSnippet";
import {
    getCustomCodesArray,
    isValidPaymentMethod,
    mapCustomCodes,
    mapServiceUrls,
    onSuccessRedirect,
    validateGiftAddress
} from "../Helpers/Functions";
import CustomerDetailsSnippet from "../CustomerDetails/CustomerDetailsSnippet";
import CustomFieldsPage from "./CustomFieldsPage";
import ClubSignupProductsPage from "./ClubSignupProductsPage";
import useJoinClub from "../Utils/UseJoinClub";
import ClubMembershipGiftPage from "./ClubMembershipGiftPage";
import useWindowDimensions from "../Hooks/useWindowDimensions";
import usePaymentMethods from "../Utils/UsePaymentMethods";
import dayjs from "dayjs";
import ClubMembershipTerm from "./ClubMembershipTerm";
import useValidateGiftRecipient from "../Utils/UseValidateGiftRecipient";

type ClubSignupSnippetProps = {
    snippetProfile: WebSnippetProfile | null
    clubCode?: string
    back?: Function
}

export default function ClubSignupSnippet(props: ClubSignupSnippetProps) {
    const {width} = useWindowDimensions()
    let snippetProfile = props.snippetProfile?.ClubSignup
    // @ts-ignore
    const { session, customer } = useAuth();
    const [step, setStep] = useState(0)
    const [customFields, setCustomFields] = useState<Array<{code: string, value: string}>>([])
    const [isGift, setIsGift] = useState(ChoiceResponse.No)
    const [paymentUid, setPaymentUid] = useState('')
    const [addressUid, setAddressUid] = useState('')
    const [pickupUid, setPickupUid] = useState('')
    const [deliveryType, setDeliveryType] = useState(DeliveryType.Ship)
    const [products, setProducts] = useState<Array<ProductPreference>>([])
    const [noClub, setNoClub] = useState(false)
    const [defaultProductsSet, setDefaultProductsSet] = useState(false)
    const [membershipSet, setMembershipSet] = useState(false)
    const [joinError, setJoinError] = useState('')
    const [,refresh] = useState({})
    const [giftRecipient, setGiftRecipient] = useState<ISignupState>()
    const [giftAddress, setGiftAddress] = useState<IAddressState>()
    const [termState, setTermState] = useState({StartDate: dayjs(), ExpirationDate: undefined, UseDefaultEndDate: true})
    const [giftError, setGiftError] = useState<boolean>(false)

    // @ts-ignore
    const { domain, deviceCode, clubCode } = useSnippetContext();
    const membershipClubCode = props.clubCode ?? clubCode
    const { serviceUrls } = useServiceUrls(domain);
    const { clubsStatus, clubs, clubsError } = useGetClubs(serviceUrls, domain, deviceCode)
    const { paymentMethodsStatus, paymentMethods, paymentMethodsError, paymentMethodsFetching } = usePaymentMethods(serviceUrls, session, domain, deviceCode);
    const { deviceSessionProfileStatus, deviceSessionProfile, deviceSessionProfileError} = useDeviceSessionProfile(serviceUrls, domain, deviceCode)
    const { SnippetConfigStatus: shippingStatus, SnippetConfig: shippingConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, snippetProfile?.ShippingAddressesSnippetCode ?? '');
    const { SnippetConfigStatus: paymentStatus, SnippetConfig: paymentConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, snippetProfile?.PaymentMethodSnippetCode ?? '');
    const { SnippetConfigStatus: memberDetailsStatus, SnippetConfig: memberDetailsConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, snippetProfile?.MemberDetailsSnippetCode ?? '');

    const validGiftAddress = useMemo(() => validateGiftAddress(shippingConfig, giftAddress), [shippingConfig, giftAddress]);
    
    const steps = useMemo(() => {
        let workingSteps: Array<String> = []
        if (!snippetProfile)
            return workingSteps
        if (snippetProfile.AllowGift)
            workingSteps.push(snippetProfile.MembershipSelectionPageName ? snippetProfile.MembershipSelectionPageName : 'Gift?')
        if (snippetProfile.IncludeMemberDetails && isGift === ChoiceResponse.No)
            workingSteps.push(snippetProfile.MemberDetailsPageName ? snippetProfile.MemberDetailsPageName : 'Member Details')
        if (isGift === ChoiceResponse.Yes)
            workingSteps.push(snippetProfile.GiftRecipientPageName ? snippetProfile.GiftRecipientPageName : 'Gift Recipient')
        if (snippetProfile.MembershipCustomFields.length !== 0)
            workingSteps.push(snippetProfile.CustomFieldsPageName ? snippetProfile.CustomFieldsPageName : 'Customize Your Membership')
        if (snippetProfile.CaptureDeliveryDetails)
            workingSteps.push(snippetProfile.ShippingAddressPageName ? snippetProfile.ShippingAddressPageName : 'Delivery Details')
        if (snippetProfile.IncludeProductPreferences)
            workingSteps.push(snippetProfile.ProductPreferencesPageName ? snippetProfile.ProductPreferencesPageName : 'Product Preferences')
        if (snippetProfile.CapturePaymentMethod)
            workingSteps.push(snippetProfile.PaymentMethodPageName ? snippetProfile.PaymentMethodPageName : 'Payment')

        return workingSteps
    }, [isGift, snippetProfile])
    
    const getClub = (clubsList?: Array<any>, profile?: ClubSignupSnippetProfile) => {
        if (!profile)
            return undefined
        
        let tempClub: any
        if (profile.ClubsScope === ClubsScope.All)
            tempClub = clubsList?.find((c: any) => c.Code.toLowerCase() === membershipClubCode.toLowerCase())
        else if (profile.Clubs.length === 1)
            tempClub = profile.Clubs[0]
        else
            tempClub = profile.Clubs.find((c) => c.Code.toLowerCase() === membershipClubCode.toLowerCase())
        
        if (tempClub === undefined) {
            setNoClub(true)
            return undefined
        }
        
        if (noClub)
            setNoClub(false)
        
        return clubsList?.find((c: any) => c.Code === tempClub.Code)
    }
    
    const club = useMemo(() => getClub(clubs, snippetProfile), [props.snippetProfile, membershipClubCode, clubs])
    const [currentMembership, setCurrentMembership] = useState(club && customer.ClubMemberships.length > 0 ? customer.ClubMemberships.find((membership: any) => membership.Club.Uid === club.Uid) : null)

    const { membership, membershipStatus, membershipError, removeMembership } = useClubMembership(serviceUrls, domain, deviceCode, session, currentMembership?.Uid);
    const [joinClub, { status, error, reset }] = useJoinClub();
    const [validateGiftRecipient, {status: validationStatus, error: validationError, reset: validationReset, data}] = useValidateGiftRecipient()
    
    const updateMembershipState = (membership: any) => {
        setPaymentUid(membership.Payment?.PaymentMethodUid ?? '')
        setDeliveryType(membership.Fulfillment?.DeliveryType ?? DeliveryType.Ship)
        setAddressUid(membership.Fulfillment?.ShippingAddressUid ?? '')
        setPickupUid(membership.Fulfillment?.PickupLocationUid)
        setProducts(membership.ProductPreferences)
        if (membership.ProductPreferences.length > 0)
            setDefaultProductsSet(true)
        setIsGift(membership.Payment?.Source === PaymentSource.Sponsor ? ChoiceResponse.Yes : ChoiceResponse.No)
        // @ts-ignore
        setCustomFields(getCustomCodesArray(membership.CustomFields))
    }

    useEffect(() => {
        setCurrentMembership(club && customer.ClubMemberships.length > 0 ? customer.ClubMemberships.find((membership: any) => membership.Club.Uid === club.Uid) : null)
    }, [club, customer]);

    useEffect(() => {
        if (membership) {
            updateMembershipState(membership)
        }
    }, [membership])

    useEffect(() => {
        if (isGift) {
            setPaymentUid('')
            setDeliveryType(DeliveryType.Ship)
            setAddressUid('')
            setPickupUid('')
            setProducts([])
            setDefaultProductsSet(false)
            // @ts-ignore
            setCustomFields([])
        }
    }, [isGift])

    useEffect(() => {
        if (status === 'error') {
            // @ts-ignore
            if (error.includes('Customer cannot modify this membership'))
                setJoinError('This customer is already a member of this club, you cannot gift them a membership.')
            else {
                // @ts-ignore
                setJoinError(error)
            }
        }
    }, [status])

    useEffect(() => {
        if (status === 'success' && props.back) {
            reset()
            removeMembership()
            props.back()
        }
        else if (status === 'success' && props.snippetProfile?.OnSuccessRedirectUrl)
            onSuccessRedirect(props.snippetProfile.OnSuccessRedirectUrl, props.snippetProfile.OnSuccessRedirectDelay)
        
    }, [status, props.snippetProfile])

    useEffect(() => {
        if (validationStatus === 'success' && data.data === true)
            next()
    }, [validationStatus]);


    const next = (e?: SyntheticEvent) => {
        setJoinError('')
        e?.preventDefault()

        if (step === steps.length - 1) {
            let customerUid;
            let sponsorUid;
            let paymentSource;
            if (isGift === ChoiceResponse.Yes) {
                sponsorUid = customer.Uid;
                paymentSource = PaymentSource.Sponsor;
            } else {
                customerUid = customer.Uid;
                sponsorUid = null;
                paymentSource = PaymentSource.Default;
            }
            const fulfillment = snippetProfile?.CaptureDeliveryDetails ? {
                DeliveryType: deliveryType,
                ShippingAddressUid: deliveryType === DeliveryType.Ship ? addressUid : null,
                PickupLocationUid: deliveryType === DeliveryType.Pickup ? pickupUid : null,
            } : null
            
            if (!isValidPaymentMethod(paymentUid, snippetProfile, paymentMethods)) {
                setJoinError('Please select or create a valid payment method')
                console.log('payment error')
            } else
                joinClub({
                    data: {
                        ProductPreferences: products,
                        ProductPreferenceType: snippetProfile?.IncludeProductPreferences ? snippetProfile?.ProductPreferenceType ?? ProductPreferenceType.Append : ProductPreferenceType.Default,
                        CustomFields: mapCustomCodes(customFields),
                        Payment: {
                            PaymentMethodUid: paymentUid,
                            Source: paymentSource
                        },
                        Fulfillment: fulfillment,
                        CustomerUid: customerUid,
                        SponsorUid: sponsorUid,
                        GiftMemberShippingAddress: isGift === ChoiceResponse.Yes && deliveryType === DeliveryType.Ship ? giftAddress?.ShippingAddress ?? null : null,
                        GiftCustomer: isGift === ChoiceResponse.Yes ? giftRecipient : null,
                        ...termState
                    },
                    deviceCode: deviceCode,
                    domain: domain,
                    session: session,
                    urls: mapServiceUrls(serviceUrls),
                    clubUid: club.Uid
                });
        }
        else
            setStep(step + 1)
    }

    const back = (e?: SyntheticEvent) => {
        setJoinError('')
        e?.preventDefault()
        if (step === 0)
            props.back?.()
        else
            setStep(step - 1)
    }

    const handleChange = (field: string, value: any) => {
        setJoinError('')
        switch (field) {
            case 'giftChoice':
                setIsGift(value)
                break;
            case 'giftRecipient':
                setGiftRecipient(value);
                break;
            case 'shippingAddress':
                if (value) {
                    setAddressUid(value.Uid ?? '')
                    setDeliveryType(DeliveryType.Ship)
                }
                break;
            case 'PickupLocation':
                if (value) {
                    setPickupUid(value.Uid)
                    setDeliveryType(DeliveryType.Pickup)
                }
                break;
            case 'paymentMethod':
                if (value)
                    setPaymentUid(value.Uid)
                break;
            case 'CustomFields':
                setCustomFields(value)
                break;
            case 'DefaultProducts':
                setDefaultProductsSet(true)
                break;
            case 'Products':
                setProducts(value)
                break;
        }
    };
    
    const updateGiftAddress = (address: IAddressState) => {
        setGiftAddress(address)
    }
    
    const updateGiftRecipient = (recipient: ISignupState) => {
        setGiftRecipient(recipient)
        if (validationError)
            validationReset()
    }
    
    const onGiftSignupComplete = (sessionKey: any) => {
        validateGiftRecipient({urls: serviceUrls, session, domain, deviceCode, data: giftRecipient, clubUid: club.Uid})
    }

    const acknowledgeError = (e: SyntheticEvent) => {
        setJoinError('')
        reset()
        back(e)
    }
    
    return (
        <div className={'bl-clubsignup-snippet'}>
            {(clubsStatus === 'loading' || deviceSessionProfileStatus === 'loading' || membershipStatus === 'loading') && <LoadingSpinner />}
            {deviceSessionProfile && snippetProfile && club &&
                <DeviceSessionProvider profile={deviceSessionProfile}>
                    <SnippetHeader logoUrl={props.snippetProfile?.LogoUrl ?? ''} title={props.snippetProfile?.Title ?? ''} message={props.snippetProfile?.Message ?? ''} snippetCode={props.snippetProfile?.Code ?? ''}/>
                    {/*@ts-ignore*/}
                    <Box sx={{width: '100%'}}>
                        {width > 550 ? 
                        <Stepper activeStep={step} alternativeLabel>
                            {steps.map((label) => (
                                // @ts-ignore
                                <Step key={label}>
                                    <StepLabel>{label}</StepLabel>
                                </Step>
                            ))}
                        </Stepper> : null}
                        {width <=  550 ?
                        <Stepper activeStep={step} orientation="vertical">
                            {steps.map((step, index) => (
                                <Step key={index}>
                                    <StepLabel>
                                        {step}
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper> : null}
                    </Box>
                    { steps[step] === (snippetProfile.MembershipSelectionPageName ? snippetProfile.MembershipSelectionPageName : 'Gift?') &&
                        <>
                            <ClubMembershipGiftPage snippetProfile={snippetProfile} updateField={handleChange} isGift={isGift}/>
                            <hr className='button-break' />
                            {(step !== 0 || props.clubCode) && <button className='bl-snippet-button' onClick={back}>{snippetProfile.MembershipSelectionBackButton ? snippetProfile.MembershipSelectionBackButton : snippetProfile.BackLabel}</button>}
                            <button className='bl-snippet-button' onClick={next}>{snippetProfile.MembershipSelectionNextButton ? snippetProfile.MembershipSelectionNextButton : step === steps.length - 1 ? snippetProfile.SaveLabel : snippetProfile.NextLabel}</button>
                        </>
                    }
                    {steps[step] === (snippetProfile?.MemberDetailsPageName ? snippetProfile?.MemberDetailsPageName : 'Member Details') &&
                        <>
                        { memberDetailsStatus === 'success' && 
                            <CustomerDetailsSnippet snippetProfile={memberDetailsConfig} saveLabel={snippetProfile.MemberDetailsNextButton ? snippetProfile.MemberDetailsNextButton : step === steps.length - 1 ? snippetProfile.SaveLabel : snippetProfile.NextLabel} backLabel={snippetProfile.MemberDetailsBackButton ? snippetProfile.MemberDetailsBackButton : step === 0 ? undefined : snippetProfile.BackLabel ? snippetProfile.BackLabel : 'Back'} onBack={back} onComplete={next}/>
                        }
                        { memberDetailsStatus === 'loading' && <LoadingSpinner />}
                        {memberDetailsStatus === 'error' && <p className={'error-text'}>{`Unable to get '${snippetProfile.MemberDetailsSnippetCode}' snippet.`}</p>}
                        </>
                    }
                    { steps[step] === (snippetProfile.CustomFieldsPageName ? snippetProfile.CustomFieldsPageName : 'Customize Your Membership') &&
                        <>
                            <form onSubmit={next}>
                                <CustomFieldsPage pageText={snippetProfile.CustomFieldsPageText} deviceProfile={deviceSessionProfile} handleChange={handleChange} customFields={customFields} customFieldConfigs={snippetProfile.MembershipCustomFields} displayType={snippetProfile.CustomFieldsDisplayType} termState={termState} updateTermState={setTermState} config={snippetProfile}/>
                                <hr className='button-break' />
                                {(step !== 0 || props.clubCode) && <button className='bl-snippet-button' onClick={back}>{snippetProfile.CustomFieldsBackButton ? snippetProfile.CustomFieldsBackButton : snippetProfile.BackLabel}</button>}
                                <button className='bl-snippet-button' type={"submit"}>{snippetProfile.CustomFieldsNextButton ? snippetProfile.CustomFieldsNextButton : step === steps.length - 1 ? snippetProfile.SaveLabel : snippetProfile.NextLabel}</button>
                            </form>
                        </>
                    }
                    {steps[step] === (snippetProfile?.GiftRecipientPageName ? snippetProfile?.GiftRecipientPageName : 'Gift Recipient') &&
                        <>
                            <ClubMembershipTerm handleChange={setTermState} config={snippetProfile} state={termState} />
                            <SignupSnippet snippetCode={snippetProfile.GiftSignupSnippetCode} isStandalone={false} saveLabel={snippetProfile.GiftRecipientNextButton ? snippetProfile.GiftRecipientNextButton : step === steps.length - 1 ? snippetProfile.SaveLabel : snippetProfile.NextLabel} backLabel={step === 0 ? undefined : snippetProfile.GiftRecipientBackButton ? snippetProfile.GiftRecipientBackButton :  snippetProfile.BackLabel ? snippetProfile.BackLabel : 'Back'} onBack={back} onComplete={onGiftSignupComplete} isGift={isGift === ChoiceResponse.Yes} setGiftRecipient={updateGiftRecipient} giftCustomer={giftRecipient} error={validationError}/>
                        </>
                    }

                    { steps[step] === (snippetProfile?.ShippingAddressPageName ? snippetProfile?.ShippingAddressPageName : 'Delivery Details') && shippingConfig &&
                        <>
                            <ShippingAddressSnippet onSelect={handleChange} giftCustomer={giftRecipient} snippetProfile={shippingConfig} allowPickup={snippetProfile.AllowStorePickup} allowShipping={snippetProfile.AllowShipping} initialType={deliveryType} initialValue={deliveryType === DeliveryType.Pickup ? pickupUid : addressUid} inventoryLocations={snippetProfile.InventoryLocationUids} setGiftAddress={updateGiftAddress} giftAddress={giftAddress}/>
                            <hr className='button-break' />
                            {(step !== 0 || props.clubCode) && <button className='bl-snippet-button' onClick={back}>{snippetProfile.ShippingAddressBackButton ? snippetProfile.ShippingAddressBackButton : snippetProfile.BackLabel}</button>}
                            <button className='bl-snippet-button' type={'submit'} disabled={(((deliveryType === DeliveryType.Ship && addressUid === '') || (deliveryType === DeliveryType.Pickup && pickupUid === '')) && isGift === ChoiceResponse.No) || (isGift === ChoiceResponse.Yes && !validGiftAddress && deliveryType === DeliveryType.Ship)} onClick={next}>{snippetProfile.ShippingAddressNextButton ? snippetProfile.ShippingAddressNextButton : step === steps.length - 1 ? snippetProfile.SaveLabel : snippetProfile.NextLabel}</button>
                        </>
                    }
                    {steps[step] === (snippetProfile?.ProductPreferencesPageName ? snippetProfile?.ProductPreferencesPageName : 'Product Preferences') &&
                        <>
                            <ClubSignupProductsPage snippetConfig={snippetProfile} next={next} nextLabel={snippetProfile.ProductPreferencesNextButton ? snippetProfile.ProductPreferencesNextButton : step === steps.length - 1 ? snippetProfile.SaveLabel : snippetProfile.NextLabel} back={back} backLabel={snippetProfile.ProductPreferencesBackButton ? snippetProfile.ProductPreferencesBackButton : snippetProfile.BackLabel} updateField={handleChange} productPreferences={products} defaultsSet={defaultProductsSet}/>
                        </>
                    }
                    { steps[step] === (snippetProfile?.PaymentMethodPageName ? snippetProfile?.PaymentMethodPageName : 'Payment') && paymentConfig &&
                        <>
                            <PaymentMethodSnippet snippetProfile={paymentConfig} onSelect={handleChange} initialValue={paymentUid}/>
                            <hr className='button-break' />
                            {(step !== 0 || props.clubCode) && <button className='bl-snippet-button' onClick={back}>{snippetProfile.PaymentMethodBackButton ? snippetProfile.PaymentMethodBackButton : snippetProfile.BackLabel}</button>}
                            <button className='bl-snippet-button' onClick={next}>{snippetProfile.PaymentMethodNextButton ? snippetProfile.PaymentMethodNextButton : step === steps.length - 1 ? snippetProfile.SaveLabel : snippetProfile.NextLabel}</button>
                        </>
                    }
                    {status === 'loading' && <LoadingSpinner />}
                    {joinError !== '' &&
                        <>
                            <p className='error-text'>{joinError}</p>
                            {/*<button className='bl-snippet-button' onClick={acknowledgeError} >Back</button>*/}
                        </>
                    }
                    {status === 'success' && <p>{props.snippetProfile?.OnSuccessMessage ?? "You're membership has been saved"}</p>}
                    <SnippetFooter footer={props.snippetProfile?.Footer ?? ''} snippetCode={props.snippetProfile?.Code ?? ''}/>
                </DeviceSessionProvider>
            }
            {noClub && <p className='error-text'>Club not found.</p>}
        </div>
    )
}