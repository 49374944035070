import React, {SyntheticEvent, useState} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextInput from "../TextInput";
import {validateRegisteredCardNumber} from "../Helpers/Functions";
import {CharacterTypeOptions, RegisterCardSnippetProfile} from "../Helpers/Types";

type AddCardDialogProps = {
    cancel: () => void;
    add: (cardNumber: string) => void;
    title: string;
    save: string;
    input: string;
    snippetProfile: RegisterCardSnippetProfile;
    error?: any
}

export default function AddCardDialog(props: AddCardDialogProps) {
    const [cardNumber, setCardNumber] = useState('')
    const [validRegisteredCard, setValidRegisteredCard] = useState(false)
    const [submitted, setSubmitted] = useState<boolean>(false);
    
    const close = (e: SyntheticEvent) => {
        e.preventDefault();
        props.cancel();
    }

    const confirm = (e: SyntheticEvent) => {
        e.preventDefault()
        const valid = validateRegisteredCardNumber(cardNumber, props.snippetProfile.RegisteredCardMinLength ?? 0, props.snippetProfile.RegisteredCardCharacterType ?? CharacterTypeOptions.AlphaNumeric, props.snippetProfile.RegisteredCardMaxLength ?? undefined);
        if (!valid) {
            setValidRegisteredCard(valid)
            return
        }
        setSubmitted(true)
        props.add(cardNumber);
    }

    const handleChange = (field: string, value: string) => {
        const valid = validateRegisteredCardNumber(value, props.snippetProfile.RegisteredCardMinLength ?? 0, props.snippetProfile.RegisteredCardCharacterType ?? CharacterTypeOptions.AlphaNumeric, props.snippetProfile.RegisteredCardMaxLength ?? undefined)
        if (submitted)
            setSubmitted(false)
        setValidRegisteredCard(valid);
        setCardNumber(value)
    }

    return (
        <Dialog className={'bLoyal-dialog'} maxWidth='sm' fullWidth={true} open={true}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent className='dialog-body'>
                <div className='bLoyal-center bLoyal-dialog-body'>
                    <div className="bLoyal-gift-card bLoyal-center">
                        <div className="bLoyal-stripe"></div>
                        <div className="bLoyal-gift-card-body">
                        </div>
                    </div>
                    <TextInput id={'RegisteredCardNumber'} value={cardNumber} label={props.input} handleChange={handleChange} />
                    {!submitted && <p>{`Registered Card Number must have ${props.snippetProfile.RegisteredCardMaxLength === null ? `at least ${props.snippetProfile.RegisteredCardMinLength} characters` : `between ${props.snippetProfile.RegisteredCardMinLength} and ${props.snippetProfile.RegisteredCardMaxLength} characters`} and include only ${props.snippetProfile.RegisteredCardCharacterType === CharacterTypeOptions.Numeric ? 'numbers' : props.snippetProfile.RegisteredCardCharacterType === CharacterTypeOptions.Alpha ? 'letters' : 'letters and numbers'} `}</p>}
                    {props.error && submitted && <p className={'bLoyal-error-text'}>{props.error}</p>}
                </div>
            </DialogContent>
            <DialogActions>
                <button style={{float: 'left'}} onClick={close}>
                    Cancel
                </button>
                <button onClick={confirm} disabled={!validRegisteredCard || !cardNumber}>{props.save}</button>
            </DialogActions>
        </Dialog>
    )
}