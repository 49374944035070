import {queryCache, useMutation} from 'react-query';
import {client} from './ApiClient';
import {ICustomer} from '../Helpers/Types';

const applyCoupon = ({urls, session, domain, deviceCode, couponUid, couponCode, quantity}: {urls: any; session: string; domain: string; deviceCode: string; couponCode: string; couponUid?: string; quantity?: number}) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/snippets/customer/${session}/coupons/commands/redeem`, {
		body: {
			CouponUid: couponUid,
			CouponCode: couponCode,
			Quantity: quantity ? null : quantity
		}
	});
}

export default function useApplyCoupon() {
	// @ts-ignore
	return useMutation(applyCoupon, {
		onSuccess: () => {
			queryCache.invalidateQueries('CustomerSession')
		},
	})
}