import React, {useState} from 'react';
import {ISyntheticEvent, ProductPreference, ProductPreferenceType, QuantityType} from './Helpers/Types';
import ProductSelection from './ProductSelection';
import useServiceUrls from './Utils/UseServiceUrls';
import useProducts from './Utils/UseProducts';
import {useAuth} from './Utils/UseAuth';
import LoadingSpinner from './LoadingSpinner';
import {useSnippetContext} from './Utils/UseSnippetContext';

type ProductsPageProps = {
    snippetConfig: any
    nextStep: (e: ISyntheticEvent) => void
    prevStep: (e: ISyntheticEvent) => void
    updateField: (field: string, value: any) => void
    productPreferenceType: ProductPreferenceType,
    productPreferences: Array<ProductPreference>,
    memberPreferenceType: ProductPreferenceType,
    defaultsSet?: boolean
}

export default function ProductsPage(props: ProductsPageProps) {
    const minimumPrice = props.snippetConfig.MinPriceForProductSelection ?? 0;
    const minimumQuantity = props.snippetConfig.MinQuantityForProductSelection ?? 0;
    // @ts-ignore
    const {session} = useAuth();
    // @ts-ignore
    const {domain, deviceCode} = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const configProducts: Array<string> = props.snippetConfig.ClubProductOptions;
    const {productsStatus, products, productsError} = useProducts(serviceUrls, session, domain, deviceCode, configProducts.map((product: any) => product.LookupCode));
    const [quantity, setQuantity] = useState(0);
    const [price, setPrice] = useState(0.00);
    const [selectedProducts, setSelectedProducts] = useState<Array<ProductPreference>>(props.productPreferences);
    const [requirementsError, setRequirementsError] = useState(false);
    const [defaultsSet, setDefaultsSet] = useState(props.defaultsSet == null ? false : props.defaultsSet);

    const getQuantityType = (quantity: string): QuantityType => {
        const hasCommas = quantity.includes(',');
        if (!hasCommas) {
            if (quantity.includes('#'))
                return QuantityType.Unlimited;
            return QuantityType.Max;
        }
        return QuantityType.Options;
    };

    const getQuantityOptions = (quantity: string) => {
        let options: any = quantity.split(',');
        options = options
            .filter((option: string) => option.trim() !== '')
            .map((option: string) => {
            let trimmed = option.trim();
            return {
                text: trimmed,
                value: parseInt(trimmed)
            };
        });
        return options;
    };
    
    const mapProductQuantities = (product: any, useDefaultValues: boolean, ) => {
        let configProduct: any = configProducts.find((prod: any) => prod.LookupCode === product.Code);
        if (!configProduct)
            return product;

        const quantityType = getQuantityType(configProduct.Quantity);
        const selectedQuantity = selectedProducts.find((prod: any) => prod.ProductUid === product.Uid);
        const quantityValue: any = quantityType === QuantityType.Options ? getQuantityOptions(configProduct.Quantity) : configProduct.Quantity;

        const quantity = selectedQuantity ? selectedQuantity.Quantity : defaultsSet ? 0 : parseDefaultQuantity(configProduct.DefaultQuantity, quantityType, quantityValue, useDefaultValues)

        return {
            ...product,
            QuantityValue: quantityValue,
            QuantityType: quantityType,
            SelectedQuantity: quantity
        };
    } 

    const addQuantityToProductsList = (): Array<any> => {
        let useDefaultValues = false;
        if (props.memberPreferenceType === ProductPreferenceType.Default)
            useDefaultValues = true;
        
        let productsWithQuantities: any;
        productsWithQuantities = products.map((product: any) => mapProductQuantities(product, useDefaultValues));

        const totalQuantity = productsWithQuantities.reduce((qty: number, p: any) => qty + p.SelectedQuantity, 0); 
        const totalPrice = productsWithQuantities.reduce((price: number, p: any) => price + p.SelectedQuantity * p.BasePrice, 0); 
        
        if (price !== totalPrice) {
            setPrice(totalPrice);
        }
        if (quantity !== totalQuantity) {
            setQuantity(totalQuantity);
        }

        return productsWithQuantities;
    };
    
    const parseDefaultQuantity = (defaultQuantity: any, quantityType: QuantityType, quantityValue: Array<any>, useDefaultValue: boolean): number => {
        const value = parseInt(defaultQuantity);
        if (!useDefaultValue || isNaN(value)) {
            switch (quantityType) {
                case QuantityType.Max:
                    return 0;
                case QuantityType.Options:
                    return quantityValue[0].value;
                default:
                    return 0;
            }
        }
        return value;
    }

    const getSelectedProducts = (products: Array<ProductPreference>) => {
        setSelectedProducts(products);
    };
    
    const valuesUpdated = () => {
        setDefaultsSet(true)
        setRequirementsError(false);
    }

    const saveAndContinue = (e: ISyntheticEvent) => {
        e.preventDefault()
        if (price < minimumPrice || quantity < minimumQuantity) {
            setRequirementsError(true);
            return;
        }
        props.updateField('ProductPreferenceType', props.productPreferenceType);
        props.updateField('ProductPreferences', selectedProducts);
        props.nextStep(e);
    };

    return (
        <>
            <form onSubmit={saveAndContinue}>
                <div className='bLoyal-center form'>
                    <h2>{props.snippetConfig.ProductPreferenceCaption ?? "Select the products for your club membership"}</h2>
                    {products &&
                    <>
                        {minimumPrice > 0.0 &&
                        <p>Minimum Price Requirement: {minimumPrice}</p>
                        }
                        {minimumQuantity > 0 &&
                        <p>Minimum Quantity Requirement: {minimumQuantity}</p>
                        }
                        <ProductSelection getValue={getSelectedProducts} products={addQuantityToProductsList()}
                                          setQuantity={setQuantity}
                                          setPrice={setPrice}
                                          valuesUpdated={valuesUpdated}/>
                    </>
                    }
                    {productsStatus === 'loading' &&
                    <LoadingSpinner/>
                    }
                    <button className='bl-snippet-button' id='back' onClick={props.prevStep}>Back</button>
                    <button className='bl-snippet-button' id='continue' type='submit' >Continue
                    </button>
                    {productsError &&
                    <p className='bLoyal-error-text'>{productsError}</p>
                    }
                    {requirementsError &&
                    <p className='bLoyal-error-text'>You must meet the minimum price and quantity requirements</p>
                    }
                </div>
            </form>

        </>
    );
}