import React, {Fragment, SyntheticEvent, useEffect, useMemo, useReducer, useState} from 'react';
import {
    ICountry,
    ICustomField,
    ICustomFieldType, IInputConfig, IQuickSignupField,
    ISelectOption,
    ISignupState,
    IState,
    ISyntheticEvent
} from "../../Helpers/Types";
import {
    SignupAction,
    SignupActionTypes,
    updateCustomSignupFieldAction,
    updateSignupFieldAction
} from "../../SignupActions";
import {useDeviceSessionContext} from "../../Utils/UseDeviceSessionProfileContext";
import {useSnippetContext} from "../../Utils/UseSnippetContext";
import useServiceUrls from "../../Utils/UseServiceUrls";
import useSnippetConfig from "../../Utils/UseSnippetConfig";
import UseSignupCustomer from "../../Utils/UseSignupCustomer";
import useCountries from "../../Utils/UseCountries";
import useStates from "../../Utils/UseStates";
import {doesCountryHaveStates, getDisplayValue, mapServiceUrls} from "../../Helpers/Functions";
import NumberInput from "../../NumberInput";
import SelectInput from "../../SelectInput";
import TextInput from "../../TextInput";
import EmailInput from "../../EmailInput";

export const giftRecipientState: ISignupState = {
    SubscriberGroups: [],
    BirthdayMonth1: '',
    BirthdayMonth2: '',
    FirstName2: '',
    LastName2: '',
    LoyaltyCardNumber: '',
    Password: '',
    FirstName: '',
    LastName: '',
    CompanyName: '',
    Address1: '',
    Address2: '',
    City: '',
    State: '',
    PostalCode: '',
    Country: '',
    EmailAddress: '',
    Phone1: '',
    MobilePhone: '',
    BirthDate: '',
    CustomFields: [],
    RegisterCard: '',
};

export const giftRecipientReducer = (state = giftRecipientState, action: SignupAction): ISignupState => {
    switch (action.type) {
        case SignupActionTypes.UpdateCustomField:
            if (state.CustomFields.filter(field => field.Key === action.fieldName).length > 0){
                let newArray = state.CustomFields.map(field => {
                    if (field.Key === action.fieldName)
                        return { Key: action.fieldName, Value: action.value }
                    return field;
                })
                return {...state, CustomFields: newArray}
            }
            let fields = state.CustomFields
            fields.push({Key: action.fieldName, Value: action.value})
            return {...state, CustomFields: fields}
        case SignupActionTypes.UpdateField:
            if (action.fieldName === 'Zip')
                return {
                    ...state,
                    PostalCode: action.value
                }

            return {
                ...state,
                [action.fieldName]: action.value
            };
        default:
            return state;
    }
};

type GiftRecipientProps = {
    signupSnippetCode: string
    giftCustomer?: any,
    back: Function,
    next: Function
    handleChange: (field: string, value: string) => void
    selectWidth: number
    nextLabel: string,
    backLabel: string
}

export default function GiftRecipient(props: GiftRecipientProps) {
    // @ts-ignore
    const {profile} = useDeviceSessionContext();
    let initialState: ISignupState = {...giftRecipientState, Country: profile.DefaultCountry, ...props.giftCustomer}
    const [state, dispatch] = useReducer(giftRecipientReducer, initialState);
    // @ts-ignore
    const { domain, deviceCode } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const { SnippetConfig: config } = useSnippetConfig(serviceUrls, domain, deviceCode, props.signupSnippetCode);
    const [signupCustomer, { data, isSuccess, isError, error, reset }] = UseSignupCustomer();

    const { countries, countriesStatus } = useCountries(serviceUrls, domain, deviceCode);
    const { states } = useStates(serviceUrls, domain, deviceCode, state.Country, countries && doesCountryHaveStates(countries, state.Country));

    const [countryOptions, setCountryOptions] = useState<Array<ISelectOption>>([]);
    const [stateOptions, setStateOptions] = useState<Array<ISelectOption>>([]);

    const snippetProfile = useMemo(() => config?.CustomerSignup, [config])
    
    const isSet = useMemo(() => props.giftCustomer !== undefined, [props.giftCustomer])
    
    useEffect(() => {
        if (isSuccess) {
            props.handleChange('giftRecipient', data.data.Customer)
            props.handleChange('giftRecipientSessionKey', data.data.SessionKey)
            props.next()
        }
    }, [isSuccess])

    useEffect(() => {
        if (!countries)
            return;

        const countryList = countries.map((country: ICountry) => {
            return {
                value: country.Code,
                text: country.Name
            };
        });

        setCountryOptions(countryList);
    }, [countries]);

    useEffect(() => {
        if (!states)
            return;

        const stateList = states.map((state: IState) => {
            return {
                value: state.Code,
                text: state.Name
            };
        });

        setStateOptions(stateList);
    }, [states]);

    const submit = (event: ISyntheticEvent) => {
        event.preventDefault();
        if (isSet) {
            props.next()
            return
        }
        signupCustomer({ urls: mapServiceUrls(serviceUrls), deviceCode, signupState: state, domain, session: '' });
    };
    
    const back = (event: SyntheticEvent) => {
        event.preventDefault()
        props.back()
    }

    const handleChange = (fieldName: string, value: string) => {
        // @ts-ignore
        dispatch(updateSignupFieldAction(fieldName, value));
    };

    const handleCustomFieldChange = (fieldName: string, value: string) => {
        // @ts-ignore
        dispatch(updateCustomSignupFieldAction(fieldName, value))
    }

    const mapCustomField = (field: ICustomField, index: number) => {
        if (profile.CustomerCustomFields.some((d: any) => d.Code === field.Code))
            return null;


        const mapListOptions = (options: Array<any>) => {
            return options.map((option: any) => {
                return {
                    text: option.Name,
                    value: option.Value,
                }
            })
        }

        let value = state.CustomFields.find((curr: any) => curr.Key === field.Code)
        switch (field.FieldType) {

            case ICustomFieldType.Int:
            case ICustomFieldType.Decimal:
                return (
                    <Fragment key={index}>
                        <NumberInput key={index} id={field.Code} value={value?.Value ?? ''} label={field.Name}
                                     handleChange={handleCustomFieldChange}/>
                        <br/>
                    </Fragment>
                )
            case ICustomFieldType.ValidatedList:
                return (
                    <Fragment key={index}>
                        <SelectInput key={index} options={mapListOptions(field.ValidValues)} label={field.Name} current={value?.Value ?? ""} fieldName={field.Code} handleChange={handleCustomFieldChange} disabled={isSet} width={props.selectWidth === 0 ? undefined : props.selectWidth}/>
                    </Fragment>
                )
            case ICustomFieldType.Money:
            case ICustomFieldType.Date:
            case ICustomFieldType.Unknown:
            case ICustomFieldType.Time:
            case ICustomFieldType.DateTime:
            case ICustomFieldType.Bool:
            case ICustomFieldType.Byte:
            case ICustomFieldType.Blob:
            case ICustomFieldType.List:
            case ICustomFieldType.MultiSelectValidatedList:
            case ICustomFieldType.String:
            default:

                return (
                    <Fragment key={index}>

                        <TextInput key={index} id={field.Code} value={value?.Value ?? ''} label={field.Name}
                                   handleChange={handleCustomFieldChange} disabled={isSet}/>
                        <br/>
                    </Fragment>
                )
        }
    }

    const mapSignupField = (field: IInputConfig, index: number) => {
        if (!field.IsIncluded && !field.IsRequired)
            return;

        let fieldDefinition = profile.CustomerCustomFields?.find((cf: any) => cf.Name === field.FieldName);

        if (fieldDefinition) 
            return mapCustomField(fieldDefinition, index);

        // @ts-ignore
        let value = field.Name === "Zip" ? state.PostalCode : state[field.FieldName]
        let inputField: any;
        const label = field.Label ? field.Label : getDisplayValue(field.FieldName);
        switch (field.FieldName) {
            case 'Password':
                return <></>
            case 'EmailAddress':
                inputField = <EmailInput key={index} required={field.IsRequired} id={field.FieldName} value={value} label={label} handleChange={handleChange} disabled={isSet}/>;
                break;
            case 'Country':
                return (
                    <Fragment key={index}>
                        <SelectInput key={index} current={value} disabled={isSet} fieldName={field.FieldName} handleChange={handleChange} options={countryOptions}
                                     label={label} width={props.selectWidth === 0 ? undefined : props.selectWidth} required={field.IsRequired}/>
                    </Fragment>
                );
            case 'State':
                if (state.Country === '') {
                    return;
                }
                const selected: ICountry = countries.find((curr: ICountry) => curr.Code === state.Country);
                if (selected?.HasStates)
                    return (
                        <Fragment key={index}>
                            <SelectInput key={index} current={value} disabled={isSet} fieldName={field.FieldName} handleChange={handleChange}
                                         options={stateOptions} label={label} width={props.selectWidth === 0 ? undefined : props.selectWidth} required={field.IsRequired}/>
                        </Fragment>
                    );
                else
                    inputField = <TextInput key={index} required={field.IsRequired} id={field.FieldName} value={value} label={label ?? getDisplayValue(field.FieldName)} handleChange={handleChange} disabled={isSet}/>;
                break;
            default:
                inputField = <TextInput key={index} required={field.IsRequired} id={field.FieldName} value={value} label={label ?? label === '' ? getDisplayValue(field.FieldName) : label} handleChange={handleChange} disabled={isSet}/>;
        }
        return (
            <Fragment key={index}>
                {inputField}
                <br/>
            </Fragment>
        );
    };
    
    return (
        <>
            {snippetProfile &&
                <form onSubmit={submit}>
                    {countriesStatus === 'success' &&
                        <div className='bLoyal-center form'>
                            <div className='bLoyal-center bLoyal-input-holder'>
                                {snippetProfile.SignupFields.map(mapSignupField)}
                            </div>
                        </div>
                    }
                    <hr className='button-break'/>
                    <button className='bl-snippet-button' id='back' onClick={back}>{props.backLabel}</button>
                    <button className='bl-snippet-button' id='continue' type='submit'>{props.nextLabel}</button>
                </form>
            }
        </>
    )
}