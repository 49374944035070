import React from 'react';
import {Dialog, DialogActions, DialogTitle} from '@material-ui/core';
import AddPaymentDetails from './AddPaymentDetails';
import {BillingAddress, IPaymentType} from "./Helpers/Types";

type PaymentModalProps = {
	close: () => void
	savePayment: (title: string) => void
	saveCreditPayment: (token: string, cardNumber: string, cardType: string, isPrimary: boolean, title: string, billingAddress: BillingAddress) => void
	payment: any
	paymentTypeList: Array<IPaymentType>
	includeBillingAddress: boolean
}

export default function PaymentModal(props: PaymentModalProps) {

	return (
		<Dialog maxWidth='lg' open={true} fullWidth={true} scroll='paper'>
			<DialogTitle>{`${props.payment ? 'Edit' : 'Add'} Payment Method${props.payment?.IsClubPayment ? ' - This payment method is used on a club' : ''}`}</DialogTitle>
			<AddPaymentDetails payment={props.payment} close={props.close} savePayment={props.savePayment} saveCreditPayment={props.saveCreditPayment} paymentTypeList={props.paymentTypeList} includeBillingAddress={props.includeBillingAddress} />
			<DialogActions>
				<button className='bl-snippet-button' onClick={props.close} >
					Cancel
				</button>
			</DialogActions>
		</Dialog>
	);
}