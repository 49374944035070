import React, {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import {ChoiceResponse, Club, ClubBillingPeriod, ClubPaymentStrategy, WebSnippetProfile} from "../Helpers/Types";
import {Box, Step, StepLabel, Stepper} from "@material-ui/core";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import useServiceUrls from "../Utils/UseServiceUrls";
import useGetCart from "../Utils/UseGetCart";
import useGetClubs from "../Utils/UseGetClubs";
import useDeviceSessionProfile from "../Utils/UseDeviceSessionProfile";
import ClubDetails from "./Sections/ClubDetails";
import LoadingSpinner from "../LoadingSpinner";
import GiftRecipient from "./Sections/GiftRecipient";
import {DeviceSessionProvider} from "../Utils/UseDeviceSessionProfileContext";
import PaymentMethodSnippet from "../PaymentMethod/PaymentMethodSnippet";
import useSnippetConfig from "../Utils/UseSnippetConfig";
import ShippingAddressSnippet from "../ShippingAddress/ShippingAddressSnippet";
import {useAuth} from "../Utils/UseAuth";
import useSetClubPreferences from "../Utils/UseSetClubPreferences";
import {getCartLine, getStartDate, mapCustomCodes, mapServiceUrls} from "../Helpers/Functions";
import useAcknowledgeAlert from "../Utils/UseAcknowledgeAlert";
import SnippetHeader from "../Helpers/SnippetHeader";
import SnippetFooter from "../Helpers/SnippetFooter";
import {useCartContext} from "../Utils/UseCartContext";

type ClubPreferenceSnippetProps = {
    snippetProfile: WebSnippetProfile | null
}

export default function ClubPreferenceSnippet(props: ClubPreferenceSnippetProps) {
    const selectWidth = (document.getElementById('bl-preference-snippet')?.clientWidth ?? 0) * .75
    let snippetProfile = props.snippetProfile?.ClubPreferences
    // @ts-ignore
    const { session, customer } = useAuth();
    const [step, setStep] = useState(0)
    const [customCodes, setCustomCodes] = useState<Array<{code: string, value: string}>>([])
    const [isGift, setIsGift] = useState(ChoiceResponse.No)
    const [giftRecipient, setGiftRecipient] = useState<any>(undefined)
    const [giftRecipientSessionKey, setGiftRecipientSessionKey] = useState('')
    const [period, setPeriod] = useState(0)
    const [clubTerm, setClubTerm] = useState("1")
    const [paymentUid, setPaymentUid] = useState('')
    const [addressUid, setAddressUid] = useState('')
    const [membershipStartDate, setMembershipStartDate] = useState('0')
    const [paymentStrategy, setPaymentStrategy] = useState(ClubPaymentStrategy.PerShipment)
    const [allDone, setAllDone] = useState(false)
    const [club, setClub] = useState<undefined | Club>(undefined)
    
    const [noLine, setNoLine] = useState(false)
    const [noClub, setNoClub] = useState(false)
    const [bLoyalCartUid, setBLoyalCartUid] = useState('')
    const [bLoyalCartExternalId, setBLoyalCartExternalId] = useState('')
    const [bLoyalCartSourceExternalId, setBLoyalCartSourceExternalId] = useState('')
    
    const {contextCartUid, contextCartExternalId, contextCartSourceExternalId} = useCartContext();
    // @ts-ignore
    const { domain, deviceCode, cartUid, cartExternalId, cartSourceExternalId, lineUid, alertUid, onCompleteFunction, clubCode } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const { cartStatus, cart, cartError } = useGetCart(serviceUrls, domain, deviceCode, cartUid, cartExternalId, cartSourceExternalId, true)
    const { clubsStatus, clubs, clubsError } = useGetClubs(serviceUrls, domain, deviceCode)
    const { deviceSessionProfileStatus, deviceSessionProfile, deviceSessionProfileError} = useDeviceSessionProfile(serviceUrls, domain, deviceCode)
    const { SnippetConfigStatus: shippingStatus, SnippetConfig: shippingConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, snippetProfile?.ShippingAddressesSnippetCode ?? '');
    const { SnippetConfigStatus: paymentStatus, SnippetConfig: paymentConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, snippetProfile?.PaymentMethodSnippetCode ?? '');
    
    const steps = useMemo(() => {
        let workingSteps = [snippetProfile?.ClubDetailsPageName ? snippetProfile?.ClubDetailsPageName : 'Membership Details']
        if (isGift === ChoiceResponse.Yes) 
            workingSteps.push(snippetProfile?.GiftRecipientPageName ? snippetProfile?.GiftRecipientPageName : 'Gift Recipient')
        
        if (snippetProfile?.CaptureDeliveryDetails && (session !== null || isGift === ChoiceResponse.Yes))
            workingSteps.push(snippetProfile?.ShippingAddressPageName ? snippetProfile?.ShippingAddressPageName : 'Shipping Address')
        
        if (snippetProfile?.CapturePaymentMethod && session !== null)
            workingSteps.push(snippetProfile?.PaymentMethodPageName ? snippetProfile?.PaymentMethodPageName : 'Payment Details')
        
        return workingSteps
    }, [isGift, snippetProfile])

    const [setPreferences, {status, data, reset}] = useSetClubPreferences();
    const [acknowledgeAlert, {status: acknowledgeStatus, error}] = useAcknowledgeAlert()

    useEffect(() => {
        if (!bLoyalCartUid) {
            if (contextCartUid)
                setBLoyalCartUid(contextCartUid);
            else if (cartUid)
                setBLoyalCartUid(cartUid);
        }

        if (!bLoyalCartExternalId) {
            if (contextCartExternalId)
                setBLoyalCartExternalId(contextCartExternalId);
            else if (cartExternalId)
                setBLoyalCartExternalId(cartExternalId);
        }

        if (!bLoyalCartSourceExternalId) {
            if (contextCartSourceExternalId)
                setBLoyalCartSourceExternalId(contextCartSourceExternalId)
            else if (cartSourceExternalId)
                setBLoyalCartSourceExternalId(cartSourceExternalId);
        }
    }, [contextCartUid, contextCartExternalId, contextCartSourceExternalId, cartUid, cartExternalId, cartSourceExternalId]);


    useEffect(() => {
        if (status === 'success')
            acknowledgeAlert({urls: mapServiceUrls(serviceUrls), domain, deviceCode, cartUid: bLoyalCartUid, cartExternalId: bLoyalCartExternalId, cartSourceExternalId: bLoyalCartSourceExternalId, alertUid})
        else if (status === 'error')
            console.log(error)
    }, [status])

    useEffect(() => {
        async function callOnComplete() {
            try {
                // @ts-ignore
                await window[onCompleteFunction]();
            } catch (e) {
                console.log(`On complete function ${onCompleteFunction} not found`)
            }
        }

        if (acknowledgeStatus === 'success')
            callOnComplete()

    }, [acknowledgeStatus])

    useEffect(() => {
        if (clubs && clubs.length > 0 && cart) {
            const line = getCartLine(cart.Cart, lineUid)
            if (line === null) {
                setNoLine(true)
                console.log(`Cart line with uid ${lineUid} not found.`)
            } else {
                setNoLine(false)
                // @ts-ignore
                const tempClub = clubs.find((c: Club) => c.Code === line.AutoJoinClubCode || c.Uid === line.AutoJoinClubUid)
                if (tempClub === undefined) {
                    // @ts-ignore
                    console.log(`Club ${line.AutoJoinClubCode} not found.`)
                    setNoClub(true)
                }
                else {
                    setClub({...tempClub})
                    // @ts-ignore
                    setInitialValues(line.ClubPreferences)
                }
            }
        }
    }, [clubs, clubsStatus, cart])
    
    useEffect(() => {
        if (isGift === ChoiceResponse.No) {
            setGiftRecipient(undefined)
            setGiftRecipientSessionKey('')
        }
    }, [isGift])
    
    const setInitialStartDate = (dateString: string) => {
        const date = new Date(dateString)
        const now = new Date()
        let months;
        months = (date.getFullYear() - now.getFullYear()) * 12;
        months -= now.getMonth();
        months += date.getMonth();
        const difference = Math.abs(months)
        setMembershipStartDate(difference.toString())
    }
    
    const setInitialValues = (clubPreferences: any) => {
        if (clubPreferences === null)
            return;
        
        setClubTerm(clubPreferences.ClubTerm)
        setAddressUid(clubPreferences.ShippingAddressUid)
        setPaymentUid(clubPreferences.PaymentMethodUid)
        let fields: Array<{code: string, value: string}> = []
        for (const [key, value] of Object.entries(clubPreferences.CustomFields)) {
            // @ts-ignore
            fields.push({code: key, value: value})
        }
        setCustomCodes(fields)
        setIsGift(clubPreferences.Gift ? ChoiceResponse.Yes : ChoiceResponse.No)
        setPaymentStrategy(clubPreferences.PaymentOption)
        setInitialStartDate(clubPreferences.FirstShippingDate)
    }
    
    const handleChange = (field: string, value: any) => {
        if (value === undefined)
            return;
        
        switch (field) {
            case 'clubTerm':
                setClubTerm(value)
                break;
            case 'shippingDate':
                setMembershipStartDate(value)
                break;
            case 'membershipStartDate':
                setMembershipStartDate(value)
                break;
            case 'clubBillingPeriod':
                setPeriod(value)
                break;
            case 'giftChoice':
                setIsGift(value)
                break;
            case 'giftRecipient':
                setGiftRecipient(value);
                break;
            case 'giftRecipientSessionKey':
                setGiftRecipientSessionKey(value)
                break;
            case 'shippingAddress':
                setAddressUid(value.Uid)
                break;
            case 'paymentMethod':
                setPaymentUid(value.Uid)
                break;
            case 'clubPaymentStrategy':
                setPaymentStrategy(value)
                break;
        }
    };
    
    const next = (e?: SyntheticEvent) => {
        e?.preventDefault()
        if (step === steps.length - 1) {
            setPreferences({urls: mapServiceUrls(serviceUrls), deviceCode, domain, lineUid, cartUid: bLoyalCartUid, cartExternalId: bLoyalCartExternalId, cartSourceExternalId: bLoyalCartSourceExternalId, preferences: {
                ClubTerm: isNaN(parseInt(clubTerm)) ? undefined : parseInt(clubTerm),
                    FirstShippingDate: getStartDate(isNaN(parseInt(membershipStartDate)) ? 0 : parseInt(membershipStartDate)),
                    ShippingFrequency: period,
                    ShippingAddressUid: addressUid,
                    PaymentMethodUid: paymentUid,
                    PaymentOption: paymentStrategy,
                    Gift: isGift === ChoiceResponse.Yes,
                    CustomerUid: isGift === ChoiceResponse.Yes ? giftRecipient?.Uid : customer?.Uid,
                    CustomFields: mapCustomCodes(customCodes)
                }})
        }
        else 
            setStep(step + 1)
    }
    
    const back = (e?: SyntheticEvent) => {
        e?.preventDefault()
        setStep(step - 1)
    }
    
    const handleCustomFieldChange = (field: string, value: any) => {
        let fields = customCodes.slice()
        let found = false
        fields = fields.map((f) => {
            if (f.code === field) {
                found = true
                return {
                    code: field,
                    value: value,
                }
            }
            
            return f
        })
        
        if (!found)
            fields.push({code: field, value: value})
        
        setCustomCodes(fields)
    }
    
    return (
        <div className='bLoyal-center preference-snippet' id='bl-preference-snippet'>
            {(cartStatus === 'loading' || clubsStatus === 'loading' || deviceSessionProfileStatus === 'loading') && <LoadingSpinner />}
            {deviceSessionProfile && snippetProfile && club &&
                <DeviceSessionProvider profile={deviceSessionProfile}>
                    <SnippetHeader logoUrl={props.snippetProfile?.LogoUrl ?? ''} title={props.snippetProfile?.Title ?? ''} message={props.snippetProfile?.Message ?? ''} snippetCode={props.snippetProfile?.Code ?? ''}/>
                    {/*@ts-ignore*/}
                    <Box sx={{width: '100%'}}>
                        <Stepper activeStep={step} alternativeLabel>
                            {steps.map((label) => (
                                <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                </Step>
                                ))}
                        </Stepper>
                    </Box>
                    {steps[step] === (snippetProfile?.ClubDetailsPageName ? snippetProfile?.ClubDetailsPageName : 'Membership Details') &&
                        <>
                            <p>{snippetProfile.ClubDetailsPageText}</p>
                            <ClubDetails deviceProfile={deviceSessionProfile} handleChange={handleChange} handleCustomFieldChange={handleCustomFieldChange} customCodes={customCodes} snippetProfile={snippetProfile} club={club} clubTerm={clubTerm} period={period} startDate={membershipStartDate} isGift={isGift} selectWidth={selectWidth} paymentStrategy={paymentStrategy}/>
                            <hr className='button-break' />
                            {step !== 0 && <button className='bl-snippet-button' onClick={back}>Back</button>}
                            <button className='bl-snippet-button' onClick={next}>{step === steps.length - 1 ? snippetProfile.SaveLabel : snippetProfile.NextLabel}</button>
                        </>
                    }
                    {steps[step] === (snippetProfile?.GiftRecipientPageName ? snippetProfile?.GiftRecipientPageName : 'Gift Recipient') &&
                        <>
                            <p>{snippetProfile.GiftRecipientPageText}</p>
                            <GiftRecipient signupSnippetCode={snippetProfile.GiftSignupSnippetCode} giftCustomer={giftRecipient} back={back} next={next} handleChange={handleChange} selectWidth={selectWidth} nextLabel={step === steps.length - 1 ? snippetProfile.SaveLabel : snippetProfile.NextLabel} backLabel={snippetProfile.BackLabel}/>
                        </>
                    }
                    
                    { steps[step] === (snippetProfile?.ShippingAddressPageName ? snippetProfile?.ShippingAddressPageName : 'Shipping Address') && shippingConfig &&
                        <>
                            <p>{snippetProfile.ShippingAddressPageText}</p>
                            <ShippingAddressSnippet onSelect={handleChange} giftCustomer={giftRecipient} snippetProfile={shippingConfig} allowPickup={snippetProfile.AllowStorePickup} allowShipping={snippetProfile.AllowShipping} initialValue={addressUid}/>
                            <hr className='button-break' />
                            {step !== 0 && <button className='bl-snippet-button' onClick={back}>{snippetProfile.BackLabel}</button>}
                            <button className='bl-snippet-button' onClick={next}>{step === steps.length - 1 ? snippetProfile.SaveLabel : snippetProfile.NextLabel}</button>
                        </>
                    }
                    
                    { steps[step] === (snippetProfile?.PaymentMethodPageName ? snippetProfile?.PaymentMethodPageName : 'Payment Details') && paymentConfig && 
                        <>
                            <PaymentMethodSnippet snippetProfile={paymentConfig} onSelect={handleChange} initialValue={paymentUid}/>
                            <hr className='button-break' />
                            {step !== 0 && <button className='bl-snippet-button' onClick={back}>{snippetProfile.BackLabel}</button>}
                            <button className='bl-snippet-button' onClick={next}>{step === steps.length - 1 ? snippetProfile.SaveLabel : snippetProfile.NextLabel}</button>
                        </>
                    }
                    {status === 'loading' && <LoadingSpinner />}
                    {status === 'error' && <p className='bLoyal-error-text'>Unable to save club preferences.</p>}
                    {acknowledgeStatus === 'error' && <p className='bLoyal-error-text'>Unable to acknowledge the alert but your preferences have been saved.</p>}
                    {noLine && <p className='bLoyal-error-text'>Cart line not found.</p>}
                    {noClub && <p className='bLoyal-error-text'>Club not found.</p>}

                    <SnippetFooter footer={props.snippetProfile?.Footer ?? ''} snippetCode={props.snippetProfile?.Code ?? ''}/>
                </DeviceSessionProvider>
            }
        </div>
    )
}