import React, {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import {useSnippetContext} from "../Utils/UseSnippetContext";
import useServiceUrls from "../Utils/UseServiceUrls";
import {
    AccountIndicatorType,
    AvailableCoupon,
    CustomerDashboardSnippetProfile,
    LoyaltyAccountIndicator, WebSnippetProfile,
} from "../Helpers/Types";
import {useAuth} from "../Utils/UseAuth";
import QRCode from 'react-qr-code'
import DashboardCouponDisplay from "./DashboardCouponDisplay";
import useGetCustomerSession from "../Utils/UseGetCustomerSession";
import {MDBCol, MDBRow} from "mdbreact";
import SnippetHeader from "../Helpers/SnippetHeader";
import ReferralSnippet from "../Referral/ReferralSnippet";
import useSnippetConfig from "../Utils/UseSnippetConfig";
import LoyaltyAccountIndicatorCard from "rc-progress-bars/dist/LoyaltyAccountIndicators/LoyaltyAccountIndicatorCard";
import ProgressBarWithCheckpoints from "rc-progress-bars/dist/LoyaltyAccountIndicators/ProgressBarWithCheckpoints";
import SnippetFooter from "../Helpers/SnippetFooter";
import {Grid} from "@mui/material";
import CouponSection from "./CouponSection";
import useSaveMember from "../Utils/UseSaveMember";
import useApplyCoupon from "../Utils/UseApplyCoupon";

type DashboardProps = {
    snippetConfig: WebSnippetProfile
}

export default function Dashboard(props: DashboardProps) {
    // @ts-ignore
    const { customer, session } = useAuth()
    // @ts-ignore
    const { domain, deviceCode, onCompleteFunction } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    const { customerSession, status } = useGetCustomerSession(serviceUrls, session, domain, deviceCode)
    const { SnippetConfigStatus, SnippetConfig } = useSnippetConfig(serviceUrls, domain, deviceCode, props.snippetConfig.CustomerDashboard?.ReferralSnippetCode ?? '', props.snippetConfig.CustomerDashboard?.ReferralSnippetCode !== '' ?? false);
    
    const [showReferral, setShowReferral] = useState(false)
    const [onCompleteExists, setOnCompleteExists] = useState(false)
    const [couponCode, setCouponCode] = useState<string>('')
    
    const qrValue = useMemo(() => props.snippetConfig.CustomerDashboard?.QRCustomerField, [props.snippetConfig])
    const dashboardProfile = useMemo(() => props.snippetConfig.CustomerDashboard, [props.snippetConfig])
    
    const [apply, {status: applyStatus, data: applyData, reset, error}] = useApplyCoupon()
    
    useEffect(() => {
        // @ts-ignore
        if (typeof window[onCompleteFunction] === "function") {
            setOnCompleteExists(true)
            return;
        }

        console.log(`OnCompleteFunction ${onCompleteFunction} does not exist`)
    }, [])
    
    useEffect(() => {
        if (applyStatus === 'success' && onCompleteExists && couponCode !== '') {
            // @ts-ignore
            window[onCompleteFunction]({CouponCode: couponCode});
        }
        if (couponCode !== '' && applyStatus === 'success')
            setCouponCode('')
    }, [couponCode, applyStatus])
    
    const showReferralSnippet = (e: SyntheticEvent) => {
        e.preventDefault()
        setShowReferral(true)
    }
    
    const applyCoupon = (code: string) => {
        apply({urls: serviceUrls, domain, deviceCode, session, couponCode: code, couponUid: undefined, quantity: 1})
        setCouponCode(code)
    }
    
    const mapAccountIndicator = (accountIndicator: LoyaltyAccountIndicator, index: number) => {
        let accountClass = customer.LoyaltyPointsAccounts.find((account: any) => account.LoyaltyAccountClassUid === accountIndicator.ClassUid)
        if (!accountClass)
            accountClass = customer.LoyaltyCurrencyAccounts.find((account: any) => account.LoyaltyAccountClassUid === accountIndicator.ClassUid);
        
        if (!accountClass)
            return <></>
        
        switch (accountIndicator.Type) {
            case AccountIndicatorType.Value:
                return (
                    <div key={index} style={{width: '50%', minWidth: '300px', maxWidth: '350px'}} className='bLoyal-center'>
                        <LoyaltyAccountIndicatorCard  accountIndicator={{Label: accountIndicator.Label, Value: accountClass.Balance}}/>
                        <br />
                    </div>
                )
            case AccountIndicatorType.ProgressBar:
                return (
                    <div key={index} style={{width: '75%', minWidth: '400px'}} className='bLoyal-center'>
                    <h2>{accountIndicator.Label}</h2>
                        <ProgressBarWithCheckpoints useIcons={accountIndicator.ProgressBar.UseIcons} checkpoints={accountIndicator.ProgressBar.Checkpoints}  maximumValue={accountIndicator.ProgressBar.Maximum * 1.02} progress={accountClass.Balance} filledBackground={accountIndicator.ProgressBar.Color}/>
                        <br />
                        <br />
                    </div>
                )
            default:
                return <></>
        }
    }
    
   
    const getReferralSection = () => {
        if (props.snippetConfig.CustomerDashboard?.DisplayReferralSnippet && showReferral)
            return (
                <>
                    <ReferralSnippet snippetConfig={SnippetConfig} />
                    <br />
                    <br />
                </>
            )
        if (props.snippetConfig.CustomerDashboard?.DisplayReferralSnippet && !showReferral)
            return (
                <>
                    <button className='bl-snippet-button' id='show-referral-snippet' onClick={showReferralSnippet}>{props.snippetConfig.CustomerDashboard?.ReferralSnippetButtonText}</button>
                    <br />
                    <br />
                </>
            )
        
        return <></>
    }
    
    return (
        <div className='bLoyal-center' style={{padding: '0 10px'}}>
            <SnippetHeader logoUrl={props.snippetConfig.LogoUrl} title={props.snippetConfig.Title} message={props.snippetConfig.Message} snippetCode={props.snippetConfig.Code}/>
            {props.snippetConfig.CustomerDashboard?.DisplayQRCode && 
                <div>
                    {/*@ts-ignore*/}
                    <QRCode value={qrValue === "Code" ? customer.Code : customer.LoyaltyCardNumber} size={100}/>
                    <p className='bLoyal-center'>{qrValue === "Code" ? customer.Code : customer.LoyaltyCardNumber}</p>
                </div>
            }
            <br />
            <>
                {props.snippetConfig.CustomerDashboard?.LoyaltyAccountIndicators.map(mapAccountIndicator)}
                {getReferralSection()}
                {status === "success" && dashboardProfile?.ShowCoupons === true && customerSession &&
                    <CouponSection snippetConfig={props.snippetConfig} customerSession={customerSession} applyCoupon={applyCoupon} onCompleteExists={onCompleteExists}/>
                }
                {/*@ts-ignore*/}
                {applyStatus === 'error' && <p className={'bLoyal-error-text'}>{error.toString()}</p>}
                <SnippetFooter footer={props.snippetConfig.Footer} snippetCode={props.snippetConfig.Code}/>
            </>
        </div>
    )
}