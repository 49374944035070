import React, {SyntheticEvent} from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";

type ClickToRedeemWarningProps = {
    cancel: () => void;
    confirm: () => void;
}

export default function ClickToRedeemWarning({cancel, confirm}: ClickToRedeemWarningProps) {

    const close = (e: SyntheticEvent) => {
        e.preventDefault();
        cancel()
    }

    const confirmRedeem = (e: SyntheticEvent) => {
        e.preventDefault()
        confirm()
    }

    return (
        <Dialog className={'bLoyal-dialog'} maxWidth='sm' fullWidth={true} open={true}>
            <DialogTitle>Click-To-Redeem™</DialogTitle>
            <DialogContent className='dialog-body bLoyal-center'>
               This will mark the coupon as redeemed, only confirm if you are ready to use the coupon now
            </DialogContent>
            <DialogActions>
                <button style={{float: 'left'}} onClick={close}>
                    Close
                </button>
                <button style={{float: 'right'}} onClick={confirmRedeem}>
                    Confirm
                </button>
            </DialogActions>
        </Dialog>
    )
}