import React, {createContext, ReactChildren, useEffect, useState} from 'react';
import queryString from "query-string";
import {getParameterCaseInsensitive} from "../Helpers/Functions";

// @ts-ignore
const CartContext = createContext();
CartContext.displayName = 'CartContext';

function CartProvider(props: { children: React.ReactNode }) {
	const [cartUid, setCartUid] = useState<string | null>(null);
	const [cartExternalId, setCartExternalId] = useState<string | null>(null);
	const [cartSourceExternalId, setCartSourceExternalId] = useState<string | null>(null);

	// Helper function to get value from sessionStorage or query parameters
	const getValueFromSessionOrQuery = (key: string): string | null => {
		// First, try to get it from session storage
		const valueFromSession = sessionStorage.getItem(key);
		if (valueFromSession) return valueFromSession;


		const queryString = require('query-string');
		const parsed = queryString.parse(window.location.search);
		const valueFromQuery = getParameterCaseInsensitive(parsed, key.toLowerCase())
		
		// If found in query, optionally save it to session storage
		if (valueFromQuery) {
			sessionStorage.setItem(key, valueFromQuery);
			return valueFromQuery;
		}

		return null;
	};
	
	useEffect(() => {
		// Read values from session storage when the component mounts
		const storedCartUid = getValueFromSessionOrQuery("bL_cartUid")
		const storedCartExternalId = getValueFromSessionOrQuery("bL_cartExternalId");
		const storedCartSourceExternalId = getValueFromSessionOrQuery("bL_cartSourceExternalId");

		if (storedCartUid) setCartUid(storedCartUid);
		if (storedCartExternalId) setCartExternalId(storedCartExternalId);
		if (storedCartSourceExternalId) setCartSourceExternalId(storedCartSourceExternalId);
	}, []);

	const value = React.useMemo(() => ({ contextCartUid: cartUid, contextCartExternalId: cartExternalId, contextCartSourceExternalId: cartSourceExternalId }), [
		cartUid,
		cartExternalId,
		cartSourceExternalId,
	]);

	return <CartContext.Provider value={value} {...props} />;
}

function useCartContext(): {contextCartUid: string, contextCartExternalId: string, contextCartSourceExternalId: string} {
	const context = React.useContext(CartContext);
	if (context === undefined) {
		throw new Error(`useCartContext must be used within a CartProvider`);
	}
	// @ts-ignore
	return context;
}

export {CartProvider, useCartContext};
