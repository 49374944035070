import React, {useState} from 'react';
import BillingAddressForm from "./BillingAddressForm";
import UsaePayAddCreditCardFrame from "../UsaePayAddCreditCardFrame";

type UsaepayFormProps = {
    back: Function
    paymentFormUrl: string
    savePayment: Function
    payment: any
    includeBillingAddress: boolean
}

export default function UsaepayForm(props: UsaepayFormProps) {
    const [step, setStep] = useState(props.includeBillingAddress ? 1 : 2)
    const [billingAddress, setBillingAddress] = useState({})
    
    const next = (street: string, street2: string, city: string, state: string, zip: string, country: string, name: string) => {
        setBillingAddress({
            BillingName: name,
            BillingAddress1: street,
            BillingAddress2: street2,
            BillingCity: city,
            BillingState: state,
            BillingPostalCode: zip,
            BillingCountry: country
        })
        setStep(2)
    }

    const savePayment = (token: string, cardNumber: string, cardType: string) => {
        props.savePayment(token, cardNumber, cardType, billingAddress)
    }
    
    const prev = () => {
        props.back()
    }
    
    return (
        <>
            {step === 1 && <BillingAddressForm payment={props.payment} next={next} prev={prev} />}
            {step === 2 && <UsaePayAddCreditCardFrame savePayment={savePayment} paymentFormUrl={props.paymentFormUrl} />}
        </>
    )
}