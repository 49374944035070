import React, {useEffect, useState} from 'react';
import {BillingAddress, ICustomer, IPaymentData, ISyntheticEvent, PaymentSource, PaymentType} from './Helpers/Types';
import PaymentModal from './PaymentModal';
import SelectInput from './SelectInput';
import {useAuth} from './Utils/UseAuth';
import {useSnippetContext} from './Utils/UseSnippetContext';
import useServiceUrls from './Utils/UseServiceUrls';
import usePaymentMethods from './Utils/UsePaymentMethods';
import PaymentMethodPreview from './PaymentMethodPreview';
import LoadingSpinner from './LoadingSpinner';
import {MDBCol, MDBRow} from 'mdbreact';
import useSavePaymentMethod from "./Utils/UseSavePaymentMethod";
import {isInViewport, mapServiceUrls} from "./Helpers/Functions";
import useDeletePaymentMethod from "./Utils/UseDeletePaymentMethod";
import {v4 as uuid} from 'uuid';

type PaymentEditorProps = {
	updateField: (field: string, value: any) => void
	payment: IPaymentData
	nextStep: (e: ISyntheticEvent) => void
	prevStep: (e: ISyntheticEvent) => void
	config: any
	sponsor: ICustomer | null
	sponsoredPayment?: IPaymentData
	isGift?: boolean
}

export default function PaymentEditor(props: PaymentEditorProps) {
	// @ts-ignore
	const { session } = useAuth();
	// @ts-ignore
	const { domain, deviceCode } = useSnippetContext();
	const [savePaymentMethod, {status: saveStatus, error: saveError, data}] = useSavePaymentMethod();
	const [deletePaymentMethod, { status, error}] = useDeletePaymentMethod();
	const { serviceUrls } = useServiceUrls(domain);
	const { paymentMethodsStatus, paymentMethods, paymentMethodsError, paymentMethodsFetching } = usePaymentMethods(serviceUrls, session, domain, deviceCode);
	const [paymentOptions, setPaymentOptions] = useState<Array<any>>([]);
	const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(props.payment ? props.payment.PaymentMethodUid : '');
	const [showingAdd, setShowingAdd] = useState(false);

	useEffect(() => {
		if (paymentMethods)
			setPaymentOptions(mapPaymentsToOptions(paymentMethods));
	}, [paymentMethods]);

	useEffect(() => {
		const pageTitle = document.getElementById('bloyal-club-signup-page-title')
		if (!isInViewport(pageTitle)) {
			const element = document.getElementById('bloyal-snippet-container')
			element?.scrollIntoView({behavior: 'auto', block: 'start'})
		}
	}, [])
	
	useEffect(() => {
		if (saveStatus === 'success') {
			const uid = data.data.PaymentMethod.Uid
			const payment: IPaymentData = {
				PaymentMethodUid: uid,
				Source: PaymentSource.Default,
			}
			props.updateField('Payment', payment);
			setSelectedPaymentMethod(uid)
		}
	}, [saveStatus])

	const mapPaymentsToOptions = (payments: Array<any>) => {
		let options: Array<any> = [];
		if (props.sponsoredPayment) {
			options.push({value: props.sponsoredPayment.PaymentMethodUid, text: props.sponsor ? `Sponsored by: ${props.sponsor.FirstName + ' ' + props.sponsor.LastName}` : "Sponsored Payment"})
		}
		if (!payments)
			return options;
		if (payments.length === 0)
			return options;
		payments.forEach((payment: any) => {
			let option = {
				value: payment.Uid,
				text: payment.Title,
			};
			if (selectedPaymentMethod === '' && payment.IsPrimary)
				setSelectedPaymentMethod(payment.Uid);
			options.push(option);
		});
		return options;
	};

	const showAddPayment = (e: ISyntheticEvent) => {
		e.preventDefault();
		setSelectedPaymentMethod('')
		setShowingAdd(true);
	};
	
	const saveCreditPayment = (token: string, cardNumber: string, cardType: string, isPrimary: boolean, title: string, billingAddress: BillingAddress) => {
		const uid = selectedPaymentMethod === '' ? null : selectedPaymentMethod;
		savePaymentMethod({
			creditCard: {
				CardType: cardType,
				CardMask: cardNumber,
				CardToken: token,
				...billingAddress
			},
			paymentMethodUid: uid,
			title: title,
			giftCard: undefined,
			isPrimary: isPrimary,
			deviceCode: deviceCode, domain: domain, paymentType: PaymentType.CreditCard, session: session, urls: mapServiceUrls(serviceUrls)
		});
		
		setShowingAdd(false);
	}

	const savePayment = (title: string) => {
		setSelectedPaymentMethod(title);
		setShowingAdd(false)
	};

	const handleSelectChange = (field: string, value: string) => {
		setSelectedPaymentMethod(value);
		let source = PaymentSource.Default;
		if ((props.sponsoredPayment && props.sponsoredPayment.PaymentMethodUid === value) || props.isGift) {
			source = PaymentSource.Sponsor
		}
		const payment: IPaymentData = {
			PaymentMethodUid: value,
			Source: source,
		}
		props.updateField('Payment', payment);
	};

	const getSelectedPaymentMethod = () => {
		return paymentMethods.find((curr: any) => curr.Uid === selectedPaymentMethod);
	};
	
	const editPayment = (e:ISyntheticEvent) => {
		e.preventDefault();
		setShowingAdd(true);
	}

	const deletePayment = (e: ISyntheticEvent) => {
		e.preventDefault();
		deletePaymentMethod({urls: mapServiceUrls(serviceUrls), paymentUid: selectedPaymentMethod, deviceCode, domain, session});
		setSelectedPaymentMethod('')
	}
	
	const saveAndContinue = (e: ISyntheticEvent) => {
		let source = PaymentSource.Default;
		if (props.sponsoredPayment && props.sponsoredPayment.PaymentMethodUid === selectedPaymentMethod) {
			source = PaymentSource.Sponsor
		}
		const payment: IPaymentData = {
			PaymentMethodUid: selectedPaymentMethod,
			Source: source,
		}
		props.updateField('Payment', payment);
		props.nextStep(e);
	};

	return (
		<form onSubmit={saveAndContinue}>
			<div className='bLoyal-center form bl-narrow'>
				{paymentMethodsStatus === 'loading' &&
                <LoadingSpinner/>
				}
				{paymentMethodsStatus === 'success' &&
                <>
                    <MDBRow>
                        <MDBCol md='8'>
                            <SelectInput label='Choose Payment Method' options={paymentOptions} current={selectedPaymentMethod} disabled={false}
                                         fieldName='PaymentMethod' handleChange={handleSelectChange}/>
                        </MDBCol>
                        <MDBCol md='4'>
                            <button className='bl-snippet-button' onClick={showAddPayment}>New</button>
	                        <button className='bl-snippet-button' disabled={selectedPaymentMethod === '' || selectedPaymentMethod === props.sponsoredPayment?.PaymentMethodUid} onClick={editPayment}>Edit</button>
							<button className='bl-snippet-button' disabled={selectedPaymentMethod === ''} onClick={deletePayment} >Delete</button>
                        </MDBCol>
                    </MDBRow>

					{selectedPaymentMethod !== '' && getSelectedPaymentMethod() &&
                    <>
                        <PaymentMethodPreview paymentMethod={getSelectedPaymentMethod()}/>
                    </>
					}
					{showingAdd &&
					<PaymentModal payment={selectedPaymentMethod === '' ? undefined : getSelectedPaymentMethod()} savePayment={savePayment} saveCreditPayment={saveCreditPayment} close={() => setShowingAdd(false)} paymentTypeList={props.config.PaymentTypeList} includeBillingAddress={false}/>
					}
                    <br/>
                </>
				}

				<button className='bl-snippet-button' id='back' onClick={props.prevStep}>Back</button>
				<button className='bl-snippet-button' id='continue' type='submit'
				        disabled={selectedPaymentMethod === ''}>Continue
				</button>
				{saveStatus === 'error' && <p className='bLoyal-error-text'>Unable to save payment method.</p>}
				{status === 'loading' &&
				<LoadingSpinner />
				}
				
				{paymentMethodsFetching &&
				<p>Refreshing...</p>
				}
				
				{(paymentMethodsError || error) &&
                <p className='bLoyal-error-text'>{paymentMethodsError ? paymentMethodsError : error}</p>
				}
			</div>
		</form>
	);
}