import {queryCache, useMutation} from 'react-query';
import {client} from './ApiClient';
import {ICustomer} from '../Helpers/Types';

const updateCustomerGroups = ({urls, session, domain, deviceCode, groups}: {urls: any; session: string; domain: string; deviceCode: string; groups: Array<string>}) => {
	return client(urls.LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/snippets/customer/${session}/commands/updateGroups`, {
		body: {
			GroupsToJoin: groups
		}
	});
}

export default function useUpdateCustomerGroups() {
	// @ts-ignore
	return useMutation(updateCustomerGroups, {
		onSuccess: () => {
			queryCache.invalidateQueries('Customer')
		},
	})
}