/** @jsxRuntime classic */
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import {ReactQueryDevtools} from 'react-query-devtools';
import {SnippetProvider} from './Utils/UseSnippetContext';
import PreProcessWrapper from "./PreProcessWrapper";
import {CartProvider} from "./Utils/UseCartContext";
import HostedSnippet from "./Hosted/HostedSnippet";

const renderSnippets = (elements: NodeListOf<Element>) => {
	elements.forEach(element => {
		let isConfigError = false;
		let snippetCode = element.getAttribute('data-bloyal-snippet-code');
		if (!snippetCode) {
			console.error(`You must provide a snippet code using the 'data-bloyal-snippet-code' attribute`)
			isConfigError = true;
		}

		let loginDomain = element.getAttribute('data-bloyal-login-domain');
		if (!loginDomain) {
			console.error(`You must provide a login domain using the 'data-bloyal-login-domain`);
			isConfigError = true;
		}

		const jsonString = element.getAttribute('data-bloyal-snippet-args');
		const args = JSON.parse(jsonString ? jsonString : '');
		let deviceCode = element.getAttribute('data-bloyal-device-code');
		if (!deviceCode)
			deviceCode = args.DeviceCode;

		if (!deviceCode) {
			console.error(`You must provide a device code using the 'data-bloyal-device-code' attribute`);
			isConfigError = true;
		}

		const clubCode = args.ClubCode;
		let useLogout = args.UseLogout ?? false;

		if (isConfigError) {
			ReactDOM.render(<h1>This page is currently unavailable</h1>, element)
			return;
		}

		if (snippetCode) {
			ReactDOM.render(
				<SnippetProvider domain={loginDomain ?? 'rctest'} deviceCode={deviceCode ?? ''}
								 clubCode={clubCode ?? ''} useLogout={useLogout}
								 returnUrlParam={args.ReturnUrlParameter ?? 'ReturnUrl'} loginUrl={args.LoginUrl ?? ''}
								 cartUid={args.CartUid} cartExternalId={args.CartExternalId}
								 cartSourceExternalId={args.CartSourceExternalId} cartAmount={args.CartAmount}
								 onCompleteFunction={args.OnSnippetComplete ?? 'blOnSnippetComplete'}
								 lineUid={args.LineUid} alertUid={args.AlertUid} redirectToHome={args.PaymentRedirectToHome}
					 			 productCode={args.ProductCode}>
					<CartProvider >
						<PreProcessWrapper snippetCode={snippetCode}/>
						<ReactQueryDevtools initialIsOpen={false}/>
					</CartProvider>
				</SnippetProvider>,
				element
			);
		}
	});
}

const renderHostedSnippet = () => {
	const hostedSnippetElement = document.getElementById('hosted-snippet-container');
	if (hostedSnippetElement !== null) {
		ReactDOM.render(<HostedSnippet/>, hostedSnippetElement);
	}
}

let snippetElements = document.querySelectorAll('div[data-bloyal-login-domain]')
renderSnippets(snippetElements);
renderHostedSnippet();

const updateElementsList = () => {
	if (snippetElements.length !== document.querySelectorAll('div[data-bloyal-login-domain]').length) {
		renderSnippets(document.querySelectorAll('div[data-bloyal-login-domain]'))
		snippetElements = document.querySelectorAll('div[data-bloyal-login-domain]')
	} else {
		renderHostedSnippet()
	}
}

if (!Element.prototype.getRootNode) {
	Element.prototype.getRootNode = function (options?: { composed?: boolean }) {
		let root: Node = this;
		while (root.parentNode) {
			root = root.parentNode;
		}
		if (options?.composed && root instanceof ShadowRoot) {
			return root;
		}
		return root;
	};
}

let root: any = document
if (document.getRootNode)
	root = document.getRootNode()

const config = { attributes: true, childList: true, subtree: true };

const observer = new MutationObserver(updateElementsList)

observer.observe(root, config)

window.onunload = (e) => {
	observer.disconnect()
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
