import {useMutation} from 'react-query';
import {client} from './ApiClient';
import {ICustomer} from '../Helpers/Types';
import {register} from "../serviceWorker";
import {mapServiceUrls} from "../Helpers/Functions";

const setPrimary = ({urls, session, domain, deviceCode, cardUid, customerUid}: {urls: any; session: string; domain: string; deviceCode: string; cardUid: string, customerUid: string}) => {
	return client(mapServiceUrls(urls).LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/sessions/${session}/trackedcard/commands/setPrimary`, {
		body: {
			CustomerUid: customerUid,
			TrackedCardUid: cardUid,
		}
	});
}

export default function useSetPrimaryCard() {
	// @ts-ignore
	return useMutation(setPrimary)
}