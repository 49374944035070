import React, {useEffect} from 'react';

type SnippetFooterProps = {
    snippetCode: string
    footer: string
}

export default function SnippetFooter(props: SnippetFooterProps) {
    useEffect(() => {
        const footer = document.getElementById(`bl-snippet-footer-${props.snippetCode}`)
        if (footer && props.footer !== null) // For IE11 compatibility
            footer.innerHTML = props.footer
    }, [])
    
    return (
        <>
            <p id={`bl-snippet-footer-${props.snippetCode}`} className='bLoyal-center'></p>
        </>
    )
}