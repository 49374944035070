import React, {SyntheticEvent, useEffect, useMemo, useState} from 'react';
import DesignSelection from "./DesignSelection";
import {EGiftCard, WebSnippetProfile} from "../Helpers/Types";
import GiftAmount from "./GiftAmount";
import RecipientDetails from "./RecipientDetails";
import SenderDetails from "./SenderDetails";
import dayjs from "dayjs";
import * as LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { v4 as uuid } from 'uuid';
import ConfirmLoseChangesDialog from "./ConfirmLoseChangesDialog";
import SelectInput from "../SelectInput";
import ValidatedSelectInput from "../Inputs/ValidatedSelectInput";
import {useSnippetContext} from "../Utils/UseSnippetContext";
import useServiceUrls from "../Utils/UseServiceUrls";
import useDeviceSessionProfile from "../Utils/UseDeviceSessionProfile";
import useGetCart from "../Utils/UseGetCart";
import useSetEGiftCards from "../Utils/UseSetEGiftCards";
import useAcknowledgeAlert from "../Utils/UseAcknowledgeAlert";
import {
    getCartLine,
    getCartLineByProductCode,
    getCustomerFullName,
    getInputField, getParameterCaseInsensitive, languageCode,
    mapServiceUrls,
    updateLanguageCode, updateLanguageCodeFromBrowser
} from "../Helpers/Functions";
import LoadingSpinner from "../LoadingSpinner";
import SnippetHeader from "../Helpers/SnippetHeader";
import SuccessDialog from "./SuccessDialog";
import utc from 'dayjs/plugin/utc';
import {useAuth} from "../Utils/UseAuth";
import {useCartContext} from "../Utils/UseCartContext";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

type NewEgiftSnippetProps = {
    profile: WebSnippetProfile
}

const getDefaultGiftCard = (profile: WebSnippetProfile): EGiftCard => {
    var utc = require('dayjs/plugin/utc')
    dayjs.extend(utc)
    return {
        Id: uuid(),
        Amount: '',
        CustomAmount: profile.EGiftCard?.MinValue ?? 0,
        ImageUrl: (profile.EGiftCard?.ImageOptions?.length ?? 0 > 0) ? profile.EGiftCard?.ImageOptions[0].Url ?? '' : '',
        Message: "",
        RecipientEmail: "",
        RecipientName: "",
        SendDate: dayjs.utc(),
        SenderEmail: "",
        SenderName: ""
    }
}

const mapGiftCardAmounts = (card: EGiftCard, options: Array<string>) => {
    const allowCustom = options.some(o => o === 'Custom')
    if (options.some(o => o === card.Amount.toString()) || !allowCustom)
        return card
    
    return {...card, Amount: 'Custom', CustomAmount: card.Amount}
}

export default function NewEGiftSnippet(props: NewEgiftSnippetProps) {
    const [noLine, setNoLine] = useState(false)
    const [bLoyalCartUid, setBLoyalCartUid] = useState('')
    const [bLoyalCartExternalId, setBLoyalCartExternalId] = useState('')
    const [bLoyalCartSourceExternalId, setBLoyalCartSourceExternalId] = useState('')
    const {contextCartUid, contextCartExternalId, contextCartSourceExternalId} = useCartContext();
    // @ts-ignore
    const { domain, deviceCode, cartUid, cartExternalId, cartSourceExternalId, lineUid, alertUid, onCompleteFunction, productCode } = useSnippetContext();
    const { serviceUrls } = useServiceUrls(domain);
    // @ts-ignore
    const { deviceSessionProfile: profile } = useDeviceSessionProfile(serviceUrls, domain, deviceCode)
    const { cartStatus, cart, cartError } = useGetCart(serviceUrls, domain, deviceCode, bLoyalCartUid, bLoyalCartExternalId, bLoyalCartSourceExternalId, true)
    const [cartLine, setCartLine] = useState<any>(undefined)
    const [setGiftCardsToCart, {status, data, error: setEGiftError}] = useSetEGiftCards()
    const [acknowledgeAlert, {status: acknowledgeStatus, error}] = useAcknowledgeAlert()
    
    const [giftCards, setGiftCards] = useState<Array<EGiftCard>>([getDefaultGiftCard(props.profile)]);
    const [currentState, setCurrentState] = useState<EGiftCard>(giftCards[0]);
    const [confirmAddNew, setConfirmAddNew] = useState(false);
    const [confirmAddToCart, setConfirmAddToCart] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showDateError, setShowDateError] = useState(false);
    const [selected, setSelected] = useState(giftCards[0].Id);
    const [invalidInputs, setInvalidInputs] = useState<Array<any>>([]);
    const [product, setProduct] = useState(productCode !== '' ? productCode : props.profile.EGiftCard?.DefaultProductCode ?? '');
    const [returnUrl, setReturnUrl] = useState(props.profile.OnSuccessRedirectUrl)

    const totalCost = useMemo(() => {
        let cost = 0;
        giftCards.forEach(gc => {
            if (gc.Amount !== 'Custom' && isNaN(parseFloat(gc.Amount)))
                return
            
            if (gc.Amount === 'Custom' && isNaN(gc.CustomAmount ?? 0))
                return;
            
            cost += gc.Amount === 'Custom' ? gc.CustomAmount ?? 0 : parseFloat(gc.Amount) ?? 0
        })
        return cost
    }, [giftCards]);
    const snippetProfile = useMemo(() => props.profile.EGiftCard, [props.profile.EGiftCard]);
    const recipientOptions = useMemo(() => giftCards.map(((gc, index) => {
        return {
            text: gc.RecipientName ? gc.RecipientName : 'Current',
            value: gc.Id,
        }
    })), [giftCards]);
    
    const reformatGiftCardDates = () => {
        let cards = giftCards.slice()
        var utc = require('dayjs/plugin/utc')
        dayjs.extend(utc)
        // @ts-ignore
        dayjs.extend(LocalizedFormat)
        cards.map(c => {
            return {
                ...c,
                SendDate: dayjs.utc(c.SendDate.toDate()).locale(languageCode.toLowerCase()).format('L')
            }
        })
        setGiftCards(cards)
    }

    useEffect(() => {
        if (!bLoyalCartUid) {
            if (contextCartUid)
                setBLoyalCartUid(contextCartUid);
            else if (cartUid)
                setBLoyalCartUid(cartUid);
        }

        if (!bLoyalCartExternalId) {
            if (contextCartExternalId)
                setBLoyalCartExternalId(contextCartExternalId);
            else if (cartExternalId)
                setBLoyalCartExternalId(cartExternalId);
        }

        if (!bLoyalCartSourceExternalId) {
            if (contextCartSourceExternalId)
                setBLoyalCartSourceExternalId(contextCartSourceExternalId)
            else if (cartSourceExternalId)
                setBLoyalCartSourceExternalId(cartSourceExternalId);
        }
    }, [contextCartUid, contextCartExternalId, contextCartSourceExternalId, cartUid, cartExternalId, cartSourceExternalId]);

    useEffect(() => {
        if (languageCode) {
            const code = languageCode.toLowerCase()
            if (dayjs.locale() !== code && code !== 'en-us') {
                try {
                    require(`dayjs/locale/${code}.js`)
                    dayjs.locale(code)
                    reformatGiftCardDates()
                } catch (e) {
                    console.log(e)
                    try {
                        const deviceLanguage = profile.Globalization.LanguageCode.toLowerCase() ?? 'en-us'
                        updateLanguageCode(profile)
                        console.log(`Attempting to use device profile language code: ${deviceLanguage}`)
                        require(`dayjs/locale/${deviceLanguage}.js`)
                        dayjs.locale(deviceLanguage)
                        reformatGiftCardDates()
                    } catch (e) {
                        console.log(e)
                        console.log('Using default en-us language code')
                        updateLanguageCodeFromBrowser('en-us')
                        dayjs.locale('en-us')
                        reformatGiftCardDates()
                    }
                }
            }
        }
    }, [languageCode]);

    useEffect(() => {
            // @ts-ignore
        const userLang = navigator.language || navigator.userLanguage;
        updateLanguageCodeFromBrowser(userLang)
        
        if (!returnUrl) {
            const queryString = require('query-string');
            const parsed = queryString.parse(window.location.search);
            setReturnUrl(getParameterCaseInsensitive(parsed, 'bl_rurl') ?? null)
        }
    }, []);

    useEffect(() => {
        if (showSuccess)
        setTimeout(() => setShowSuccess(false), 1500)
    }, [showSuccess]);

    useEffect(() => {
        if (showDateError)
            setTimeout(() => setShowDateError(false), 1500)
    }, [showDateError]);

    useEffect(() => {
        async function callOnComplete(cartUid: string) {
            setShowSuccess(true)
            try {
                // @ts-ignore
                await window[onCompleteFunction]({CartUid: cartUid, PaymentMethod: null})
                
            } catch (e) {
                console.log(`On complete function ${onCompleteFunction} not found`)
            }
            if (returnUrl !== null)
                window.setTimeout(function () {
                    let url = returnUrl ?? window.location.host;
                    url = url.includes("http") ? url : `https://${url}`
                    window.location.assign(url)
                }, (props.profile.OnSuccessRedirectDelay ?? 0) * 1000)
        }
        
        if (status === 'success') {
            if (alertUid) {
                acknowledgeAlert({
                    urls: mapServiceUrls(serviceUrls),
                    domain,
                    deviceCode,
                    cartUid: bLoyalCartUid,
                    cartExternalId: bLoyalCartExternalId,
                    cartSourceExternalId: bLoyalCartSourceExternalId,
                    alertUid
                })
            } else {
                callOnComplete(data.data.Entity.Uid)
            }
        } else if (status === 'error')
            console.log(error)
    }, [status])

    useEffect(() => {
        // @ts-ignore
        if (typeof window[onCompleteFunction] !== "function")
            console.log(`OnCompleteFunction ${onCompleteFunction} does not exist`)

    }, [])

    useEffect(() => {
        async function callOnComplete() {
            setShowSuccess(true)
            try {
                // @ts-ignore
                await window[onCompleteFunction]();
            } catch (e) {
                console.log(`On complete function ${onCompleteFunction} not found`)
            }
            if (returnUrl !== null)
                window.setTimeout(function () {
                    let url = returnUrl ?? window.location.host;
                    url = url.includes("http") ? url : `https://${url}`
                    window.location.assign(url)
                }, (props.profile.OnSuccessRedirectDelay ?? 0) * 1000)
        }

        if (acknowledgeStatus === 'success')
            callOnComplete()

    }, [acknowledgeStatus])

    useEffect(() => {
        if (cart) {
            let cartLine = getCartLine(cart.Cart, lineUid)
            if (!cartLine)
                cartLine = getCartLineByProductCode(cart.Cart, product)
            if (cartLine) {
                setCartLine(cartLine)
                if (snippetProfile?.UseCartPrice) {
                    // @ts-ignore
                    const giftCardLineOptions = cartLine.LineOptions?.find(l => l.Label === 'EGiftCardDetails')?.Value
                    const cards = JSON.parse(giftCardLineOptions ?? '{"GiftCards": []}')
                    const cartGiftCards = cards.GiftCards.map((c: any) => {
                         // @ts-ignore
                        return {...c, Amount: ((cartLine?.Price ?? 0) / cards.GiftCards.length).toString()}
                    })
                    const dayJsGiftCards: Array<EGiftCard> = cartGiftCards.map((gc: EGiftCard) => {
                        var utc = require('dayjs/plugin/utc')
                        dayjs.extend(utc)
                        var sendDate = dayjs.utc(gc.SendDate)
                        var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
                        dayjs.extend(isSameOrBefore)
                        const yesterday = dayjs().subtract(1, 'day').endOf('day')
                        // @ts-ignore
                        if (sendDate.isSameOrBefore(yesterday)) {
                            sendDate = dayjs().startOf('day')
                        }
                        return {...gc, SendDate: sendDate, Id: uuid()}
                    })
                    if (dayJsGiftCards.length > 0) {
                        setGiftCards(dayJsGiftCards)
                        setCurrentState(dayJsGiftCards[0])
                    } else {
                        if (cart.Cart.Customer)
                        { // @ts-ignore
                            setCurrentState({...currentState, SenderEmail: cart.Cart.Customer.EmailAddress ?? '', SenderName: getCustomerFullName(cart.Cart.Customer), Amount: cartLine.Price.toString()})
                        } else { //@ts-ignore
                            setCurrentState({...currentState, Amount: cartLine.Price.toString()})
                        }
                    }
                    
                } else {
                    // @ts-ignore
                    const giftCardLineOptions = cartLine.LineOptions?.find(l => l.Label === 'EGiftCardDetails')?.Value
                    const cards = JSON.parse(giftCardLineOptions ?? '{"GiftCards": []}')
                    const cartGiftCards = cards.GiftCards.map((c: any) => mapGiftCardAmounts(c, snippetProfile?.AmountOptions ?? []))
                    const dayJsGiftCards: Array<EGiftCard> = cartGiftCards.map((gc: EGiftCard) => {
                        var utc = require('dayjs/plugin/utc')
                        dayjs.extend(utc)
                        var sendDate = dayjs.utc(gc.SendDate)
                        var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
                        dayjs.extend(isSameOrBefore)
                        const yesterday = dayjs().subtract(1, 'day').endOf('day')
                        // @ts-ignore
                        if (sendDate.isSameOrBefore(yesterday)) {
                            sendDate = dayjs().startOf('day')
                        }
                        return {...gc, SendDate: sendDate, Id: uuid()}
                    })
                    if (dayJsGiftCards.length > 0) {
                        setGiftCards(dayJsGiftCards)
                        setCurrentState(dayJsGiftCards[0])
                    } else {
                        if (cart.Cart.Customer)
                            setCurrentState({
                                ...currentState,
                                SenderEmail: cart.Cart.Customer.EmailAddress ?? '',
                                SenderName: getCustomerFullName(cart.Cart.Customer)
                            })
                    }
                }
                setNoLine(false)
            } else {
                if (cart.Cart.Customer)
                    setCurrentState({...currentState, SenderEmail: cart.Cart.Customer.EmailAddress ?? '', SenderName: getCustomerFullName(cart.Cart.Customer)})
                setNoLine(true)
            }
        }
    }, [cart, snippetProfile])

    useEffect(() => {
        var cards = giftCards.slice()
        cards = cards.map(c => c.Id === currentState.Id ? currentState : c)
        setGiftCards(cards)
    }, [currentState]);

    useEffect(() => {
        if (selected !== currentState.Id)
            setCurrentState(giftCards.find(gc => gc.Id === selected) ?? getDefaultGiftCard(props.profile))
    }, [selected]);

    const removeEntry = (array: Array<string>, entry: string) => {
        return array.filter(item => item !== entry);
    }
    
    const handleChange = (field: string, value: any) => {
        const invalids = invalidInputs.slice()
        setInvalidInputs(removeEntry(invalids, field))
        switch (field) {
            case 'RecipientName':
                setCurrentState({...currentState, RecipientName: value});
                break;
            case 'RecipientEmail':
                setCurrentState({...currentState, RecipientEmail: value});
                break;
            case 'Message':
                setCurrentState({...currentState, Message: value});
                break;
            case 'SenderName':
                if (value.length > 50)
                    value = value.substring(0, 50);
                setCurrentState({...currentState, SenderName: value});
                break;
            case 'SenderEmail':
                setCurrentState({...currentState, SenderEmail: value});
                break;
            case 'Date':
                var utc = require('dayjs/plugin/utc')
                dayjs.extend(utc)
                var sendDate = dayjs.utc(value)
                var isSameOrBefore = require('dayjs/plugin/isSameOrBefore')
                dayjs.extend(isSameOrBefore)
                const yesterday = dayjs().subtract(1, 'day').endOf('day')
                // @ts-ignore
                if (sendDate.isSameOrBefore(yesterday)) {
                    sendDate = dayjs().startOf('day')
                }
                setCurrentState({...currentState, SendDate: sendDate});
                break;
            case 'GiftAmount':
                setCurrentState({...currentState, Amount: value, CustomAmount: value === 'Custom' ? currentState.CustomAmount : undefined});
                break;
            case 'CustomAmount':
                setCurrentState({...currentState, CustomAmount: value});
                break;
            case 'ImageUrl':
                setCurrentState({...currentState, ImageUrl: value});
                break;
        }
    }
    
    const handleSelectChange = (field: string, value: string, e: SyntheticEvent) => {
        // @ts-ignore
        if (!e.currentTarget.form.reportValidity()) {
            setConfirmAddNew(true)
            return;
        }
        setSelected(value)
    }
    
    const addRecipient = (e: SyntheticEvent) => {
        e.preventDefault()
        var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
        dayjs.extend(isSameOrAfter)
        // @ts-ignore
        if (!currentState.SendDate.isSameOrAfter(dayjs().startOf('day'))) {
            setShowDateError(true)
            return
        }

        // @ts-ignore
        const form = e.currentTarget.form as HTMLFormElement;
        
        // @ts-ignore
        if (!e.currentTarget.form.reportValidity()) {
            const invalids = getInvalidInputs(form)
            setInvalidInputs(invalids.map(i => i.id))
            setConfirmAddNew(true)
            return;
        }
        
        var newGift = getDefaultGiftCard(props.profile)
        var cards = giftCards.slice()
        cards.push(newGift)
        setGiftCards(cards)
        setCurrentState({
            ...newGift,
            Amount: currentState.Amount,
            CustomAmount: currentState.CustomAmount,
            ImageUrl: currentState.ImageUrl,
            SendDate: currentState.SendDate,
            SenderEmail: currentState.SenderEmail,
            SenderName: currentState.SenderName
        })
        setSelected(newGift.Id)
    }

    const addToCart = (e: SyntheticEvent) => {
        e.preventDefault()
        var isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
        dayjs.extend(isSameOrAfter)
        // @ts-ignore
        if (!currentState.SendDate.isSameOrAfter(dayjs().startOf('day'))) {
            setShowDateError(true)
            return
        }
        
        // @ts-ignore
        const form = e.currentTarget.form as HTMLFormElement;
        
        if (!form.reportValidity()) {
            const invalids = getInvalidInputs(form)
            setInvalidInputs(invalids.map(i => i.id))
            setConfirmAddToCart(true)
            return;
        }
        
        saveToCart(giftCards)
    }

    const getInvalidInputs = (form: HTMLFormElement) => {
        const invalidInputs = [];
        // @ts-ignore
        for (let element of form.elements) {
            if ((element.tagName === 'INPUT' || element.tagName === 'SELECT') && !element.checkValidity()) {
                invalidInputs.push(element);
            }
        }
        return invalidInputs;
    };

    function removeCurrentGiftCard(e?: SyntheticEvent) {
        e?.preventDefault()
        if (giftCards.length <= 1) {
            const newCard = getDefaultGiftCard(props.profile)
            setCurrentState(newCard)
            return [newCard];
        }
        
        var cards = giftCards.slice()
        cards = cards.filter(c => c.Id !== currentState.Id)
        setGiftCards(cards)
        setCurrentState(getDefaultGiftCard(props.profile))
        setSelected(cards[0].Id)
        setInvalidInputs([])
        return cards
    }

    const forceNewGiftCard = () => {
        removeCurrentGiftCard();
        
        setConfirmAddNew(false)
    }

    const forceAddToCart = () => {
        var cards = removeCurrentGiftCard()
        setConfirmAddToCart(false)
        //Call Add to cart API
        saveToCart(cards)
    }
    
    const saveToCart = (cards: Array<EGiftCard>) => {
        setGiftCardsToCart({urls: mapServiceUrls(serviceUrls), domain, deviceCode, cartUid: bLoyalCartUid, cartExternalId: bLoyalCartExternalId, cartSourceExternalId: bLoyalCartSourceExternalId, lineUid, productCode: product, cards})
    }
    
    return (
        <div className={'bLoyal-center bLoyal-padding'}>
            <ConfirmLoseChangesDialog cancel={() => setConfirmAddNew(false)} open={confirmAddNew} continue={forceNewGiftCard} quantity={giftCards.length}/>
            <ConfirmLoseChangesDialog cancel={() => setConfirmAddToCart(false)} open={confirmAddToCart} continue={forceAddToCart} quantity={giftCards.length} isAddToCart={true}/>
            <SuccessDialog open={showSuccess} message={props.profile.OnSuccessMessage} />
            <SnippetHeader logoUrl={props.profile.LogoUrl} title={props.profile.Title} message={props.profile.Message} snippetCode={props.profile.Code}/>
            <form className={'bLoyal-form bLoyal-center'}>
                {(snippetProfile?.ImageOptions?.length ?? 0) > 0 &&
                    <DesignSelection handleChange={handleChange} state={currentState} config={snippetProfile}/>
                }
                <GiftAmount handleChange={handleChange} state={currentState} config={snippetProfile} currencySymbol={profile?.Globalization?.CurrencySymbol ?? ''} productName={cartLine?.ProductName} invalidInputs={invalidInputs}/>
                <RecipientDetails handleChange={handleChange} state={currentState} config={snippetProfile} invalidInputs={invalidInputs}/>
                <SenderDetails handleChange={handleChange} state={currentState} config={snippetProfile} invalidInputs={invalidInputs}/>
                {showDateError && <p className={'bLoyal-error-text'}>Send date cannot be in the past.</p> }
                <button className={'bl-snippet-button__secondary'} onClick={addRecipient} disabled={giftCards.length > 9} >{snippetProfile?.AddRecipientLabel ?? 'Add Recipient'}</button>
                {giftCards.length > 1 && <> <br/> <button className={'bl-snippet-button__secondary'} onClick={removeCurrentGiftCard} >{snippetProfile?.RemoveRecipientLabel ?? 'Remove Selected Recipient'}</button> </>}
                {giftCards.length > 1 && <SelectInput label={getInputField(snippetProfile?.EGiftFields ?? [], 'CardSelection')?.Label ?? 'Update eGift for'} options={recipientOptions} current={selected} disabled={false}
                                                      fieldName='SelectedeGift' handleChange={handleSelectChange} removeWidth={true}/>}
                <button className={'bl-snippet-button__primary'} onClick={addToCart} >{`${snippetProfile?.AddToCartLabel ?? 'Add To Cart'} - $${totalCost}`}</button>
            </form>
            {status === 'loading' && <LoadingSpinner />}
            {/*@ts-ignore*/}
            {status === 'error' && !setEGiftError?.includes('Product not found') && <p className='bLoyal-error-text'>Error saving gift recipient.</p>}
            {/*@ts-ignore*/}
            {status === 'error' && setEGiftError?.includes('Product not found') && <p className='bLoyal-error-text'>You must provide a valid ProductCode</p>}
            {noLine && !product && <p className='bLoyal-error-text'>You must provide a valid LineUid or ProductCode</p>}
        </div>
    )
}