import React, {useEffect, useMemo, useState} from 'react';
import {useSnippetContext} from "../Utils/UseSnippetContext";
import {useAuth} from "../Utils/UseAuth";
import useServiceUrls from "../Utils/UseServiceUrls";
import useGetClubs from "../Utils/UseGetClubs";
import useDeviceSessionProfile from "../Utils/UseDeviceSessionProfile";
import {TrackedCard, WebSnippetProfile} from "../Helpers/Types";
import List from "../Helpers/List";
import useTrackedCards from "../Utils/UseTrackedCards";
import LoadingSpinner from "../LoadingSpinner";
import TextInput from "../TextInput";
import useSaveAndRegisterTrackedCard from "../Utils/UseSaveTrackedCard";
import AddCardDialog from "./AddCardDialog";
import Carousel from "../AccountManagement/Carousel";
import ManageCardDialog from "./ManageCardDialog";
import useSetPrimaryCard from "../Utils/UseSetPrimaryCard";

type RegisterCardSnippetProps = {
    snippetProfile: WebSnippetProfile | null
}

export default function RegisterCardSnippet(props: RegisterCardSnippetProps) {
    // @ts-ignore
    const { domain, deviceCode, clubCode } = useSnippetContext();
    // @ts-ignore
    const { session, customer } = useAuth();
    const { serviceUrls } = useServiceUrls(domain);
    const { trackedCardsStatus, trackedCards, trackedCardsError} = useTrackedCards(serviceUrls, domain, deviceCode, session)
    const snippetProfile = useMemo(() => props.snippetProfile?.RegisterCard, [props.snippetProfile])
    const [selectedCard, setSelectedCard] = useState('')
    const [addCard, setAddCard] = useState(false)
    const [manageCard, setManageCard] = useState(false)
    const [cardNumber, setCardNumber] = useState('')
    
    const [saveTrackedCard, {status, error}] = useSaveAndRegisterTrackedCard()
    const [setPrimaryCard, {status: setPrimaryStatus, error: setPrimaryError}] = useSetPrimaryCard()
    
    const makePrimaryLabel = useMemo(() => snippetProfile?.PrimaryButtonLabel ? snippetProfile.PrimaryButtonLabel : 'Make Primary', [snippetProfile])
    const manageLabel = useMemo(() => props.snippetProfile?.Buttons.find(b => b.ButtonName === 'ManageCard')?.Text ?? 'Manage', [snippetProfile])
    
    const currentCard = useMemo(() => trackedCards?.find((t: any) => t.Uid === selectedCard) ?? undefined, [trackedCards, selectedCard])
    
    const mapCard = (card: TrackedCard, index: number) => {
        return (
            <div key={index} onClick={() => setSelectedCard(card.Uid ?? '')}>
                <div className="bLoyal-gift-card bLoyal-center">
                    <div className="bLoyal-stripe"></div>
                    <div className="bLoyal-gift-card-body"></div>
                </div>
                <div>{`${card.Code} - $${card.Balance}`}</div>
                <br/>
                <div>
                    <button className='bl-snippet-button' onClick={() => toggleManageCard()}>{props.snippetProfile?.Buttons.find(b => b.ButtonName === 'ManageCard')?.Text ?? 'Manage'}</button>
                </div>
            </div>
        )
    }
    
    const cardViews = useMemo(() => trackedCards ? trackedCards.map(mapCard) : [], [trackedCards])

    useEffect(() => {
        if (trackedCards)
        setSelectedCard(trackedCards[0])
    }, [trackedCards]);
    
    useEffect(() => {
        if (status === 'success') {
            setAddCard(false)
            setCardNumber('')
        }
    }, [status])

    const toggleAddCard = () => {
        setAddCard(!addCard)
    }
    
    const toggleManageCard = () => {
        setManageCard(!manageCard)
    }
    
    const updateCurrentCard = (card: TrackedCard) => {
        setSelectedCard(card.Uid)
    }
    
    const saveCard = (cardNumber: string) => {
        saveTrackedCard({urls: serviceUrls, domain, deviceCode, cardNumber, session, customerUid: customer.Uid})
    }
    
    const makePrimary = () => {
        setPrimaryCard({urls: serviceUrls, domain, deviceCode, cardUid: selectedCard, customerUid: customer.Uid, session})
    }
    
    const deleteCard = () => {
        
    }
    
    const handleChange = (field: string, value: string) => {
        setCardNumber(value)
    }
    
    return (
        <div className={'bLoyal-center'} style={{width: '35%', minWidth: '400px'}}>
            {snippetProfile &&
                <>    
                    {trackedCards &&
                        <>
                            {!addCard &&
                                <>
                                    {cardViews.length > 0 && <Carousel views={cardViews} data={trackedCards} setCurrent={updateCurrentCard}/>}
                                    <button className='bl-snippet-button' onClick={toggleAddCard}>{snippetProfile?.AddButtonLabel ? snippetProfile?.AddButtonLabel : 'Add Card'}</button>
                                    <button className='bl-snippet-button' onClick={deleteCard} disabled={selectedCard === ''}>{snippetProfile?.DeleteButtonLabel ? snippetProfile?.DeleteButtonLabel : 'Delete'}</button>
                                </>
                            }
                        </>
                    }
                    {addCard && <AddCardDialog cancel={() => setAddCard(false)} add={saveCard} title={snippetProfile?.AddButtonLabel ? snippetProfile?.AddButtonLabel : 'Add Card'} save={snippetProfile?.AddButtonLabel ? snippetProfile?.AddButtonLabel : 'Add Card'} input={snippetProfile?.CardNumberLabel ? snippetProfile?.CardNumberLabel : "Card Number"} snippetProfile={snippetProfile} error={status === 'error' ? error : undefined}/>}
                    {manageCard && <ManageCardDialog card={currentCard} loading={setPrimaryStatus === 'loading'} cancel={() => setManageCard(false)} makePrimary={makePrimary} title={snippetProfile?.AddButtonLabel ? snippetProfile?.AddButtonLabel : 'Add Card'} snippetProfile={snippetProfile} error={setPrimaryStatus === 'error' ? setPrimaryError : undefined} delete={snippetProfile?.DeleteButtonLabel ? snippetProfile?.DeleteButtonLabel : 'Delete'} primary={makePrimaryLabel}/>}
                    {(trackedCardsStatus === 'loading' || status === 'loading' || setPrimaryStatus === 'loading') && <LoadingSpinner />}
                </>
            }
        </div>
    )
}