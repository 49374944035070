import {queryCache, useMutation} from 'react-query';
import {client} from './ApiClient';
import {ICustomer} from '../Helpers/Types';
import {register} from "../serviceWorker";
import {mapServiceUrls} from "../Helpers/Functions";

const saveAndRegisterTrackedCard = ({urls, session, domain, deviceCode, cardNumber, customerUid}: {urls: any; session: string; domain: string; deviceCode: string; cardNumber: string, customerUid: string}) => {
	return client(mapServiceUrls(urls).LoyaltyEngineApiUrl, `api/v4/${domain}/${deviceCode}/snippets/trackedCard/${session}/commands/saveandregister`, {
		body: {
			TrackingKey: cardNumber,
			CustomerUid: customerUid,
		}
	});
}

export default function useSaveAndRegisterTrackedCard() {
	// @ts-ignore
	return useMutation(saveAndRegisterTrackedCard, {
		onSuccess: () => {
			queryCache.invalidateQueries('TrackedCards')
		},
	})
}